import { useCallback, useEffect, useState } from "react";
import useSettings from './useSettings';

import { useTelegram } from "contexts/TelegramContext";
import useAxios from "./useAxios";

const useUseNFT = (page:number, limit: number) => {
    const { settings } = useSettings();
    const { callApi } = useAxios()
    const [ userNfts, setUserNfts] = useState({total:0, hasMore:true, data: [] as any[]})
    const fetchUserNfts = useCallback(async () => {
        if (!settings.token) return;
        try {
            const results = await callApi<any,any>('get', `/nft/get-my-nfts?page=${page}&limit=${limit}`)
            console.log("results.total > page * limit",results.total > page * limit, results.total)
            if(results && results?.data?.length > 0)
            setUserNfts((nft)=> {return { hasMore: results.total > page * limit ,total: results.total, data:[...nft.data,...results.data]}})        
        } catch (e) {
            console.log('ignoew')
        }
    }, [callApi, limit, page, settings.token])
    useEffect(() => {
        fetchUserNfts()
    }, [
        fetchUserNfts, settings.token
    ])
    return { userNfts, fetchUserNfts }
};

export default useUseNFT;
