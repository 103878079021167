import { LoadingButton } from "@mui/lab";
import { Box, ButtonBase, Stack, styled, Typography } from "@mui/material";

export const BoxContainer = styled(Stack)(({ theme }) => ({
  margin: "auto",
  minHeight: "100vh",
  padding: "1rem",
  paddingTop: '1rem',
}));

export const TypographyOutline = styled(Typography)(({ theme }) => ({
  fontWeight: 900,
  WebkitTextFillColor: "white",
  WebkitTextStroke: "1px black",
  paintOrder: "stroke fill",
}));

export const TitleBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",
  padding: "1rem 2rem",
  background: '#FEA863',
  transition: 'all 0.1s ease-in-out',
  '&.disabled': {
    background: '#745843',
    boxShadow: 'inset 0px -3px 0px rgba(0,0,0,0.3)',
    '& .MuiTypography-root': {
      WebkitTextFillColor: "#3E2B1E",
      WebkitTextStroke: "0px black",
      paintOrder: "stroke fill",
      WebkitTextStroke: '1px #3E2B1E !important',
      textShadow: 'none !important',
    }
  }

}));

export const PrimaryButton = styled(LoadingButton)(({ theme }) => ({
  borderRadius: 0,
  padding: '0px 0.5rem',
  color: "#fff",
  fontSize: '1.2rem',
  minHeight: 'unset',
  '&.non-active': {
    background: 'none !important',
    opacity: 0.7,
    '&:before, &:after': {
      opacity: 0
    }
  },
  '&:before': {
    content: '""',
    width: '100%',
    height: '15%',
    position: 'absolute',
    top: 0,
    left: 0,
    background: 'rgba(255,255,255,0.2)'
  },
  '&:after': {
    content: '""',
    width: '100%',
    height: '15%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    background: 'rgba(0,0,0,0.2)'
  }
}));

export const BrownBox = styled(Box)(({ theme }) => ({
  borderRadius: "1rem",
  background: "#6A5027",
  border: `1px solid #000`,
  padding: "0.875rem",
  boxShadow:
    "inset 0px 4px 0px rgba(255,255,255,0.25), 2px 4px 0px rgba(0,0,0)",
}));

export const WheatBox = styled(Box)(({ theme }) => ({
  borderRadius: "1rem",
  background: "#FFF9C7",
  border: `1px solid #000`,
  padding: "0.875rem",
  boxShadow:
    "inset 0px 4px 0px rgba(255,255,255,0.25), 2px 4px 0px rgba(0,0,0)",
}));

export const OrangeBox = styled(Box)(({ theme }) => ({
  borderRadius: "1rem",
  background: "#FFC671",
  border: `1px solid #000`,
  padding: "0.875rem",
  boxShadow:
    "inset 0px 4px 0px rgba(255,255,255,0.25), 2px 4px 0px rgba(0,0,0)",
}));

export const BlueBox = styled(Box)(({ theme }) => ({
  borderRadius: "1rem",
  background: "#81A4FF",
  border: `1px solid #000`,
  padding: "0.875rem",
  boxShadow:
    "inset 0px 4px 0px rgba(255,255,255,0.25), 2px 4px 0px rgba(0,0,0)",
}));

export const BoxFooter = styled(Stack)(({ theme }) => ({
  minHeight: '50px',
  width: '100%',
  background: '#FEA863',
  boxShadow: "inset 0px -5px 0px rgba(0,0,0,0.1)",
  borderTop: "2px solid #000"
}));