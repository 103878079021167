import { IconButton, Modal, Stack, Typography, useTheme } from "@mui/material";
import { TonConnectButton } from "@tonconnect/ui-react";
import Iconify from './Iconify'
import { t } from "i18next";

export const ConnectWalletPopup = ({ openConnectQuest, closeConnectQuest }) => {
    const theme = useTheme();
    return (
        <>
            <Modal
                open={openConnectQuest}
                sx={{
                    padding: "1rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                onClose={closeConnectQuest}>
                <Stack
                    p={2}
                    sx={{
                        background: theme.palette.background.default,
                        borderRadius: "0.7rem",
                        maxWidth: 400,
                        minHeight: 200,
                        width: "100%",
                        border: `1px solid ${theme.palette.divider}`,
                        position: "relative",
                    }}>
                    <IconButton
                        onClick={closeConnectQuest}
                        sx={{ position: "absolute", top: 3, right: 3 }}>
                        <Iconify icon={"ic:round-close"} size={25} />
                    </IconButton>
                    <Iconify
                        icon='solar:wallet-2-bold-duotone'
                        size={40}
                        sx={{ margin: "auto", opacity: 0.3 }}
                    />
                    <Typography
                        variant='h5'
                        textAlign={"center"}
                        color={"text.secondary"}>
                        {t("Please connect wallet")}
                    </Typography>
                    <TonConnectButton />
                </Stack>
            </Modal>
        </>
    );
};