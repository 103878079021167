import { useCallback, useEffect, useState } from "react";
import useSettings from './useSettings';

import { useTelegram } from "contexts/TelegramContext";
import useAxios from "./useAxios";

const useAvailableChest = (chestId: number) => {
    const { settings } = useSettings();
    const { callApi } = useAxios()
    const [availableChest, setAvailableChest] = useState()
    const [chestIdState, setChestIdState] = useState(chestId)

    useEffect(()=>{
        setChestIdState(chestId)
    },[chestId])
    const fetchAvailableChests = useCallback(async () => {
        if (!settings.token) return;
        try {
            const chests = await callApi('get', `/chest/get-available-chest?chestId=${chestIdState}`)
            setAvailableChest(chests as any)
        } catch (e) {
            console.log('ignoew')
        }
    }, [callApi, chestIdState, settings.token])
    useEffect(() => {
        fetchAvailableChests()
    }, [
        fetchAvailableChests, settings.token
    ])
    return { availableChest, fetchAvailableChests }
};

export default useAvailableChest;
