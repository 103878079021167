export function isMobile(): boolean {
    return window.innerWidth <= 500;
}

export function isDesktop(): boolean {
    return window.innerWidth >= 1050;
}

export function openLink(href: string, target = '_self') {
    window.open(href, target, 'noreferrer noopener');
}
export function randomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}