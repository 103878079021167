import {
  Button,
  Stack,
  Typography,
  styled,
  useTheme,
  OutlinedInput,
  ButtonBase,
  Box,
  IconButton,
  Divider,
} from "@mui/material";
import { FlexBetween, FlexRowAlign } from "components/flexbox";
import Modal from "components/modal/Modal";
import { t } from "i18next";
import ProfitIcon from "icons/ProfitIcon";
import TNXIcon from "icons/TNXIcon";
import ThunderIcon from "icons/ThunderIcon";
import { useState, useCallback, useMemo, useEffect } from "react";
import { beginCell } from "@ton/ton";
import TelegramProvider, { useTelegram } from "contexts/TelegramContext";
import { toNano } from "@ton/ton";
import { TonWeb } from "tonweb";
import {
  TonConnectButton,
  useTonWallet,
  useTonConnectUI,
  SendTransactionRequest,
} from "@tonconnect/ui-react";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import Decimal from "decimal.js";
import useSettings from "../../hooks/useSettings";
import BackIcon from "../../icons/BackIcon";
import useAxios from "../../hooks/useAxios";
import { PrimaryButton } from "../../pages/mobile/styled";
import { CloseOutlined } from "@mui/icons-material";



export const UpgradeForm = ({ char ,id , fetchUserNfts}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const { callApi } = useAxios()
  const [isLoading, setIsLoading] = useState(false)
  const [nft, setNft] = useState()
  const { settings } = useSettings();
  const fetchNft = useCallback(async()=>{
     if(!settings.token) return
     const data = await callApi('get',`/nft/get-nft?id=${id}`)
     setNft(data)
  },[callApi, id, settings.token])

  useEffect(()=>{
    setNft(char)
  },[char])


  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      const result = await callApi('post', '/nft/update-nfts', {
        nftId: id
      })
      await fetchNft()
      await fetchUserNfts()
      if(result.success===1){
        toast.success(
          "🎉 Your warrior has grown stronger!",
          {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );
      } else {
        toast.error(
          "😔 Unfortunately, the upgrade attempt has failed. Don't give up, better luck next time!",
          {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );
      }
       
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(`Error: ${error.response.data.message}`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("An unexpected error occurred.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const characterMap = {
    resistance: 'Resistance',
    assassin: 'Assassin',
    cz: 'Changpeng Zhao',
    dog: 'Doge',
    durov: 'Durov',
    knight: 'Knight',
    mage: 'Mage',
    mew: 'Cattie',
    mummy: 'Mummy',
    musk: 'Elon Musk',
    pumpkin: 'Pumpkin',
    shiba: 'Shib',
    trump: 'Donald Trump',
    vitalik: 'Vitalik',
    witch: 'Witch',
    computer:'Computer Man'
  };
  let getColor = (upgradeLuck) => {
    if (upgradeLuck >= 80) {
      return '#33ff96'; // Xanh lá cây
    } else if (upgradeLuck >= 60) {
      return '#00bfff'; // Màu chuyển tiếp giữa xanh lá và xanh dương
    } else if (upgradeLuck >= 40) {
      return '#ffbf00'; // Màu chuyển tiếp
    } else if (upgradeLuck >= 20) {
      return '#cb2944'; // Gần xanh dương
    } else {
      return '#cb2944'; // Xanh dương
    }
  };
  return (
    <>
      <PrimaryButton onClick={handleOpen} sx={{ background: '#37FFB7', width: '100%', height: 30, color: "#000" }}>Upgrade</PrimaryButton>
      <Modal
        open={open}
        handleClose={handleClose}
      >
        <ButtonBase onClick={handleClose} sx={{ position: 'absolute', top: 15, right: 15 }}>
          <img src="images/close-icon.png" style={{ width: 15, height: 15 }} />
        </ButtonBase>
        <Typography variant="h3">{characterMap[nft?.image]}</Typography>
        <Stack>
          <FlexBetween>
            <Stack py={1} flexGrow={1}>
              <Typography color={'#FFBB38'} variant="h4">ID: {nft?.id}</Typography>
              <Typography variant="h5">Rarity: {nft?.rarity}</Typography>
              <Typography variant="h5">Lv: {nft?.level}</Typography>
              <Typography variant="h5">Rating: {nft?.stats}</Typography>
            </Stack>
            <Stack flexGrow={1} alignItems={'center'} justifyContent={'center'}>
              <img src={`images/gifs/${nft?.image}/stand.gif`} style={{ height: 85 }} />
            </Stack>
          </FlexBetween>
          <Divider />
          <FlexBetween sx={{ pt: 2 }}>
          {nft?.level < 30 ? <>
            <Stack>
              <Typography variant="h5">Upgrade to Lv. {Number(nft?.level) +1}</Typography>
              <Typography variant="h5" color={'#FFBB38'}>{nft?.SDGForUpdate} $TRT</Typography>
              <Typography variant="h5" color={getColor(nft?.upgradeLuck)}>Luck {nft?.upgradeLuck}%</Typography>
            </Stack>
            </> : <>
              <Stack>
                {/* <Typography variant="h5">Upgrade to Lv. {Number(nft.level) +1}</Typography> */}
                <Typography variant="h5" color={'#FFCE44'}>Warrior at max level.</Typography>
                {/* <Typography variant="h5" color={getColor(nft.upgradeLuck)}>Luck {nft.upgradeLuck}%</Typography> */}
              </Stack>
            </>}
            {nft?.level < 30 ? <PrimaryButton loading={isLoading} onClick={handleUpdate} sx={{ background: '#37FFB7', px: 2, height: 30, color: "#000", fontSize: '1.2rem' }}>Upgrade</PrimaryButton> : <></>}
          </FlexBetween>
        </Stack>
      </Modal>
    </>
  );
};

export const StyledButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  background: theme.palette.background.default,
  borderRadius: "0.8rem ",
  padding: "1rem",
  flexDirection: "column",
  color: theme.palette.text.primary,
  width: "100%",
  "&.active": {
    background: `${theme.palette.primary.main} !important`,
    color: "#000",
  },
}));

export const StyledInput = styled(OutlinedInput)(({ theme }) => ({
  margin: "1.5rem 0px",
  input: {
    textAlign: "center",
  },
  "& fieldset": {
    borderRadius: "0.5rem",
    border: `1px solid ${theme.palette.text.disabled} !important`,
  },
}));
