import {
  ButtonBase,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FlexRowAlign } from "components/flexbox";
import { useCallback, useEffect, useState } from "react";
import { BoxContainer, PrimaryButton } from "./styled";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';
import { randomNumber } from "../../utils";
import useAxios from "../../hooks/useAxios";
import useSettings from "../../hooks/useSettings";
import { fCurrency } from "../../utils/format";
import Decimal from "decimal.js";

export default function Leaderboard() {
  const theme = useTheme();
  const navigate = useNavigate()
  const { settings } = useSettings();
  const [reward, setReward] = useState(0)
  const [top10, setTop10] = useState([])

  const [indexWL, setIndexWL] = useState(0)

  const { callApi } = useAxios()

  const fetchData = useCallback(async () => {
    if (!settings.token) return;
    try {
        const results = await callApi('get', `/pvp/current-reward`)
        setReward(results?.reward)
      }
     catch (e) {
        console.log('ignoew')
    }
    }, [callApi, settings.token])

    const fetchTopData = useCallback(async () => {
      if (!settings.token) return;
      try {
          if(indexWL===0){
            const results = await callApi('get', `/pvp/top-10-win`)
            setTop10(results)
          } else {
            const results = await callApi('get', `/pvp/top-10-lose`)
            setTop10(results)
          }
        }
       catch (e) {
          console.log('ignoew')
      }
      }, [callApi, settings.token, indexWL])

    useEffect(() => {
      fetchData()
      fetchTopData()
    }, [
      fetchData,fetchTopData, settings.token, indexWL
    ])

   // Calculate 50% of the total reward for top win/lose
   const topReward = new Decimal(reward).mul(0.50); // 50% of the total reward goes to the top 10 winners and losers
   const topOneReward =  new Decimal(topReward).mul(0.19).toFixed(2); // 19% goes to top 1 winner and top 1 loser
   const remainingReward = new Decimal(topReward).sub(topOneReward).div(9).toFixed(2)
   
    
  return (
    <BoxContainer sx={{ alignItems: 'center' }}>
      <ButtonBase onClick={() => navigate(-1)} sx={{ padding: 0, position: 'absolute', top: '1rem', left: '1rem', }}>
        <img src="images/button-back.png" style={{ width: 40, height: 40 }} />
      </ButtonBase>
      <Typography mt={1} variant="h3">Leaderboard</Typography>
      <Typography color={'text.secondary'} lineHeight={1} sx={{ opacity: 0.6 }}>Curent Reward: {fCurrency(reward,2)} TON</Typography>
      <Typography color={'text.secondary'} lineHeight={1} textAlign={'center'} mt={1} sx={{ opacity: 0.6, maxWidth: 200 }}>The reward will be distributed at the end of the week</Typography>
      <FlexRowAlign gap={2} mt={1}>
        <PrimaryButton onClick={()=> { setIndexWL(0)}} sx={{ background: '#3DFF77', padding: '0px 2rem', color: "#000" }}>Victory</PrimaryButton>
        <PrimaryButton onClick={()=> { setIndexWL(1)}} sx={{ background: '#D02D2F', padding: '0px 2rem' }}>Defeat</PrimaryButton>
      </FlexRowAlign>
      <Stack px={2} mt={1} spacing={0.5} py={1} sx={{ border: '3px solid #084B7D', width: '100%', minHeight: 130, background: '#081922', overflowY: 'scroll' }}>
        <Grid container>
          <Grid item xs={1}>
            <Typography>#</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Name</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>W-L</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>Score</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography textAlign={'right'}>Reward</Typography>
          </Grid>
        </Grid>
        <Grid container maxHeight={'calc(100vh - 200px)'} sx={{ overflowY: 'scroll' }}>
          {
            top10.map((x, index) => <Log key={index} data={{ id: index+1, name: x.userName ?? 'hidden', win: x.countWin, loss: x.countLose, score: (indexWL===0 ?  x.countWin- x.countLose : x.countLose- x.countWin ), amount: (indexWL===0? x.totalWin : -x.totalLose ), rewardAmount: index===0? topOneReward :  remainingReward }} />)
          }
        </Grid>
      </Stack>
    </BoxContainer>
  );
}

const Log = ({ data }) => {
  const { id, name, win, loss, score, amount, rewardAmount } = data
  return <>
    <Grid item xs={1} py={0.5} borderBottom={'1px solid rgba(255,255,255,0.3)'}>
      <Typography>{id}</Typography>
    </Grid>
    <Grid item xs={3} py={0.5} borderBottom={'1px solid rgba(255,255,255,0.3)'}>
      <Typography>{name}</Typography>
    </Grid>
    <Grid item xs={3} py={0.5} borderBottom={'1px solid rgba(255,255,255,0.3)'}>
      <Typography>{win}-{loss}</Typography>
    </Grid>
    <Grid item xs={2} py={0.5} borderBottom={'1px solid rgba(255,255,255,0.3)'}>
      <Typography color={amount >= 0 ? 'success.main' : 'error.main'}>{amount}</Typography>
    </Grid>
    <Grid item xs={3} py={0.5} borderBottom={'1px solid rgba(255,255,255,0.3)'}>
      <FlexRowAlign gap={0.5} justifyContent='flex-end'>
        <Typography textAlign={'right'} >{rewardAmount}</Typography>
        <img src="/images/TON.png" width={13} height={13} />
      </FlexRowAlign>
    </Grid>
  </>
}
