import { SettingsContext } from "contexts/settingsContext";
import { useCallback, useContext, useEffect, useState } from "react";
import useSettings from './useSettings'

import Decimal from "decimal.js";
import TelegramProvider, { useTelegram } from "contexts/TelegramContext";
import useAxios from "./useAxios";
import { retrieveLaunchParams } from '@telegram-apps/sdk';
import axios from "axios";

const useInitUserData = () => {
    const { settings, saveSettings } = useSettings();
    const isLocalhost = () => {
        const hostname = window.location.hostname;
        const currentPath = window.location.pathname;

        return hostname === 'localhost' || hostname === '127.0.0.1' || currentPath ==='/dashboard' || currentPath.startsWith('/landing') ;
      };

    const { initDataRaw, startParam } = isLocalhost() == true ?  { initDataRaw :'', startParam:''}  : retrieveLaunchParams();
    const register = useCallback(async () => {
        if (!!initDataRaw && settings?.token === 'needToLogin') {
          const API =
          process.env.REACT_APP_IS_DEV === "true"
            ? "http://localhost:2000/api" : 'https://api.tonrealm.com/api';
          const headers = {
            Authorization: `tma ${initDataRaw}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Content-Security-Policy-Report-Only":
              "script-src https://accounts.google.com/gsi/client; frame-src https://accounts.google.com/gsi/; connect-src https://accounts.google.com/gsi/",
          };
          const resp = await axios.post(API + '/auth/create-user', {
            refCode: startParam
          }, {
            headers: headers,
          })
          saveSettings({
            ...settings,
            token: resp.data.token
          })
        }
      }, [initDataRaw, saveSettings, settings, startParam])

      useEffect(() => {
        if (!initDataRaw || settings?.token !== 'needToLogin') return
        //// first login from app
        register()
      }, [initDataRaw, settings?.token, register])
    return { register }
};

export default useInitUserData;
