import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  ButtonBase,
  Link,
  OutlinedInput,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FlexBetween } from "components/flexbox";
import Modal from "components/modal/Modal";
import { t } from "i18next";
import { useState, useEffect, useCallback } from "react";
import useAxios from "../../hooks/useAxios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { fCurrency, shortenNumber } from "../../utils/format";
import toast from "react-hot-toast";
import TonWeb from "tonweb";
import { Decimal } from "decimal.js";
import Iconify from "../../components/Iconify";
import {
  ButtonAction,
  ButtonWithdraw,
  ModalContentBox,
  TitleModalBox,
} from "./styled";
import { NumberInput } from "../../components/NumberInput";
import { useTonAddress, useTonConnectModal, useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import { beginCell } from "@ton/core";
import TelegramProvider, { useTelegram } from "contexts/TelegramContext";

const DepositForm = ({ tokenInfo, onChange, userData, children, btnSx }) => {
  const { callApi } = useAxios();
  const { symbol, min } = tokenInfo;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [balance, setBalance] = useState(0);
  const wallet = useTonWallet();
  const walletAddress = useTonAddress(true);
  const [tonConnectUi] = useTonConnectUI();
  const { user, webApp } = useTelegram();
  const { open: openConnectWallet } = useTonConnectModal()

  const fetchBalance = useCallback(async () => {
    if (!walletAddress) return
    const response = await callApi('get', `/user/wallet-balance?wallet=${walletAddress}`)
    setBalance(response.balance)
  }, [callApi, walletAddress])


  useEffect(() => {
    fetchBalance()
  }, [fetchBalance])

  const RedeemOptions = [
    {
      title: `${t("With 3 days to redeem, you get 50% of")} ${symbol}`,
      value: 0.5,
      prioriy: "1",
      label: t("3 days"),
    },
    {
      title: `${t("With 7 days to redeem, you get 100% of")} ${symbol}`,
      value: 1,
      prioriy: "2",
      label: t("7 days"),
    },
    {
      title: `${t("With 14 days to redeem, you get 150% of")} ${symbol}`,
      value: 1.5,
      prioriy: "3",
      label: t("14 days"),
    },
  ];

  const [redeemSelected, setRedeemSelected] = useState(RedeemOptions[0]);

  const Schema = Yup.object().shape({
    amount: Yup.number().required(),
  });

  const formik = useFormik({
    initialValues: {
      amount: "",
    }
  })

  const handleSendTon = async () => {
    setIsSubmitting(true);
    try {
      const body = beginCell()
        .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
        .storeStringTail(`${user.id.toString()}`) // write our text comment
        .endCell();
      const defaultTx = {
        // The transaction is valid for 10 minutes from now, in unix epoch seconds.
        validUntil: Math.floor(Date.now() / 1000) + 600,
        messages: [
          {
            // The receiver's address.
            address: "UQAAiPseoHQDXx-Xdc1x24tGENQmlbsGR39gJiVOxRdz1hlD",
            // Amount to send in nanoTON. For example, 0.005 TON is 5000000 nanoTON.
            amount: new Decimal(formik.values.amount).mul(Decimal.pow(10, 9)).toFixed(),
            payload: body.toBoc().toString("base64"),
          },
        ],
      };
      await tonConnectUi.sendTransaction(defaultTx, {
        notifications: "success",
      });

      toast.success(
        "🎉 You have deposit TON, please wait transaction confirm on network",
        {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
    } catch (e) {
      console.log(e)
      toast.error("An unexpected error occurred.", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitting(false);
    }
  }



  const handleDisconnect = async () => {
    await tonConnectUi.disconnect()
  }


  const { handleSubmit, getFieldProps, errors, touched, handleReset, values } =
    formik;

  const handleOpen = () => {
    if (symbol === 'TRT') return;
    setOpen(true);
  };
  const handleFillMax = (value) => {
    formik.setFieldValue("amount", value);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    tokenInfo && (
      <>
        <ButtonWithdraw sx={{ height: 20, px: 0, ...btnSx }} variant='contained' onClick={handleOpen}>
          {
            children ? children : <>
              <Box
                component='img'
                src={`/images/${symbol}.png`}
                alt=''
                sx={{
                  width: 20,
                  height: 20,
                }}
              />
              <Typography pl={1} fontWeight={900} variant="h5" color={"#fff"}>
                {balance ? shortenNumber(balance, 4) : 0} {symbol}
              </Typography>
            </>
          }
        </ButtonWithdraw >
        <Modal open={open} handleClose={handleClose}>
          <TitleModalBox>
            <Typography variant='h2' textAlign={"center"} fontWeight={600}>
              {t("Deposit")} {symbol}
            </Typography>
            <Typography variant="h4" color={'text.secondary'}>Minimum amount: 0.4 TON</Typography>
          </TitleModalBox>
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
              <ModalContentBox sx={{ alignItems: 'center' }}>
                <Stack gap={1.5}>
                  <FlexBetween>
                    <Box
                      sx={{
                        position: "relative",
                        padding: "0.5rem 1rem 0.5rem 2.5rem",
                        minHeight: "unset",
                        overflow: "visible",
                      }}>
                      <Box
                        component='img'
                        src={`/images/${symbol}.png`}
                        alt=''
                        sx={{
                          position: "absolute",
                          left: "4px",
                          top: "3px",
                          borderRadius: "50%",
                          width: 23,
                          height: 23
                        }}
                      />
                      <Typography fontWeight={600}>{symbol}</Typography>
                    </Box>
                    <Box
                      sx={{
                        position: "relative",
                        padding: "0.5rem 1rem",
                        minWidth: "100px",
                      }}>
                      <Typography
                        color={"text.primary"}
                        fontWeight={600}
                        sx={{ textAlign: "right" }}>
                        {balance > 0 ? fCurrency(balance, 4) : 0}
                      </Typography>
                    </Box>
                  </FlexBetween>
                  <Stack gap={1}>
                    <NumberInput
                      type='number'
                      placeholder={t("Enter amount of {{symbol}} here").replace(
                        "{{symbol}}",
                        symbol,
                      )}
                      sx={{
                        '& fieldset': {
                          borderRadius: 0,
                          border: '2px solid #064a74 !important'
                        }
                      }}
                      size='small'
                      fullWidth
                      InputProps={{
                        endAdornment:
                          balance > 0 && (
                            <Link
                              onClick={() =>
                                handleFillMax(
                                  fCurrency(balance, 4).replace(/,/g, ""),
                                )
                              }>
                              {t("Max")}
                            </Link>
                          )

                      }}
                      {...getFieldProps("amount")}
                      error={Boolean(errors.amount) && Boolean(touched.amount)}
                      helpertext={Boolean(touched.amount) && errors.amount}
                    />
                  </Stack>

                  <FlexBetween justifyContent={"center"}>
                    {wallet?.account ? (
                      <Stack width={'100%'} spacing={2}>
                        <LoadingButton
                          sx={{ background: 'url("images/button.png") 50% 50% no-repeat', backgroundSize: 'contain', minHeight: 40, width: '100%', fontSize: '2rem' }}
                          onClick={handleSendTon}
                          loading={isSubmitting}
                        >
                          {t("Deposit")}
                        </LoadingButton>
                        <ButtonBase
                          sx={{ backgroundSize: 'contain', height: 40, width: '100%', fontSize: '1.3rem' }}
                          onClick={handleDisconnect}
                          loading={isSubmitting}
                        >
                          {t("Disconnect Wallet")}
                        </ButtonBase>
                      </Stack>
                    ) : (
                      <ButtonBase onClick={openConnectWallet} sx={{ background: 'url("images/button.png") 50% 50% no-repeat', backgroundSize: 'contain', height: 40, width: '100%', fontSize: '2rem' }}>
                        Connect wallet
                      </ButtonBase>
                    )}
                  </FlexBetween>

                </Stack>

              </ModalContentBox>

            </Form>
          </FormikProvider>
        </Modal>
      </>
    )
  );
};

export default DepositForm;
