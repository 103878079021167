import TelegramProvider, { useTelegram } from "contexts/TelegramContext";
import {
    TonConnectButton,
    useTonWallet,
    useTonConnectUI,
    SendTransactionRequest,
} from "@tonconnect/ui-react";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    ButtonBase,
    Grid,
    OutlinedInput,
    Stack,
    Typography,
    styled,
    useTheme,
} from "@mui/material";
import { FlexBetween, FlexRowAlign } from "components/flexbox";
import Decimal from "decimal.js";
import { t } from "i18next";
import ProfitIcon from "icons/ProfitIcon";
import TNXIcon from "icons/TNXIcon";
import { useCallback, useEffect, useMemo, useState } from "react";

import { BoxContainer, BoxFooter, BrownBox, PrimaryButton, TitleBox, TypographyOutline } from "./styled";
import { useNavigate } from "react-router-dom";
import { UpgradeForm } from "../../page-sections/homepage/UpgradeForm";
import { ConnectWalletPopup } from "../../components/ConnectWallet";
import { GiftOpened } from "../../page-sections/homepage/GiftOpened";
import { Map1 } from "../../page-sections/battle/maps/Map1";
import Slider from "react-slick";
import { BattleContent, WarzoneLogs } from "../../page-sections/battle/BattleContent";
import { shortenNumber } from "../../utils/format";
import useUserData from "../../hooks/useUserData";
import WithdrawForm from "../../page-sections/homepage/WithdrawForm";
import DepositForm from "../../page-sections/homepage/DepositForm";
import useSocket from "../../hooks/useSocket";
import toast from "react-hot-toast";

export const Battle = () => {

    const theme = useTheme();
    const navigate = useNavigate()
    const [tabIndex, setTabIndex] = useState(0)
    const TRTMaps = [
        {
            id: 1,
            battleMap: <Map1 />,
            thumbnail: '/images/map.png',
            symbol: 'TRT',
            symbolColor: '#FFC809',
            rank: 'Common',
            rankColor: '#AAAAB2',
            pool: 1000,
        }
    ]
    const TONMaps = [
        {
            id: 2,
            battleMap: <Map1 />,
            thumbnail: '/images/map.png',
            symbol: 'TON',
            symbolColor: 'primary',
            rank: 'Common',
            rankColor: '#AAAAB2',
            pool: 1,
        },
    ]

    const SlickSettings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    return (
        <BoxContainer sx={{ alignItems: 'center' }}>
            <ButtonBase onClick={() => navigate('/')} sx={{ padding: 0, position: 'absolute', top: '1rem', left: '1rem', }}>
                <img src="images/button-back.png" style={{ width: 40, height: 40 }} />
            </ButtonBase>
            <FlexBetween gap={1} sx={{ background: "#000", height: 40, padding: '5px', width: 'fit-content' }}>
                <PrimaryButton onClick={() => setTabIndex(0)} className={tabIndex === 0 ? '' : 'non-active'} sx={{ background: theme.palette.primary.main, height: '100%' }}>
                    TRT Map
                </PrimaryButton>
                <PrimaryButton onClick={() => setTabIndex(1)} className={tabIndex === 1 ? '' : 'non-active'} sx={{ background: theme.palette.primary.main, height: '100%' }}>
                    TON Map
                </PrimaryButton>
            </FlexBetween>
            <Stack pt={2.5} pb={4} alignItems={'center'}>
                <Balance symbol={tabIndex === 0 ? 'TRT' : 'TON'} />
            </Stack>
            <Box flexGrow={1} />
            <Stack sx={{ width: 'calc(100% + 1rem)' }} px={6}>
                {
                    tabIndex === 0 ? <Stack sx={{ position: 'relative' }}>
                        <Slider {...SlickSettings}>
                            {
                                TRTMaps.map((item, index) => <BattleContent
                                    key={item?.id}
                                    data={item}
                                />)
                            }
                        </Slider>
                    </Stack> : <Stack>
                        <Slider {...SlickSettings}>
                            {
                                TONMaps.map((item, index) => <BattleContent
                                    key={item?.id}
                                    data={item}
                                />)
                            }
                        </Slider>
                    </Stack>
                }
            </Stack>
            <Box flexGrow={1} />
            <Stack spacing={1} pb={2}>
                <PrimaryButton onClick={() => navigate('/leaderboard')} sx={{ height: 30, width: 170 }}>Leaderboard</PrimaryButton>
                <PrimaryButton onClick={() => navigate('/warzone')} sx={{ height: 30, width: 170 }}>Warzone</PrimaryButton>
                <PrimaryButton onClick={() => navigate('/history')} sx={{ height: 30, width: 170 }}>History</PrimaryButton>
            </Stack>
        </BoxContainer>
    );
};


const Balance = ({ symbol }) => {
    const theme = useTheme()
    const { userData, fetchUserData } = useUserData();
    const balance = userData?.userBalances.find(
        (balance) => balance.token === symbol,
    )?.balance ?? 0
    const { on, off } = useSocket();
    const handleDepositSuccess = useCallback(async () => {
        await fetchUserData()
        toast.success("🎉 Deposit successful!", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }, [])
    useEffect(() => {
        on("DepositTon", handleDepositSuccess);
        return () => {
            off("DepositTon", handleDepositSuccess);
        };
    }, [handleDepositSuccess, off, on]);
    return (
        <Stack textAlign={'center'} alignItems={'center'} justifyContent={'center'} sx={{ width: '100%', position: "relative" }}>
            <img src="images/box-bg.png" style={{ width: 'auto', height: '60px', marginTop: '-3px', margin: "auto" }} />
            <FlexBetween px={3} sx={{ position: 'absolute', width: '100%' }}>
                <Stack alignItems={'center'} width={'100%'}>
                    <Typography variant="h5" color={'text.secondary'} sx={{ opacity: 0.7 }}>Balance</Typography>
                    <FlexRowAlign>
                        {/* <img src="images/fire.png" style={{ width: 'auto', height: 20, marginTop: '-3px' }} /> */}
                        <Typography variant="h3" color={symbol === 'TRT' ? '#FFC809' : 'primary'}>{shortenNumber(balance, 2)} {symbol}</Typography>
                    </FlexRowAlign>
                </Stack>
            </FlexBetween>
            {
                symbol === 'TON' ? <FlexBetween gap={2} sx={{ position: 'absolute', top: '115%' }}>
                    <DepositForm
                        tokenInfo={{ symbol: "TON", min: 0.4 }}
                        onChange={() => fetchUserData()}
                        userData={userData}
                        btnSx={{ width: '100%' }}
                    >
                        <img src="/images/btn-deposit.png" style={{ width: '100%', height: 30 }} />
                        <Typography variant="h5" sx={{ position: 'absolute', color: '#000' }}>Deposit</Typography>
                    </DepositForm>
                    <WithdrawForm
                        tokenInfo={{ symbol: "TON", min: 0.4 }}
                        onChange={() => fetchUserData()}
                        userData={userData}
                        btnSx={{ width: '100%' }}
                    >
                        <img src="/images/btn.png" style={{ width: '100%', height: 30 }} />
                        <Typography variant="h5" sx={{ position: 'absolute', color: '#000' }}>Withdraw</Typography>
                    </WithdrawForm>
                </FlexBetween> : ''
            }
        </Stack>
    );
};


export const StyledButton = styled(Button)(({ theme }) => ({
    border: `1px solid #B7B7B7`,
    background: theme.palette.background.default,
    borderRadius: "0.8rem",
    position: "relative",
    flexDirection: "column",
    padding: 0,
    img: {
        filter: "saturate(0)",
    },
    "&.active": {
        borderColor: "#FAFF00",
        boxShadow:
            "inset 0px 4px 0px rgba(255,255,255,0.25), 2px 4px 0px rgba(0,0,0)",
        img: {
            filter: "saturate(1)",
        },
    },
}));

export const StyledInput = styled(OutlinedInput)(({ theme }) => ({
    margin: "1.5rem 0px",
    background: "#000",
    borderRadius: "0.5rem",
    input: {
        textAlign: "center",
        color: "white",
        fontWeight: 600,
    },
    "& fieldset": {
        border: `1px solid #000!important`,
    },
}));

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <ButtonBase onClick={onClick} sx={{ position: 'absolute', top: 'calc(50% - 15px)', right: '-30px' }} disableRipple>
            <img src="/images/arrow-right.png" width={30} height={30} style={{ objectFit: 'contain' }} />
        </ButtonBase>
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <ButtonBase onClick={onClick} sx={{ position: 'absolute', top: 'calc(50% - 15px)', left: '-30px' }} disableRipple>
            <img src="/images/arrow-left.png" width={30} height={30} style={{ objectFit: 'contain' }} />
        </ButtonBase>
    );
}