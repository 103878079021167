import { forwardRef, useEffect, useRef, useState } from 'react';
import { TextField } from "@mui/material"

export const NumberInput = forwardRef(function NumberInput(props, ref) {
    const {
        decimal = 18,
        value: initialValue,
        onChange,
    } = props
    const inputRef = useRef()
    const [value, setValue] = useState('');

    const formatValue = (inputValue, decimalPlaces = 18) => {
        // Convert input value to a string and replace commas with periods
        let sanitizedValue = inputValue.toString().replace(/,/g, '.');

        // Remove non-numeric characters except the decimal point
        sanitizedValue = sanitizedValue.replace(/[^\d.]/g, '');

        // Ensure only one decimal point
        sanitizedValue = sanitizedValue.replace(/(\..*)\./g, '$1');

        // Limit the number of decimal places
        const regex = new RegExp(`^(\\d+)(\\.\\d{0,${decimalPlaces}})?`);
        const match = sanitizedValue.match(regex);

        return match ? match[0] : '';
    };

    useEffect(() => {
        if (initialValue) {
            let sanitizedValue = formatValue(initialValue, decimal)
            setValue(sanitizedValue);
        }
    }, [initialValue]);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        const sanitizedValue = formatValue(inputValue, decimal)
        setValue(sanitizedValue);
        const customEvent = {
            ...event,
            target: {
                ...event.target,
                ...props,
                value: sanitizedValue,
            },
        };
        if (onChange) {
            onChange(customEvent);
        }
        event.preventDefault()
    };

    const handlePaste = async (event) => {
        const pasteData = await event.clipboardData.getData('text');
        const sanitizedValue = formatValue(pasteData, decimal)
        setValue(sanitizedValue);
        const customEvent = {
            ...event,
            target: {
                ...event.target,
                ...props,
                value: sanitizedValue,
            },
        };
        if (onChange) {
            onChange(customEvent);
        }
        event.preventDefault()
    }

    const handleInput = async (event) => {
        const inputValue = event.target.value;
        const sanitizedValue = formatValue(inputValue, decimal)
        setValue(sanitizedValue);
        const customEvent = {
            ...event,
            target: {
                ...event.target,
                ...props,
                value: sanitizedValue,
            },
        };
        if (onChange) {
            onChange(customEvent);
        }
        event.preventDefault()
    }

    return <TextField
        {...props}
        inputProps={{
            className: 'number',
            inputMode: 'decimal',
            onChange: handleInputChange,
            onInput: handleInput,
            onPaste: handlePaste,
        }}
        value={value}
        type='text'
        inputRef={inputRef}
    />
});
