import { Modal, Stack, Typography, useTheme, ButtonBase, Grid, Backdrop, alpha, Collapse, Box } from "@mui/material"
import { memo, useCallback, useEffect, useState } from "react"
import FlexRowAlign from "../../components/flexbox/FlexRowAlign"
import { PrimaryButton } from "../../pages/mobile/styled"
import FlexBetween from "../../components/flexbox/FlexBetween"
import { shortenNumber } from "../../utils/format"
import { theme } from "antd"
import { RarityColors } from "../../utils/constants"
import useAvailableWarriors from "../../hooks/useAvailableWarriors"
import useWarzoneLogs from "../../hooks/useWarzoneLogs"

import Iconify from "../../components/Iconify"
import { animated, useSpring } from '@react-spring/web'
import { BattleProgress } from "./BattleProgress"
import useSocket from "./../../hooks/useSocket";
import useUserData from "./../../hooks/useUserData";
import useAxios from "../../hooks/useAxios"
import toast from "react-hot-toast"
import useSettings from "../../hooks/useSettings"
import InfiniteScroll from "react-infinite-scroller"

let interval = null



export const BattleContent = memo(({ data }) => {
    const theme = useTheme()
    const [open, setOpen] = useState(false)
    const [start, setStart] = useState(false)
    const [matchData, setMatchData] = useState(null)
    const handleStartMatch = (data) => {
        setMatchData(data)
        setStart(true)
    }
    const handleEndMatch = useCallback(() => {
        setMatchData(null)
        setStart(false)
    }, [])
    return <>
        <Stack>
            <Stack p={2} sx={{ position: 'relative' }}>
                <img src="/images/box-bg-3.png" />
                <img src={data?.thumbnail} style={{ position: 'absolute', padding: '2.5rem', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'contain' }} />
                <FlexRowAlign gap={1} sx={{ position: 'absolute', top: '3rem', width: '100%', left: 0 }}>
                    <Typography variant="h4">Pool:</Typography>
                    <Typography variant="h4" color={data.symbolColor}>{data?.pool} {data?.symbol}</Typography>
                </FlexRowAlign>
                <ButtonBase onClick={() => setOpen(true)} sx={{ background: 'url("/images/start-btn-bg.png") 50% 50% no-repeat', backgroundSize: 'contain', position: 'absolute', bottom: '2.5rem', width: '100%', height: 30, left: '50%', translate: '-50% 0%', fontSize: '1.4rem', fontWeight: 900 }}>
                    START NOW
                </ButtonBase>
            </Stack>
        </Stack>
        <Modal open={open} onClose={() => setOpen(false)}>
            <Stack sx={{ width: '100vw', height: '100vh', margin: 'auto', maxWidth: 444, background: theme.palette.background.default, position: 'relative' }}>
                <ButtonBase onClick={() => setOpen(false)} sx={{ padding: 0, position: 'absolute', top: '1rem', left: '1rem', zIndex: 1000 }}>
                    <img src="images/button-back.png" style={{ width: 40, height: 40 }} />
                </ButtonBase>

                {
                    start ? <Match data={{ map: data, ...matchData }} onComplete={() => handleEndMatch()} /> : <HerosSelector poolData={data} onFoundMatch={(matchData) => handleStartMatch(matchData)} />
                }
                {start ?
                    <Stack pt={2.5} alignItems={'center'} flexGrow={1} overflow={'scroll'}>
                        <Typography variant="h4" mb={2}>You are in warzone</Typography>
                        {/* <Stack p={2}>
                        {
                            data?.battleMap
                        }
                    </Stack>   */}
                        <WarzoneLogs data={data} />
                        {/* <PrimaryButton sx={{ mt: 2, height: 40, width: 100, background: '#FF5638 !important' }}>
                        <Typography variant="h5" color={"#000"}>Stop battle</Typography>
                    </PrimaryButton> */}
                    </Stack>
                    : <>

                    </>
                }
            </Stack>
        </Modal>
    </>
})

export const WarzoneLogs = memo(({ data }) => {
    const theme = useTheme()
    const { warzoneLogs } = useWarzoneLogs()
    const [mainLogs, setMainLogs] = useState([]);
    const [myLogs, setMyLogs] = useState([]);

    const { on, off } = useSocket()
    const handleNewLogs = useCallback((logString) => {
        setMainLogs(prevLogs => {
            // Nếu mảng đã có phần tử, xóa phần tử đầu tiên (cũ nhất)
            const updatedLogs = [...prevLogs];
            if (updatedLogs.length > 0) {
                updatedLogs.shift();
            }

            // Thêm record mới vào cuối mảng
            const newRecord = JSON.parse(logString)
            updatedLogs.push(newRecord);

            return updatedLogs; // Trả về mảng mới để cập nhật state
        });
    }, []);

    useEffect(() => {
        setMainLogs(warzoneLogs?.data ?? []);
        setMyLogs(warzoneLogs?.myLogs ?? []);
    }, [warzoneLogs]); // Cập nhật khi warzoneLogs thay đổi

    const handleNewMyLogs = useCallback((logString) => {
        setMyLogs(prevLogs => {
            // Nếu mảng đã có phần tử, xóa phần tử đầu tiên (cũ nhất)
            const updatedLogs = [...prevLogs];
            if (updatedLogs.length > 0) {
                updatedLogs.shift();
            }

            // Thêm record mới vào cuối mảng
            const newRecord = JSON.parse(logString)
            updatedLogs.push(newRecord);

            return updatedLogs; // Trả về mảng mới để cập nhật state
        });
    }, []);
    useEffect(() => {
        on("newWarZone", handleNewLogs);
        return () => {
            off("newWarZone", handleNewLogs);
        };
    }, [handleNewLogs, off, on]);
    useEffect(() => {
        on("newMyWarZone", handleNewMyLogs);
        return () => {
            off("newMyWarZone", handleNewMyLogs);
        };
    }, [handleNewMyLogs, off, on]);
    return <Stack width={'100%'} px={2}>
        <Stack sx={{ position: 'relative', width: 'fit-content', margin: '0px auto -3px' }}>
            <img src="/images/box-bg-7.png" style={{ height: 32, }} />
            <Typography variant="h5" textAlign={'center'} sx={{ position: 'absolute', top: 0, left: 0, right: 0, lineHeight: '32px' }}>Warzone</Typography>
        </Stack>
        <Stack px={2} spacing={0.5} py={1} sx={{ border: '3px solid #53536C', width: '100%', minHeight: 130, background: theme.palette.background.default }}>
            {
                mainLogs.map((log, index) => <Log key={`${index}-${log?.type}`} data={log} />)
            }
        </Stack>
        <Stack px={2} spacing={0.5} py={1} sx={{ border: '3px solid #53536C', borderTop: 'none', width: '100%', minHeight: 50, background: theme.palette.background.default }}>
            {
                myLogs.map((log, index) => <Log key={`${index}-${log?.type}`} data={log} />)
            }
        </Stack>
    </Stack>
})

export const HerosSelector = ({ poolData, onFoundMatch }) => {
    const theme = useTheme()
    const [page, setPage] = useState(1)
    const [heroSelected, setHeroSelected] = useState([])
    const [matchWaiting, setMatchWaiting] = useState(false)
    const [isStart, setIsStart] = useState(false)
    const [waitingSeconds, setWaitingSeconds] = useState(0)
    const { on, off } = useSocket();
    const { callApi } = useAxios()
    const { userData, fetchUserData } = useUserData()
    const [hasMore, setHasMore] = useState(true)
    const { settings } = useSettings();
    const [data, setData] = useState({ total: 0, data: [] })
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const fetchData = useCallback(async () => {
        if (!settings.token) return;
        setIsLoadingMore(true)
        try {
            const results = await callApi('get', `/nft/available-warriors?poolId=${poolData.id}&page=${page}&limit=${30}`)
            if (results && results?.data?.length > 0) {
                setData((nft) => {
                    const mergedData = [
                        ...nft.data.filter(existingItem => !results.data.some(newItem => newItem.id === existingItem.id)),
                        ...results.data
                    ];
                    return {
                        total: results.total,
                        hasMore: results.total > page * 30,
                        data: mergedData
                    };
                })
            } else {
                setHasMore(false)
            }

        } catch (e) {
            console.log('ignoew')
        } finally {
            setIsLoadingMore(false)
        }
    }, [callApi, page, poolData.id, settings.token])

    const handleGetData = (page) => {
        if (isLoadingMore) return
        setPage(page)
    }

    const handleSelect = (hero) => {
        const found = heroSelected.find(item => item.id === hero.id)
        if (Boolean(found)) {
            setHeroSelected(heroSelected.filter(item => item.id !== hero.id))
        } else {
            if (heroSelected.length < 3)
                setHeroSelected([...heroSelected, hero])
        }
    }
    useEffect(() => {
        fetchData()
    }, [
        fetchData, settings.token, page
    ])

    useEffect(() => {
        if (data) {
            setIsLoadingMore(false)
        }
    }, [data])

    const handleCancleMatching = useCallback(async () => {
        setMatchWaiting(false)
        try {
            await callApi('post', '/pvp/cancel-mathching', {
                poolId: poolData.id,
                nfts: heroSelected.map((hero) => { return { nftId: hero.id } })
            })
        } catch (error) {
            if (error.response && error.response.status === 400) {
                toast.error(`Error: ${error.response.data.message}`, {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (error.response && error.response.status === 429) {
                toast.error(`Too many request`, {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("An unexpected error occurred.", {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }, [callApi, heroSelected, poolData.id])

    const handleMatching = useCallback(async () => {
        setMatchWaiting(true)
        try {
            await callApi('post', '/pvp/waiting-players', {
                poolId: poolData.id,
                nfts: heroSelected.map((hero) => { return { nftId: hero.id } })
            })
        } catch (error) {
            setMatchWaiting(false)
            if (error.response && error.response.status === 400) {
                toast.error(`Error: ${error.response.data.message}`, {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (error.response && error.response.status === 429) {
                toast.error(`Too many request`, {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("An unexpected error occurred.", {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }, [callApi, heroSelected, poolData.id])

    const handleCancelAuto = useCallback(async () => {
        try {
            await callApi('post', '/pvp/turn-off-auto', {
                poolId: poolData.id,
            })
            await fetchUserData()
        } catch (error) {
            if (error.response && error.response.status === 400) {
                toast.error(`Error: ${error.response.data.message}`, {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (error.response && error.response.status === 429) {
                toast.error(`Too many request`, {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("An unexpected error occurred.", {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }, [callApi, fetchUserData, poolData.id])

    const handleStartAuto = useCallback(async () => {
        try {
            await callApi('post', '/pvp/turn-on-auto', {
                poolId: poolData.id,
            })
            await fetchUserData()
        } catch (error) {
            console.log("error", error)
            if (error.response && error.response.status === 400) {
                toast.error(`Error: ${error.response.data.message}`, {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (error.response && error.response.status === 429) {
                toast.error(`Too many request`, {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("An unexpected error occurred.", {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }, [callApi, fetchUserData, poolData.id])


    const handleStartOrCancel = useCallback(async () => {
        if (matchWaiting) {
            await handleCancleMatching()
        } else {
            await handleMatching()
        }
    }, [handleCancleMatching, handleMatching, matchWaiting])

    const handleFindCompetitor = useCallback((matchDataString) => {
        const matchData = JSON.parse(matchDataString)
        setIsStart(true)
        if (onFoundMatch)
            onFoundMatch(
                {
                    myTeam: matchData.myTeam,
                    opponents: matchData.opponents,
                    round1: matchData.round1,
                    round2: matchData.round2,
                    round3: matchData.round3,
                    isWinner: matchData.isWinner,
                    rewards: matchData.rewards,
                }
            )
    }, [onFoundMatch])

    const handleNotFound = useCallback(() => {
        setMatchWaiting(false)
        toast.error("No opponents available right now. Please try again later.", {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }, [])


    useEffect(() => {
        on("noOpponentFound", handleNotFound);
        return () => {
            off("noOpponentFound", handleNotFound);
        };
    }, [handleNotFound, off, on]);


    useEffect(() => {
        on("matchFound", handleFindCompetitor);
        return () => {
            off("matchFound", handleFindCompetitor);
        };
    }, [handleFindCompetitor, off, on]);



    useEffect(() => {
        let timer = 0
        if (matchWaiting === true) {
            interval = setInterval(() => {
                timer = timer + 1
                setWaitingSeconds(timer)
            }, 1000);
        }
        else {
            setWaitingSeconds(0)
            clearInterval(interval)
        }
    }, [matchWaiting])

    console.log(page, hasMore, isLoadingMore)


    return <Stack spacing={2} justifyContent={'center'} px={2} pb={2} height={'100%'} alignItems={'center'} sx={{ background: theme.palette.background.default, zIndex: matchWaiting ? 1000000 : 0, transition: 'all 0.2s ease-in-out', transform: `scale(${isStart ? 15 : 1})`, filter: `blur(${isStart ? 50 : 0}px)` }}>
        <Stack height={85} justifyContent={'flex-end'} alignItems={'center'}>
            <Collapse in={!matchWaiting}>
                <Typography variant="h2" color={'text.secondary'}>BATTLE</Typography>
            </Collapse>
        </Stack>
        <FlexRowAlign alignItems="flex-end" gap={2}>
            <Stack sx={{ width: matchWaiting ? 50 : 70, aspectRatio: 1, position: 'relative', transition: 'all 0.2s ease-in-out' }}>
                <img src="/images/box-bg-3.png" />
                <Typography sx={{ position: 'absolute', top: 'calc(50% - 15px)', opacity: 0.2, width: '100%' }} textAlign={'center'} variant="h2">2</Typography>
                <Stack position={'absolute'} p={'7%'} width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'} top={0}>
                    {
                        heroSelected[1] && <img src={`images/gifs/${heroSelected[1].image}/stand.gif`} style={{ width: '100%', height: '100%', objectFit: 'contain', border: `3px solid ${RarityColors[heroSelected[1]?.rarity || 'C']}`, padding: '10%' }} />
                    }
                </Stack>
            </Stack>
            <Stack sx={{ width: matchWaiting ? 50 : 100, aspectRatio: 1, position: 'relative', transition: 'all 0.2s ease-in-out' }}>
                <img src="/images/box-bg-3.png" />
                <Typography sx={{ position: 'absolute', top: 'calc(50% - 25px)', opacity: 0.3, width: '100%' }} textAlign={'center'} variant="h1">1</Typography>
                <Stack position={'absolute'} p={'7%'} width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'} top={0}>
                    {
                        heroSelected[0] && <img src={`images/gifs/${heroSelected[0].image}/stand.gif`} style={{ width: '100%', height: '100%', objectFit: 'contain', border: `3px solid ${RarityColors[heroSelected[0]?.rarity || 'C']}`, padding: '10%' }} />
                    }
                </Stack>
            </Stack>
            <Stack sx={{ width: matchWaiting ? 50 : 70, aspectRatio: 1, position: 'relative', transition: 'all 0.2s ease-in-out' }}>
                <img src="/images/box-bg-3.png" />
                <Typography sx={{ position: 'absolute', top: 'calc(50% - 15px)', opacity: 0.2, width: '100%' }} textAlign={'center'} variant="h2">3</Typography>
                <Stack position={'absolute'} p={'7%'} width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'} top={0}>
                    {
                        heroSelected[2] && <img src={`images/gifs/${heroSelected[2].image}/stand.gif`} style={{ width: '100%', height: '100%', objectFit: 'contain', border: `3px solid ${RarityColors[heroSelected[2]?.rarity || 'C']}`, padding: '10%' }} />
                    }
                </Stack>
            </Stack>
        </FlexRowAlign>
        <Collapse in={matchWaiting} >
            <Stack alignItems={'center'} py={2}>
                <Iconify icon={'svg-spinners:blocks-shuffle-3'} size={20} sx={{ color: theme.palette.text.secondary, opacity: 0.5 }} />
                <Typography textAlign={'center'} variant="h2" sx={{ opacity: 0.5 }} color={'text.secondary'}>{`0${parseInt(waitingSeconds / 60)}`.slice(-2)}:{`0${waitingSeconds % 60}`.slice(-2)}</Typography>
            </Stack>
        </Collapse>
        {userData?.user?.isAutoPVP === true ? <>
            <PrimaryButton onClick={() => handleCancelAuto()} sx={{ minHeight: 40, padding: '0px 2rem', fontSize: '1.6rem', background: matchWaiting ? '#FF5638 !important' : '' }}>
                {
                    'Stop auto PVP!'
                }
            </PrimaryButton>
        </> : <>
            <PrimaryButton onClick={() => handleStartOrCancel()} sx={{ minHeight: 40, padding: '0px 2rem', fontSize: '1.6rem', background: matchWaiting ? '#FF5638 !important' : '' }}>
                {
                    matchWaiting ? 'Stop' : 'Fight and earn!!!!!'
                }
            </PrimaryButton>
            {poolData.id === 1 ?
                <PrimaryButton onClick={() => handleStartAuto()} sx={{ minHeight: 40, padding: '0px 2rem', fontSize: '1.6rem', background: matchWaiting ? '#FF5638 !important' : '' }}>
                    {
                        'Auto PVP!!!!!'
                    }
                </PrimaryButton> : <></>
            }
        </>
        }
        <Stack px={1} maxHeight={'calc(100vh - 100px)'} spacing={0.5} py={1} sx={{ border: '3px solid #53536C', width: '100%', minHeight: 130, background: theme.palette.background.default, overflowY: 'scroll' }}>
            <InfiniteScroll
                pageStart={page}
                loadMore={(page) => handleGetData(page)}
                hasMore={hasMore === true && isLoadingMore === false}
                initialLoad={false}
                loader={<Typography textAlign={'center'}>Loading...</Typography>}
                useWindow={false}
            >
                <Grid container >
                    {
                        data?.data?.map((char, index) => {
                            const foundIndex = heroSelected.findIndex(item => item.id === char.id)
                            return <Grid onClick={() => handleSelect(char)} key={`nft-${index}`} p={0.5} item xs={4} >
                                <HeroItem char={char} foundIndex={foundIndex} />
                            </Grid>
                        })
                    }
                </Grid>
            </InfiniteScroll>
        </Stack>
        <Box flexGrow={1} />
    </Stack>
}

export const Match = ({ data, onComplete }) => {
    const theme = useTheme()
    const [isStart, setIsStart] = useState(false)
    const { map, myTeam, opponents } = data
    const styles = useSpring({
        from: {
            opacity: 0,
            transform: 'scale(5)'
        },
        to: {
            opacity: 1,
            transform: 'scale(1)'
        },
    })
    return <animated.div style={{ ...styles, height: 'fit-content', zIndex: 1000000, borderBottom: '3px solid #53536C', boxShadow: '0px 0px 20px #000' }}>
        <Stack spacing={2} justifyContent={'center'} height={'100%'} alignItems={'center'} sx={{ background: "#000" }}>
            <Stack flexGrow={1} >
                <BattleProgress data={data} onComplete={onComplete} />
                <Box flexGrow={1} />
            </Stack>
        </Stack>
    </animated.div>
}

const HeroItem = ({ char, foundIndex }) => {
    return <Stack alignItems={'center'} sx={{
        position: 'relative',
        height: '100%', width: '100%',
        border: `3px solid ${alpha(RarityColors[char?.rarity || 'C'], foundIndex >= 0 ? 0.5 : 1)}`,
        padding: '3rem 0px', aspectRatio: 1
    }}>
        <Typography variant="h6" sx={{ color: "#fff", position: 'absolute', top: '5px', left: '10px' }}>{`Lv.${char.level}`}</Typography>
        <Typography color={RarityColors[char?.rarity || 'C']} variant="h5" sx={{ position: 'absolute', top: '25px', left: '10px' }}>{char.rarity}</Typography>
        <FlexRowAlign gap={0.2} sx={{ color: "#fff", position: 'absolute', top: '5px', right: '10px' }}>
            <img src='images/sword.png' style={{ height: 10 }} />
            <Typography variant="h6" >{char.stats}</Typography>
        </FlexRowAlign>
        <img src={`images/gifs/${char.image}/stand.gif`} style={{ height: 70, width: 'auto' }} />
        <Typography variant="h6" sx={{ color: "#fff", opacity: 0.6, position: 'absolute', bottom: '5px', left: '10px' }}>ID: {char.id}</Typography>
        <Backdrop in={Boolean(foundIndex >= 0)} open={Boolean(foundIndex >= 0)} sx={{ position: 'absolute' }}>
            <Typography variant="h2">{foundIndex + 1}</Typography>
        </Backdrop>
    </Stack>
}


const EnemyHeroItem = ({ char, foundIndex }) => {
    return <Stack alignItems={'center'} sx={{
        position: 'relative',
        height: '100%', width: '100%',
        border: `3px solid ${alpha(RarityColors[char?.rarity || 'C'], foundIndex >= 0 ? 0.5 : 1)}`,
        padding: '3rem 0px', aspectRatio: 1
    }}>
        <Typography variant="h6" sx={{ color: "#fff", position: 'absolute', top: '5px', left: '10px' }}>{`Lv.${char.level}`}</Typography>
        <Typography color={RarityColors[char?.rarity || 'C']} variant="h5" sx={{ position: 'absolute', top: '25px', left: '10px' }}>{char.rarity}</Typography>
        <FlexRowAlign gap={0.2} sx={{ color: "#fff", position: 'absolute', top: '5px', right: '10px' }}>
            <img src='images/sword.png' style={{ height: 10 }} />
            <Typography variant="h6" >{char.stats}</Typography>
        </FlexRowAlign>
        <img src={`images/gifs/${char.image}/left-stand.gif`} style={{ height: 70, width: 'auto' }} />
        <Typography variant="h6" sx={{ color: "#fff", opacity: 0.6, position: 'absolute', bottom: '5px', left: '10px' }}>ID: {char.id}</Typography>
        <Backdrop in={Boolean(foundIndex >= 0)} sx={{ position: 'absolute' }}>
            <Typography variant="h2">{foundIndex + 1}</Typography>
        </Backdrop>
    </Stack>
}

const Log = ({ data, disableIcon }) => {
    return <FlexRowAlign justifyContent="flex-start" gap={1} alignItems='flex-start'>
        {
            !disableIcon && <img src='images/sword.png' style={{ height: 15 }} />
        }
        <Typography>
            <span style={{ color: `${RarityColors[data?.sourceRarity]}` }}>{data?.winerUserName ?? 'hidden'}</span>
            <span> defeat </span>
            <span style={{ color: `${RarityColors[data?.targetRarity || 'C']}` }}> {data?.loserUserName ?? 'hidden'}</span>
            <span> earned </span>
            <span style={data?.token === 'TON' ? { color: '#1E90FF' } : { color: '#d35400' }}> ${data?.amount} {data?.token}</span>
            <span style={{ color: '#FFC809' }}> Winner/loser received {data.winnerGold}/{data.loserGold} GOLD</span>
        </Typography>
    </FlexRowAlign>
}
