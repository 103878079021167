import TelegramProvider, { useTelegram } from "contexts/TelegramContext";
import {
  TonConnectButton,
  useTonWallet,
  useTonConnectUI,
  SendTransactionRequest,
  useTonConnectModal,
} from "@tonconnect/ui-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  ButtonBase,
  Grid,
  LinearProgress,
  OutlinedInput,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { FlexBetween, FlexRowAlign } from "components/flexbox";
import Decimal from "decimal.js";
import { t } from "i18next";
import ProfitIcon from "icons/ProfitIcon";
import TNXIcon from "icons/TNXIcon";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import useSettings from "../../hooks/useSettings";
import useAxios from "../../hooks/useAxios";
import useUserData from "../../hooks/useUserData";
import useAvailableChest from "../../hooks/useAvailableChest";
import Modal from "components/modal/Modal";
import useItems from "../../hooks/useItems";

import { BoxContainer, BoxFooter, BrownBox, PrimaryButton, TitleBox, TypographyOutline } from "./styled";
import { ArrowDropDownRounded, ArrowDropUpRounded } from "@mui/icons-material";
import { Buffer } from 'buffer'
import { beginCell } from "@ton/ton";
import { BoxTitle } from "../../components/BoxTitle";
import { useNavigate } from "react-router-dom";
import { UpgradeForm } from "../../page-sections/homepage/UpgradeForm";
import Iconify from "../../components/Iconify";
import { ConnectWalletPopup } from "../../components/ConnectWallet";
import { fCurrency } from "../../utils/format";
import { ClaimButton } from "../../page-sections/homepage/styled";
import ChainSelector, { SupportedChain } from "../../page-sections/ChainSelector";
import { useAccount, useDisconnect, useSendTransaction, useSwitchChain, useWriteContract } from "wagmi";
import { ConnectButton, useConnectModal } from "@rainbow-me/rainbowkit";
import { erc20Abi, parseEther } from "viem";
// global.Buffer = Buffer;
import '@rainbow-me/rainbowkit/styles.css';


export const BuyMap = ({ data, children }) => {
  const theme = useTheme();
  const wallet = useTonWallet();
  const { open } = useTonConnectModal()
  const { openConnectModal : openEVM } = useConnectModal()

  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false)
  const [tonConnectUI] = useTonConnectUI();
  const { user } = useTelegram();
  const [chainSelected, setChainSelected] = useState(SupportedChain[0])
  const { userData, fetchUserData } = useUserData();
  const { callApi } = useAxios();
  console.log("data",data?.evmPrice)
  const {address , isConnected} = useAccount()
  const { disconnect } = useDisconnect()
  const { switchChainAsync  } = useSwitchChain()

  const [isOpenConnectPopUp, setIsOpenConnectPopUp] = useState(false);
  const handleDisconnect = async () => {
    await tonConnectUI.disconnect()
  }

  const updateEVMAddress = useCallback(async()=>{
    if(!address) return;
    if(userData?.user?.wallet!=address) {
      await callApi('post','/user/link-wallet',{
        wallet: address
      })
    }
  },[address, callApi, userData?.user?.wallet])

  const { writeContractAsync, isSuccess, isError } = useWriteContract();

  const changeChainId = useCallback(async()=>{
    await switchChainAsync({chainId:chainSelected.chainId})
  },[chainSelected.chainId, switchChainAsync])

  useEffect(()=>{
    if(chainSelected.chainId===0) return
    changeChainId()
  },[chainSelected, changeChainId])

  const { 
    data: hash,
    error,
    isPending, 
    sendTransactionAsync
  } = useSendTransaction() 

  useEffect(()=>{
    updateEVMAddress()
  },[address,updateEVMAddress])


  const handleSendETH = useCallback(async () => {
    if (!address) return;
    setIsLoading(true);
    try {
      if(chainSelected.chainId!==5000){
        console.log("data?.evmPrice",data?.evmPrice)
        await sendTransactionAsync({to: '0x41Cd92D9dC8bDd036888cc2bA0a9d635c8b31075',value:parseEther(new Decimal(data?.evmPrice ?? 0).toString())})
      } else {
        console.log("???")
        await writeContractAsync({
          address: '0xcDA86A272531e8640cD7F1a92c01839911B90bb0',
          abi: erc20Abi,
          functionName: 'transfer',
          args: ['0x41Cd92D9dC8bDd036888cc2bA0a9d635c8b31075', parseEther(new Decimal(data?.evmPrice ?? 0).toString())],
        })
      }
      toast.success(
        "🎉 You have bought chests!",
        {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );

      toast.success(
        "🎉 You have bought chests!",
        {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(`Error: ${error.response.data.message}`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.log(error)
        toast.error("An unexpected error occurred.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [address, chainSelected.chainId, data?.evmPrice, sendTransactionAsync, writeContractAsync]);



  const handleSendTon = useCallback(async () => {
    if (!wallet) return;
    setIsLoading(true);
    try {

      const body = beginCell()
        .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
        .storeStringTail(user.id.toString()) // write our text comment
        .endCell();
      const defaultTx = {
        // The transaction is valid for 10 minutes from now, in unix epoch seconds.
        validUntil: Math.floor(Date.now() / 1000) + 600,
        messages: [
          {
            // The receiver's address.
            address: "UQBpC23Ez-0E98MQTg1ZDznGHINNAaCH5R7FwF5RPdHpRG-B",
            // Amount to send in nanoTON. For example, 0.005 TON is 5000000 nanoTON.
            amount: new Decimal(data?.price).mul(Decimal.pow(10, 9)).toString(),
            payload: body.toBoc().toString("base64"),
          },
        ],
      };
      await tonConnectUI.sendTransaction(defaultTx, {
        notifications: "success",
      });

      // await callApi("post", "/user/check-payment", {
      //   amount: new Decimal(amount).mul(Decimal.pow(10, 9)).toFixed(),
      // });
      // await fetchUserData();

      toast.success(
        "🎉 Your transaction submit successful please wait for transaction confirm!",
        {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(`Error: ${error.response.data.message}`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("An unexpected error occurred.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [wallet, user?.id, data?.price, tonConnectUI]);


  const handleEVMDisconnect = async () => {
    await disconnect()
  }


  return (
    <>
      {
        children ? <ButtonBase onClick={() => setOpenModal(true)}>
          {children}
        </ButtonBase> : <ClaimButton sx={{ width: 200 }} onClick={() => setOpenModal(true)} variant='contained'>
          {t("Buy")}
        </ClaimButton>
      }
      <Modal open={openModal}
        handleClose={() => setOpenModal(false)}
        sx={{
          background: theme.palette.background.default,
        }}>
        <ConnectWalletPopup
          openConnectQuest={isOpenConnectPopUp}
          closeConnectQuest={() => setIsOpenConnectPopUp(false)}
        />
        <ButtonBase onClick={() => setOpenModal(false)} sx={{ padding: 0, position: 'absolute', zIndex: 1000, top: '1rem', left: '1rem', }}>
          <img src="images/button-back.png" style={{ width: 40, height: 40 }} />
        </ButtonBase>
        <img src={`/images/maps/${data?.thumbnail}`} style={{ position: 'absolute', top: 0, width: '100%', height: 'auto', left: 0, zIndex: 0, filter: 'brightness(0.5)' }} />
        <Typography variant="h2" mt={-0.5} textAlign={'center'} width={'100%'} sx={{ zIndex: 100, position: 'relative', textShadow: '1px 1px 0px #000, -1px -1px 0px #000' }}>{data?.name}</Typography>
        <Stack sx={{ width: '100%', pt: 3, position: 'relative', zIndex: 100 }} spacing={3} alignItems={'center'}>
          <Stack sx={{ width: '100%', height: 100 }} alignItems={'center'} justifyContent={'center'}>
            <Typography variant="h4" textAlign={'center'}>Daily reward: <span style={{ color: 'gold' }}>{fCurrency(data?.minReward ?? 0)}-{fCurrency(data?.maxReward ?? 0)} $GOLD</span></Typography>
          </Stack>
          <Stack p={2} spacing={2} sx={{ background: '#121625', border: '3px solid #064A75', width: '100%' }}>
            <FlexBetween>
              <Typography variant="h5">Chain</Typography>
              <ChainSelector onChange={chain => setChainSelected(chain)} />
            </FlexBetween>
            <FlexBetween>
              <Typography variant="h5">Amount</Typography>
              <Box flexGrow={1} />
              <img src={chainSelected.chainId === 0 ? "images/TON.png" : chainSelected.iconToken} style={{ width: 'auto', height: 25 }} />
              <Typography variant="h4" color={theme.palette.primary.main} ml={1}>{chainSelected.chainId ===0 ? data?.price : data?.evmPrice} {chainSelected.token}</Typography>
            </FlexBetween>

            {chainSelected.chainId === 0 ? <>
            {wallet?.account ? (
            <>
              <LoadingButton
                sx={{ background: 'url("images/button.png") 50% 50% no-repeat', backgroundSize: 'contain', height: 40, width: '100%', fontSize: '2rem' }}
                onClick={handleSendTon}
                loading={isLoading}
              >
                {t("Buy Chest")}
              </LoadingButton>
              <ButtonBase
                sx={{ backgroundSize: 'contain', height: 40, width: '100%', fontSize: '2rem' }}
                onClick={handleDisconnect}
                loading={isLoading}
              >
                {t("Disconnect Wallet")}
              </ButtonBase>
            </>
          ) : (
            <ButtonBase onClick={open} sx={{ background: 'url("images/button.png") 50% 50% no-repeat', backgroundSize: 'contain', height: 40, width: '100%', fontSize: '2rem' }}>
              Connect wallet
            </ButtonBase>
          )}
          </> : <> 
          {isConnected === false ? <>
            <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
            }}
          >
            <ButtonBase onClick={openEVM} sx={{ background: 'url("images/button.png") 50% 50% no-repeat', backgroundSize: 'contain', height: 40, width: '100%', fontSize: '2rem' }}>
              Connect wallet
            </ButtonBase>
          </Box>
          </> :<>
          <LoadingButton
              sx={{ background: 'url("images/button.png") 50% 50% no-repeat', backgroundSize: 'contain', height: 40, width: '100%', fontSize: '2rem' }}
              onClick={handleSendETH}
              loading={isLoading}
            >
              {t("Buy Cave")}
            </LoadingButton>

            <ButtonBase
              sx={{ backgroundSize: 'contain', height: 40, width: '100%', fontSize: '2rem' }}
              onClick={handleEVMDisconnect}
            >
              {t("Disconnect Wallet")}
            </ButtonBase>
          </>
          }
          
          </>}
          </Stack>
        </Stack>
      </Modal>

    </>
  );
};

export const StyledButton = styled(Button)(({ theme }) => ({
  border: `1px solid #B7B7B7`,
  background: theme.palette.background.default,
  borderRadius: "0.8rem",
  position: "relative",
  flexDirection: "column",
  padding: 0,
  img: {
    filter: "saturate(0)",
  },
  "&.active": {
    borderColor: "#FAFF00",
    boxShadow:
      "inset 0px 4px 0px rgba(255,255,255,0.25), 2px 4px 0px rgba(0,0,0)",
    img: {
      filter: "saturate(1)",
    },
  },
}));

export const StyledInput = styled(OutlinedInput)(({ theme }) => ({
  margin: "1.5rem 0px",
  background: "#000",
  borderRadius: "0.5rem",
  input: {
    textAlign: "center",
    color: "white",
    fontWeight: 600,
  },
  "& fieldset": {
    border: `1px solid #000!important`,
  },
}));

const PriceBox = styled(Stack)(({ theme }) => ({
  background: "#FFF4CE",
  borderRadius: "0.5rem",
  padding: "0.5rem",
  justifyContent: "center",
  alignItems: "center",
  minHeight: 64,
}));

const DailyBox = styled(Stack)(({ theme }) => ({
  background: "#FFF06C",
  borderRadius: "0.5rem",
  padding: "0.5rem 1rem",
  minHeight: 40,
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));

const PayBox = styled(Stack)(({ theme }) => ({
  background: "#FFFFFF",
  borderRadius: "0.5rem",
  padding: "0.5rem 1rem",
  minHeight: 40,
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow:
    "inset 0px 4px 0px rgba(255,255,255,0.25), 2px 4px 0px rgba(0,0,0)",
}));

const UpgradeBox = styled(Stack)(({ theme }) => ({
  maxHeight: "calc(100vh - 290px)",
  overflow: 'scroll',
  background: '#FFF3E8',
  borderTop: '5px solid #DDC5BC',
  padding: '1.5rem',
}));

const ShopBox = styled(Stack)(({ theme }) => ({
  maxHeight: "calc(100vh - 290px)",
  overflow: 'scroll',
  background: '#FFF3E8',
  borderTop: '5px solid #DDC5BC',
  padding: '1.5rem',
}));

const ShopItem = styled(FlexBetween)(({ theme }) => ({
  border: '2px solid #000',
  boxShadow: '3px 4px #000',
  borderRadius: '0.7rem',
  minHeight: 80,
  background: '#FFA863',
  padding: '0px',
  paddingLeft: '10px',
  overflow: 'hidden',
  '& .image': {
    background: '#D0775B',
    width: 70,
    height: 60,
    borderRadius: '0.6rem',
    boxShadow: 'inset 0px 7px 0px rgba(0,0,0,0.1)'
  },
  '& .name-card': {
    height: 60,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    border: '1px solid #AA7042',
    borderRadius: '0.6rem',
    '& .name': {
      fontFamily: '"Rubik", sans-serif !important',
      fontWeight: 900,
      fontSize: '1.2rem',
      textTransform: 'uppercase',
      WebkitTextFillColor: "#fff",
      WebkitTextStroke: "1px #77483F",
      paintOrder: "stroke fill",
      textShadow: '0px 2px rgba(0,0,0,0.4) !important',

      '&:before,&:after': {
        content: '""',
        position: 'absolute',
        width: "6px",
        height: '6px',
        borderRadius: '6px',
        background: "rgba(0,0,0,0.4)",
        top: '6px',
        left: '6px'
      },
      '&:after': {
        right: '6px',
        left: 'unset'
      }
    },
    '& .description': {
      fontWeight: 500,
      '&:before,&:after': {
        content: '""',
        position: 'absolute',
        width: "6px",
        height: '6px',
        borderRadius: '6px',
        background: "rgba(0,0,0,0.4)",
        bottom: '6px',
        left: '6px'
      },
      '&:after': {
        right: '6px',
        left: 'unset'
      }
    }
  },
  '& .price-card': {
    width: '100px',
    height: 80,
    borderLeft: '1px solid #000',
    background: '#FFFFFF'
  }
}));