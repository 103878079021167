
import '@rainbow-me/rainbowkit/styles.css';

import {
  connectorsForWallets,
  getDefaultConfig,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';

import { createConfig, http, WagmiProvider } from 'wagmi';
import {
  mainnet,
  arbitrum,
  base,
  manta,
  mantle,
} from 'wagmi/chains';
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";
import { walletConnect } from 'wagmi/connectors';
import { walletConnectWallet } from '@rainbow-me/rainbowkit/wallets';

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [walletConnectWallet],
    },
  ],
  {
    appName: 'Ton Realm',
    projectId: '9501f334c45bcaec0d156632461011b4',
  }
);

export const wagmiConfig = createConfig({
  chains: [mainnet, arbitrum, base, manta],
  connectors,
  transports: {
    [mainnet.id]: http(),
    [arbitrum.id]: http(),
    [base.id]: http(),
    [manta.id]: http(),
  },
})

