import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Link,
  OutlinedInput,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FlexBetween } from "components/flexbox";
import Modal from "components/modal/Modal";
import { t } from "i18next";
import { useState, useEffect } from "react";
import useAxios from "../../hooks/useAxios";
import useEventConvert from "../../hooks/useEventConvert";


import * as Yup from "yup";
import { Form, FormikProvider, useFormik, useFormikContext } from "formik";
import { fCurrency, shortenNumber } from "../../utils/format";
import toast from "react-hot-toast";
import TonWeb from "tonweb";
import { Decimal } from "decimal.js";
import Countdown from 'react-countdown';
import {
  ButtonAction,
  ButtonWithdraw,
  ConvertButton,
  ModalContentBox,
  TitleModalBox,
} from "./styled";
import { NumberInput } from "../../components/NumberInput";
import moment from "moment";
import useConvertEvent from "../../hooks/useEventConvert";
import FlexRowAlign from "../../components/flexbox/FlexRowAlign";

const PointForm = ({ catBalance, userData, tokenInfo, tonInfo, onChange }) => {
  const { callApi } = useAxios();
  const { symbol, min } = tokenInfo;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const { convertEvent, fetchConvertEvent } = useEventConvert()
  const [balance, setBalance] = useState(0)
  const [enableConvert, setEnableConvert] = useState(false)
  const [completedCountDown, setCompletedCountDown] = useState(false)
  const [nextConvertTime, setNextConvertTime] = useState(0)

  const Schema = Yup.object().shape({
    amount: Yup.number().required(),
    tonAmount: Yup.string().required(),
  });

  const price = convertEvent?.[0].price ?? 0
  const convertRatioMax = convertEvent?.[0].maxRatio ?? 0
  const startTime = parseInt(convertEvent?.[0]?.startTime ?? 0)
  const endTime = parseInt(convertEvent?.[0]?.endTime ?? 0)
  const formik = useFormik({
    initialValues: {
      amount: "",
      tonAmount: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {

      toast.error("Event ended.", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;

      setIsSubmitting(true);
      try {

        await callApi("post", "/user/convert-request", {
          token1: '2',
          token2: '1',
          amount: new Decimal(values.amount).toFixed(),
        });
        await fetchConvertEvent()
        if (onChange)
          onChange()
        toast.success(
          "🎉 Swap successful.",
          {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(`Error: ${error.response.data.message}`, {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("An unexpected error occurred.", {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const { handleSubmit, getFieldProps, errors, touched, handleReset, values } = formik;

  const tokenSwap = useEffect(() => {
    const amount = parseFloat(values.amount) || 0
    formik.setFieldValue('tonAmount', new Decimal(price ?? 0).mul(amount).toFixed(4, Decimal.ROUND_FLOOR));
  }, [price, values.amount])

  const handleOpen = () => {
    // setOpen(true);
  };
  const handleFillMax = (value) => {
    formik.setFieldValue("amount", value);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const temp = userData?.userBalances.find((balance) => balance.token === symbol)?.balance || 0;
    setBalance(temp)
  }, [userData])

  useEffect(() => {
    setBalance(catBalance)
  }, [catBalance])

  useEffect(() => {
    setEnableConvert((moment(startTime) < moment() && moment() < moment(endTime)))
  }, [open, startTime, endTime])

  useEffect(() => {
    const time = moment(convertEvent?.lastSwap).add('24', 'hours')
    setNextConvertTime(time)
  }, [convertEvent])

  return (
    tokenInfo && (
      <>
        <ButtonWithdraw sx={{ height: 40, minWidth: 150 }} variant='contained' onClick={handleOpen}>
          <FlexRowAlign sx={{ width: '100%' }} gap={1} justifyContent='flex-start'>
            <img src="images/POINT.png" style={{ width: 20, height: 20 }} />
            <Typography variant="h4">Total: {shortenNumber(balance, 4)} points</Typography>
          </FlexRowAlign>
        </ButtonWithdraw>
        <Modal open={open} handleClose={handleClose}>
          <TitleModalBox>
            <Typography variant='h5' textAlign={"center"} fontWeight={600}>
              {t("Convert")} {symbol}
            </Typography>
            <Typography variant='body2' textAlign={"center"} px={4}>
              {t(`Minimum conversion: ${min} ${symbol}`)}
            </Typography>
            <Typography variant='body2' textAlign={"center"} px={4}>
              {t(`The maximum conversion amount is ${convertRatioMax * 100}% of your balance.`)}
            </Typography>
          </TitleModalBox>
          <Stack sx={{ position: 'relative', width: 'fit-content', margin: 'auto' }}>
            {
              startTime && <Countdown
                date={moment(startTime)}
                renderer={props => <RendererCountdown {...props} color="#FFC368" sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, }} />}
                onComplete={() => setEnableConvert(false)}
              />
            }
            {
              endTime && <Countdown
                date={moment(endTime)}
                renderer={props => <RendererCountdown {...props} color="#7BBF44" sx={{ opacity: enableConvert ? 1 : 0 }} />}
                onComplete={() => setEnableConvert(false)}
              />
            }
          </Stack>
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
              <ModalContentBox>
                <Stack gap={1.5}>
                  <FlexBetween gap={1}>
                    <img src={`/images/${symbol}.png`} style={{ width: 20, height: 20 }} />
                    <Typography variant="h4">{symbol}</Typography>
                    <Box flexGrow={1} />
                    <Typography variant="h4" sx={{ opacity: 0.7 }}>
                      {balance > 0 ? fCurrency(balance, 4) : 0}
                    </Typography>
                  </FlexBetween>
                  <NumberInput
                    type='number'
                    placeholder={t("Enter amount of {{symbol}} here").replace(
                      "{{symbol}}",
                      symbol,
                    )}
                    size='small'
                    fullWidth
                    sx={{
                      '& fieldset': {
                        border: '2px solid #054b76 !important',
                        borderRadius: 0
                      }
                    }}
                    InputProps={{
                      endAdornment:
                        balance > 0 && (
                          <Link
                            onClick={() =>
                              handleFillMax(
                                new Decimal(balance).mul(convertRatioMax).toFixed(4, Decimal.ROUND_FLOOR),
                              )
                            }>
                            {t("Max")}
                          </Link>
                        )

                    }}
                    {...getFieldProps("amount")}
                    error={Boolean(errors.amount) && Boolean(touched.amount)}
                    helpertext={Boolean(touched.amount) && errors.amount}
                  />
                  <FlexBetween justifyContent={"center"} mb={-1}>
                    <Box
                      component='img'
                      src={`/images/dropdown.png`}
                      alt=''
                      width={25}
                      height={25}
                      sx={{ objectFit: 'contain' }}
                    />
                  </FlexBetween>
                  <FlexBetween gap={1}>
                    <img src={`/images/${tonInfo?.symbol}.png`} style={{ width: 23, height: 23 }} />
                    <Typography variant="h4">
                      {tonInfo?.symbol}
                    </Typography>
                    <Box flexGrow={1} />
                  </FlexBetween>
                  <NumberInput
                    readOnly={true}
                    placeholder={t("Amount of {{symbol}}").replace(
                      "{{symbol}}",
                      tonInfo?.symbol,
                    )}
                    value={tokenSwap}
                    sx={{
                      '& fieldset': {
                        border: '2px solid #054b76 !important',
                        borderRadius: 0
                      }
                    }}
                    size='small'
                    fullWidth
                    {...getFieldProps("tonAmount")}
                    error={Boolean(errors.address) && Boolean(touched.address)}
                    helpertext={Boolean(touched.address) && errors.address}
                  />
                </Stack>
              </ModalContentBox>
              <FlexBetween gap={1}>
                <ButtonAction
                  onClick={handleSubmit}
                  loading={isSubmitting}
                  disabled={!enableConvert || (moment() < moment(nextConvertTime))}
                  sx={{ width: "170px", height: 50 }}>
                  {
                    nextConvertTime && <Countdown
                      date={moment(nextConvertTime)}
                      renderer={props => <ConvertCountdown {...props} color="#7BBF44" sx={{ opacity: enableConvert ? 1 : 0 }} />}
                      onComplete={() => setEnableConvert(true)}
                    />
                  }
                </ButtonAction>
                <Button
                  onClick={handleClose}
                  variant='contained'
                  color='secondary'
                  sx={{
                    borderRadius: "0.5rem",
                    background: "transparent",
                    border: `1px solid #000`,
                    minWidth: "120px",
                    height: 50
                  }}>
                  <Typography fontWeight={500}>{t("Cancel")}</Typography>
                </Button>
              </FlexBetween>
            </Form>
          </FormikProvider>
        </Modal>
      </>
    )
  );
};

export default PointForm;

const RendererCountdown = ({ days, hours, minutes, seconds, completed, color, sx }) => {
  return completed ? '' : <Stack p={1} px={2}
    sx={{ ...sx, background: color, width: 'fit-content', borderRadius: '0.7rem', margin: 'auto', marginTop: '1rem !important' }}
  >
    <Typography sx={{ fontWeight: 700, whiteSpace: 'nowrap' }}>{days < 10 ? '0' : ''}{days} days {hours < 10 ? '0' : ''}{hours} hours {minutes < 10 ? '0' : ''}{minutes} minutes {seconds < 10 ? '0' : ''}{seconds} seconds</Typography>
  </Stack>;
};

const ConvertCountdown = ({ hours, minutes, seconds, completed, color, sx }) => {
  return completed ? <Typography sx={{ fontWeight: 600, whiteSpace: 'nowrap', color: "#000" }}>Coming Soon</Typography> : <Stack >
    <Typography sx={{ fontWeight: 600, whiteSpace: 'nowrap', color: "#000" }}>{hours < 10 ? '0' : ''}{hours}:{minutes < 10 ? '0' : ''}{minutes}:{seconds < 10 ? '0' : ''}{seconds}</Typography>
    <Typography sx={{ fontWeight: 600, whiteSpace: 'nowrap', color: "#000" }}>to the next conversion</Typography>
  </Stack>;
};
