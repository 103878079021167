// CUSTOM ICON COMPONENT
export const navigation = [
  {
    name: 'Mission',
    iconImage: '/images/mission-icon.png',
    path: '/mission'
  },
  {
    name: 'Battle',
    iconImage: '/images/battle-icon.png',
    path: '/battle'
  },
  {
    name: 'Friends',
    iconImage: '/images/friends-icon.png',
    path: '/referral'
  },
  {
    name: 'Inventory',
    iconImage: '/images/inventory-icon.png',
    path: '/upgrade'
  },
];