import { lazy } from "react";
// CUSTOM COMPONENTS
import Loadable from "./Loadable";
import Referral from "pages/mobile/referral";
import History from "pages/mobile/history";
import { Dashboard } from "../pages/mobile/dashboard";
import { Upgrade } from '../pages/mobile/upgrade'
import { Boost } from "../pages/mobile/boost";
import { Mission } from "../pages/mobile/mission";
import { Battle } from "../pages/mobile/battle";
import Warzone from "../pages/mobile/warzone";
import Leaderboard from "../pages/mobile/leaderboard";

// ALL DASHBOARD PAGES
const Homepage = Loadable(lazy(() => import("pages/mobile/homepage")));

export const MobileRoutes = [
  {
    path: "",
    element: <Homepage />,
  },
  {
    path: "mission",
    element: <Mission />,
  },
  {
    path: "referral",
    element: <Referral />,
  },
  {
    path: "history",
    element: <History />,
  },
  {
    path: "warzone",
    element: <Warzone />,
  },
  {
    path: "leaderboard",
    element: <Leaderboard />,
  },
  {
    path: "upgrade",
    element: <Upgrade />,
  },
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "boost",
    element: <Boost />,
  },
  {
    path: "battle",
    element: <Battle />
  }
];
