import { SettingsContext } from "contexts/settingsContext";
import { useCallback, useContext, useEffect, useState } from "react";
import useSettings from './useSettings'

import Decimal from "decimal.js";
import TelegramProvider, { useTelegram } from "contexts/TelegramContext";
import useAxios from "./useAxios";

const useUserEarned = () => {
    const { settings } = useSettings();
    const { callApi } = useAxios()
    const [userEarned, setUserEarned] = useState()
    const { user } = useTelegram()


    const fetchUserEarned = useCallback(async () => {
        if (!settings?.token) return;
        try {
            const userData = await callApi('get', '/user/ref-earned')
            setUserEarned(userData as any)
        } catch (e) {
            console.log('ignoew',e)
        }
    }, [settings.token])

    useEffect(() => {
        fetchUserEarned()
    }, [
        fetchUserEarned, settings.token
    ])
    return { userEarned, fetchUserEarned }
};

export default useUserEarned;
