import MuiModal from '@mui/material/Modal';
// STYLED COMPONENT
import { StyledScrollbar, Wrapper } from './styles';

// ===========================================================================

// ===========================================================================

export default function Modal({
  children,
  open,
  handleClose,
  paperSx,
  ...props
}) {
  return <MuiModal open={open} onClose={handleClose}>
    <Wrapper {...props} sx={{ paperSx }}>
      <StyledScrollbar>{children}</StyledScrollbar>
    </Wrapper>
  </MuiModal>;
}