import { Box, Grid, Stack } from "@mui/material"
import { memo, useCallback, useEffect, useRef, useState } from "react"
import $ from 'jquery'
import { GiftOpened } from "../GiftOpened"
import useAxios from "../../../hooks/useAxios"
import toast from "react-hot-toast"
import { useSpring, animated } from '@react-spring/web'

export const FrozenCave = memo(({ userNfts ,fetchUserData, isClaimedChest, isActive }) => {
    const segment = 0.43
    const [mapInnerWidth, setMapInnerWidth] = useState(0)
    const mapRef = useRef()
    useEffect(() => {
        if (mapRef.current) {
            setMapInnerWidth($(mapRef.current)[0].offsetWidth)
        }
    }, [mapRef.current])
    return <Stack width={'100%'} sx={{ position: 'relative' }}>
        <img ref={mapRef} src="/images/maps/frozen-cave.png" style={{ width: '100%' }} />
        {
            isActive && <Stack sx={{
                width: `${segment * 22 * mapInnerWidth / 10}px`,
                height: `${segment * 9 * mapInnerWidth / 10}px`,
                position: 'absolute',
                bottom: `${segment * mapInnerWidth / 10}px`,
                left: `${segment * mapInnerWidth / 20}px`,
                zIndex: 1
            }}>
                {userNfts?.map((char) =>
                    <>
                        <Character size={segment * mapInnerWidth / 10} info={{
                            name: char.image

                        }} />
                    </>
                )}
            </Stack>
        }
       
    </Stack>
})

const obstacles = [
    "1:2",
    "2:2",
    "3:2",
    "4:2",
    "5:2",
    "6:2",
    "7:2",
    "8:2",
    "9:2",
    "10:2",
    "11:2",
    "12:2",
    "13:2",
    "14:2",
    "15:2",
    "16:2",
    "17:2",
    "18:2",
    "19:2",
    "19:3",
    "4:4",
    "5:4",
    "6:4",
    "7:4",
    "8:4",
    "9:4",
    "10:4",
    "11:4",
    "12:4",
    "13:4",
    "14:4",
    "15:4",
    "16:4",
    "17:4",
    "18:4",
    "19:4",
    "4:5",
    "5:5",
    "6:5",
    "7:5",
    "8:5",
    "9:5",
    "10:5",
    "11:5",
    "12:5",
    "13:5",
    "14:5",
    "15:5",
    "16:5",
    "17:5",
    "18:5",
    "19:5",
    "20:5",
    "21:5",
    "0:7",
    "1:7",
    "2:7",
    "3:7",
    "0:8",
    "1:8",
    "2:8",
    "1:3",
    "1:4",
    "0:4",
]
const directions = ['left', 'up', 'right', 'down']
const handleCheckObstacle = (x, y) => {
    const temp = `${x}:${y}`
    return Boolean(obstacles.includes(temp)) === true ? 0 : 1
}

const randomNumber = (min, max) => {
    return parseInt(Math.random() * (max - min) + min);
}


const handleGetDirection = async ([x, y]) => {
    const available = [
        handleCheckObstacle(Math.max(x - 1, 0), y) && handleCheckObstacle(Math.max(x - 2, 0), y),
        handleCheckObstacle(x, Math.max(y - 1, 0)) && handleCheckObstacle(x, Math.max(y - 2, 0)),
        handleCheckObstacle(Math.min(x + 1, 20), y) && handleCheckObstacle(Math.min(x + 2, 20), y),
        handleCheckObstacle(x, Math.min(y + 1, 8)) && handleCheckObstacle(x, Math.min(y + 2, 8))
    ] //[left,up,right,down]
    let direction = 0
    let directionType = 0
    while (direction === 0) {
        directionType = randomNumber(0, 4)
        direction = available[directionType]
    }
    return directionType
}

const handleGetNewPosition = ([x, y], type) => {
    switch (type) {
        case 0: return [x - 2, y]
        case 1: return [x, y - 2]
        case 2: return [x + 2, y]
        case 3: return [x, y + 2]
    }
    return [x, y]
}


const Character = ({ info, size }) => {
    const [position, setPosition] = useState([11, 7])
    const [moving, setMoving] = useState('')
    const [haveCoin, setHaveCoin] = useState(false)

    const movingCallback = useCallback(async()=>{
        if (moving === '') {
            const direction = await handleGetDirection(position)
            const sleep = randomNumber(1, 5)
            setTimeout(() => {
                const newPosition = handleGetNewPosition(position, direction)
                const positionX = Math.min(Math.max(newPosition[0], 0), 20)
                const positionY = Math.min(Math.max(newPosition[1], 0), 8)
                setHaveCoin(true)
                setPosition([positionX, positionY])
                setMoving(directions[direction])
                setTimeout(() => {
                    setMoving('')
                    setHaveCoin(false)
                }, 1000);
            }, sleep * 1000);
        }
    },[moving, position])

    useEffect( () => {
        movingCallback()
    }, [moving])

    return <>
        <Stack sx={{
            position: 'absolute',
            transition: moving ? 'all 1s linear' : '',
            left: `${position[0] * size + size / 4}px`,
            top: `${position[1] * size}px`,
            transform: `scale(1.5) translateY(-${size / 4}px)`,
            zIndex: position[1] + 10
        }}>
            <img
                src={`images/gifs/${info.name}/${moving ? moving : 'stand'}.gif`}
                width={size}
                height={size}
                style={{ objectFit: 'contain', transform: 'scale(1.2)' }}
            />
        </Stack>
        {
            haveCoin && <Coin size={size} position={position} />
        }
    </>
}

const Coin = ({ size, position }) => {
    const [coinDropStyles, api] = useSpring(
        () => ({
            from: { y: 5 },
            to: { y: 0 },
            config: {
                mass: 8, tension: 550, friction: 10
            },
        }),
        []
    )
    return <animated.img
        style={{
            ...coinDropStyles,
            position: 'absolute',
            left: `${position[0] * size + size / 4}px`,
            top: `${position[1] * size}px`,
            filter: 'drop-shadow(0px 1px 0px #000)'
        }}
        src={`images/TRT.png`}
        width={size}
        height={size}
    />
}

const Chest = memo(({ size = 20, position, fetchUserData }) => {
    const [isOpening, setIsOpening] = useState(false)
    const [gift, setGift] = useState(null)
    const [giftAmount, setGiftAmount] = useState(0)
    const { callApi } = useAxios()
    const handleOpen = async () => {
        setIsOpening(true)
        try {
            const res = await callApi("post", "/user/claim-chest");
            console.log("res", res)
            const token = res.item.tokenId === '5' ? 'Chest' : res.item.tokenId === '4' ? 'POINT' : 'TRT'
            setGift(token)
            const amount = res.item.number
            setGiftAmount(amount)
            await new Promise(resolve => setTimeout(resolve, 3000));
            await fetchUserData();
            // toast.success(`🎉 Got your reward ${amount} ${token}`, {
            //     autoClose: 3000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
        } catch (error) {
            await fetchUserData();
            console.log("erorr", error)
            if (error.response && error.response.status === 400) {
                toast.error(`Error: ${error.response.data.message}`, {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("An unexpected error occurred.", {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } finally {
            setIsOpening(false)
        }
    }

    const handleCalim = () => {
        setGift(null)
        setGiftAmount(0)
    }


    return <>
        <Stack
            onClick={() => handleOpen()}
            sx={{
                position: 'absolute',
                left: `${position[0] * size + size / 4}px`,
                top: `${position[1] * size}px`,
                transform: `scale(1.5) translateY(-${size / 4}px)`,
                zIndex: position[1] + 10
            }}>
            <img
                src={isOpening ? `images/gifs/open.gif` : `images/gifs/chest.gif`}
                width={size * 2}
                height={size * 2}
                style={{
                    objectFit: 'contain',
                    marginTop: isOpening ? '-2px' : 0,
                    marginRight: isOpening ? '-1px' : 0,
                }}
            />
        </Stack>
        <GiftOpened gift={gift} giftAmount={giftAmount} onClaim={() => handleCalim()} />
    </>
})
