import { ButtonBase, Modal, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { BuyMap } from "../../pages/mobile/buy-map";
import { FrozenCave } from "../homepage/maps/FrozenCave";
import useSelectedMap from "../../hooks/useSelectedMap";

export default function MainPromo({ open }) {
    const [openModal, setOpenModal] = useState(false)
    const { mapInfo, fetchMapInfo } = useSelectedMap(5)
    console.log("mapInfo",mapInfo)
    useEffect(() => {
        setOpenModal(open)
    }, [open])
    return <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{
            padding: '1rem',
            '& div': {
                outline: 'none'
            }
        }}
    >
        <Stack sx={{ height: '100%' }} spacing={3} justifyContent={'center'} alignItems={'center'}>
            <Stack>
                <BuyMap data={mapInfo?.[0]}>
                    <img src="/images/promotions/promo-new-cave.png" style={{ maxWidth: 350, width: 'calc(100vw - 40px)' }} />
                </BuyMap>
            </Stack>
            <ButtonBase onClick={() => setOpenModal(false)}>
                <img src="/images/close-icon.png" style={{ width: 15, height: 15 }} />
            </ButtonBase>
        </Stack>
    </Modal>
}