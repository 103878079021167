import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  ButtonBase,
  Link,
  OutlinedInput,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FlexBetween } from "components/flexbox";
import Modal from "components/modal/Modal";
import { t } from "i18next";
import { useState, useEffect } from "react";
import useAxios from "../../hooks/useAxios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { fCurrency, shortenNumber } from "../../utils/format";
import toast from "react-hot-toast";
import TonWeb from "tonweb";
import { Decimal } from "decimal.js";
import Iconify from "../../components/Iconify";
import {
  ButtonAction,
  ButtonWithdraw,
  ModalContentBox,
  TitleModalBox,
} from "./styled";
import { NumberInput } from "../../components/NumberInput";
import { useTonAddress, useTonConnectModal, useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";

const WithdrawForm = ({ tokenInfo, onChange, userData, children, btnSx, disabled }) => {
  const { callApi } = useAxios();
  const { symbol, min } = tokenInfo;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [balance, setBalance] = useState(0);
  const { open: openConnectWallet } = useTonConnectModal()

  useEffect(() => {
    const temp = userData?.userBalances.find((balance) => balance.token === symbol)?.balance || 0;
    setBalance(temp)
  }, [userData])

  const RedeemOptions = [
    {
      title: `${t("With 3 days to redeem, you get 50% of")} ${symbol}`,
      value: 0.5,
      prioriy: "1",
      label: t("3 days"),
    },
    {
      title: `${t("With 7 days to redeem, you get 100% of")} ${symbol}`,
      value: 1,
      prioriy: "2",
      label: t("7 days"),
    },
    {
      title: `${t("With 14 days to redeem, you get 150% of")} ${symbol}`,
      value: 1.5,
      prioriy: "3",
      label: t("14 days"),
    },
  ];

  const [redeemSelected, setRedeemSelected] = useState(RedeemOptions[0]);

  const Schema = Yup.object().shape({
    amount: Yup.number().required(),
    address: Yup.string().required(),
  });

  const wallet = useTonWallet();
  const walletAddress = useTonAddress(true);
  const [tonConnectUi] = useTonConnectUI();

  const formik = useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {

    },
  });

  const handleWithdraw = async () => {
    setIsSubmitting(true);
    try {
      const isValid = isValidTonAddress(walletAddress);
      if (!isValid) {
        toast.error("🎉 Wallet is not valid.", {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      await callApi("post", "/user/withdraw-request", {
        token: symbol === "TON" ? "1" : "3",
        amount: new Decimal(values.amount).toFixed(),
        address: walletAddress,
        priority: redeemSelected.prioriy,
      });
      if (onChange) onChange()
      toast.success(
        "🎉 You have successfully requested a withdrawal. Please wait for the blockchain to process the transaction.",
        {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(`Error: ${error.response.data.message}`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("An unexpected error occurred.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  }
  function isValidTonAddress(address) {
    try {
      const tonweb = new TonWeb();
      const parsedAddress = new tonweb.utils.Address(address);
      return true;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }

  const { handleSubmit, getFieldProps, errors, touched, handleReset, values } =
    formik;

  const handleOpen = () => {
    if (symbol === 'TRT'  || symbol === 'REALM') return;
    setOpen(true);
  };

  const handleDisconnect = async () => {
    await tonConnectUi.disconnect()
  }

  const handleFillMax = (value) => {
    formik.setFieldValue("amount", value);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    tokenInfo && (
      <>
        <ButtonWithdraw disabled={disabled} sx={{ height: 20, px: 0, opacity: disabled ? 0.6 : 1, ...btnSx }} variant='contained' onClick={handleOpen}>
          {
            children ? children : <>
              <Box
                component='img'
                src={`/images/${symbol}.png`}
                alt=''
                sx={{
                  width: 20,
                  height: 20,
                }}
              />
              <Typography pl={1} fontWeight={900} variant="h5" color={"#fff"}>
                {balance ? shortenNumber(balance, 4) : 0} {symbol}
              </Typography>
            </>
          }
        </ButtonWithdraw >
        <Modal open={open} handleClose={handleClose}>
          <TitleModalBox>
            <Typography variant='h5' textAlign={"center"} fontWeight={600}>
              {t("Withdraw")} {symbol}
            </Typography>
            <Typography variant='body2' textAlign={"center"} px={4}>
              {t(`Enter TON wallet address to receive your earning`)}
            </Typography>
            <Typography variant='body2' textAlign={"center"} px={4}>
              {t(`Minimum withdrawal ${tokenInfo.min} ${tokenInfo.symbol}`)}
            </Typography>
          </TitleModalBox>
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
              <ModalContentBox sx={{ alignItems: 'center' }}>
                <Stack gap={1.5}>
                  <FlexBetween>
                    <Box
                      sx={{
                        position: "relative",
                        padding: "0.5rem 1rem 0.5rem 2.5rem",
                        minHeight: "unset",
                        overflow: "visible",
                      }}>
                      <Box
                        component='img'
                        src={`/images/${symbol}.png`}
                        alt=''
                        sx={{
                          position: "absolute",
                          left: "4px",
                          top: "3px",
                          borderRadius: "50%",
                          width: 23,
                          height: 23
                        }}
                      />
                      <Typography fontWeight={600}>{symbol}</Typography>
                    </Box>
                    <Box
                      sx={{
                        position: "relative",
                        padding: "0.5rem 1rem",
                        minWidth: "100px",
                      }}>
                      <Typography
                        color={"text.primary"}
                        fontWeight={600}
                        sx={{ textAlign: "right" }}>
                        {balance > 0 ? fCurrency(balance, 4) : 0}
                      </Typography>
                    </Box>
                  </FlexBetween>
                  <Stack gap={1}>
                    <NumberInput
                      type='number'
                      placeholder={t("Enter amount of {{symbol}} here").replace(
                        "{{symbol}}",
                        symbol,
                      )}
                      sx={{
                        '& fieldset': {
                          borderRadius: 0,
                          border: '2px solid #064a74 !important'
                        }
                      }}
                      size='small'
                      fullWidth
                      InputProps={{
                        endAdornment:
                          balance > 0 && (
                            <Link
                              onClick={() =>
                                handleFillMax(
                                  fCurrency(balance, 4).replace(/,/g, ""),
                                )
                              }>
                              {t("Max")}
                            </Link>
                          )

                      }}
                      {...getFieldProps("amount")}
                      error={Boolean(errors.amount) && Boolean(touched.amount)}
                      helpertext={Boolean(touched.amount) && errors.amount}
                    />
                  </Stack>


                  <FlexBetween justifyContent={"center"} alignItems={"center"}>
                    {wallet?.account ? (
                      <Stack width={'100%'} spacing={2} alignItems="center">
                        <ButtonAction
                          disabled={symbol === 'TRT'}
                          onClick={handleWithdraw}
                          loading={isSubmitting}
                          sx={{ width: "170px" }}>
                          <Typography fontWeight={500} variant="h5" sx={{ color: "#000" }}>
                            {t("Withdraw")}
                          </Typography>
                        </ButtonAction>
                        <ButtonBase
                          sx={{ backgroundSize: 'contain', height: 40, width: '100%', fontSize: '1.3rem' }}
                          onClick={handleDisconnect}
                          loading={isSubmitting}
                        >
                          {t("Disconnect Wallet")}
                        </ButtonBase>
                      </Stack>
                    ) : (
                      <ButtonBase onClick={openConnectWallet} sx={{ background: 'url("images/button.png") 50% 50% no-repeat', backgroundSize: 'contain', height: 40, width: '100%', fontSize: '2rem' }}>
                        Connect wallet
                      </ButtonBase>
                    )}
                  </FlexBetween>

                </Stack>
              </ModalContentBox>

            </Form>
          </FormikProvider>
        </Modal>
      </>
    )
  );
};

export default WithdrawForm;
