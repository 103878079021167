import { useCallback, useEffect, useState } from "react";
import useSettings from './useSettings';

import { useTelegram } from "contexts/TelegramContext";
import useAxios from "./useAxios";

const useMapInfo = (mapId: number) => {
    const { settings } = useSettings();
    const { callApi } = useAxios()
    const [ mapInfo, setMapInfo] = useState()
    const { user } = useTelegram()
    const fetchMapInfo = useCallback(async () => {
        if (!settings.token || !mapId) return;
        try {
            const nfts = await callApi('get', `/map/map-info?mapId=${mapId}`)
            setMapInfo(nfts as any)
        } catch (e) {
            console.log('ignoew')
        }
    }, [callApi, mapId, settings.token])
    useEffect(() => {
        fetchMapInfo()
    }, [
        fetchMapInfo, settings.token, mapId
    ])
    return { mapInfo, fetchMapInfo }
};

export default useMapInfo;
