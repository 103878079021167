import {
  Button,
  Stack,
  Typography,
  styled,
  useTheme,
  OutlinedInput,
  ButtonBase,
  Divider,
  Modal
} from "@mui/material";
import { FlexBetween } from "components/flexbox";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import useAxios from "../../hooks/useAxios";
import { PrimaryButton } from "../../pages/mobile/styled";
import { RarityColors } from "../../utils/constants";
import FlexRowAlign from "../../components/flexbox/FlexRowAlign";
import CountUp from 'react-countup';


export const GiftOpened = ({ nft, gift, giftAmount, onClaim, onContinue }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    setOpen(null)
  }

  useEffect(() => {
    setOpen(Boolean(nft || gift))
  }, [nft, gift])

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Stack sx={{ alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
          <ButtonBase onClick={handleClose} sx={{ position: 'absolute', top: 15, right: 15 }}>
            <img src="images/close-icon.png" style={{ width: 15, height: 15 }} />
          </ButtonBase>
          {
            nft && <Stack spacing={2} sx={{ background: "#000", width: 250, height: 340, p: 4, border: '5px solid rgba(255,255,255,0.2)' }}>
              <Typography textAlign={'center'} variant="h4">You got a hero</Typography>
              <Stack alignItems={'center'} justifyContent={'center'} sx={{ position: 'relative', height: '100%', width: '100%', border: `4px solid ${RarityColors[nft?.rarity || 'C']}`, padding: '3rem 0px' }}>
                <Typography variant="h4" sx={{ color: "#fff", position: 'absolute', top: '10px', left: '10px' }}>{`Lv.${nft?.level}`}</Typography>
                <Typography color={RarityColors[nft?.rarity || 'C']} variant="h3" sx={{ position: 'absolute', top: '35px', left: '10px' }}>{nft?.rarity}</Typography>
                <FlexRowAlign gap={0.5} sx={{ color: "#fff", position: 'absolute', top: '10px', right: '10px' }}>
                  <img src='images/sword.png' style={{ height: 15 }} />
                  <Typography variant="h4" >{nft?.stats}</Typography>
                </FlexRowAlign>
                <img src={`images/gifs/${nft?.image}/stand.gif`} style={{ height: 70, width: 'auto' }} />
                <Typography variant="h5" sx={{ color: "#fff", opacity: 0.6, position: 'absolute', bottom: '10px', left: '10px' }}>ID: {nft?.id}</Typography>
              </Stack>
              <PrimaryButton onClick={() => onClaim()} sx={{ background: '#2B9DFF', width: '100%', fontSize: '1.4rem', minHeight: 35 }}>Your Warriors</PrimaryButton>
              {/* <PrimaryButton onClick={() => onContinue()} sx={{ background: '#2B9DFF', width: '100%', fontSize: '1.4rem', minHeight: 35 }}>Continue</PrimaryButton> */}
            </Stack>
          }
          {
            gift && <Stack spacing={3} sx={{ background: "#000", width: 250, height: 240, p: 4, border: '5px solid rgba(255,255,255,0.2)', height: '100%' }} justifyContent={'space-between'} >
              <Typography textAlign={'center'} variant="h4">You have received the reward</Typography>
              <img src={`images/${gift}.png`} style={{ height: 40, objectFit: 'contain' }} />
              <Typography textAlign={'center'} variant="h4">x<CountUp end={giftAmount} decimals={2} duration={2}/></Typography>
            </Stack>
          }
        </Stack>
      </Modal >
    </>
  );
};

export const StyledButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  background: theme.palette.background.default,
  borderRadius: "0.8rem ",
  padding: "1rem",
  flexDirection: "column",
  color: theme.palette.text.primary,
  width: "100%",
  "&.active": {
    background: `${theme.palette.primary.main} !important`,
    color: "#000",
  },
}));

export const StyledInput = styled(OutlinedInput)(({ theme }) => ({
  margin: "1.5rem 0px",
  input: {
    textAlign: "center",
  },
  "& fieldset": {
    borderRadius: "0.5rem",
    border: `1px solid ${theme.palette.text.disabled} !important`,
  },
}));
