import TelegramProvider, { useTelegram } from "contexts/TelegramContext";
import {
  TonConnectButton,
  useTonWallet,
  useTonConnectUI,
  SendTransactionRequest,
  useTonConnectModal,
} from "@tonconnect/ui-react";
import { LoadingButton } from "@mui/lab";
import {
  Badge,
  Box,
  Button,
  ButtonBase,
  Grid,
  LinearProgress,
  OutlinedInput,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { FlexBetween, FlexRowAlign } from "components/flexbox";
import Decimal from "decimal.js";
import { t } from "i18next";
import ProfitIcon from "icons/ProfitIcon";
import TNXIcon from "icons/TNXIcon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import useSettings from "../../hooks/useSettings";
import useAxios from "../../hooks/useAxios";
import useUserData from "../../hooks/useUserData";
import useAvailableChest from "../../hooks/useAvailableChest";
import Slider from "react-slick";
import useItems from "../../hooks/useItems";

import { BoxContainer, BoxFooter, BrownBox, PrimaryButton, TitleBox, TypographyOutline } from "./styled";
import { ArrowDropDownRounded, ArrowDropUpRounded } from "@mui/icons-material";
import { Buffer } from 'buffer'
import { beginCell } from "@ton/ton";
import { BoxTitle } from "../../components/BoxTitle";
import { useNavigate } from "react-router-dom";
import { UpgradeForm } from "../../page-sections/homepage/UpgradeForm";
import Iconify from "../../components/Iconify";
import { ConnectWalletPopup } from "../../components/ConnectWallet";
import { fCurrency } from "../../utils/format";
import ChainSelector, { SupportedChain } from "../../page-sections/ChainSelector";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount, useContractWrite, useDisconnect, useSendTransaction, useSwitchChain, useWriteContract } from "wagmi";
import { erc20Abi, parseEther } from "viem";
// global.Buffer = Buffer;

const data = [
  {
    name: "Worker",
    speed: 10,
    period: 30,
    price: 1000000,
    dailyProfit: 21600,
    totalProfit: 648000,
    paid: 1000000,
    symbol: "CAT",
    bonus: 5000,
    bonusSymbol: "CAT",
    bonusSymbolIcon: <TNXIcon size={18} />,
  },
  {
    name: "Boss",
    speed: 100,
    period: 30,
    price: 10000000,
    dailyProfit: 216000,
    totalProfit: 6480000,
    paid: 10000000,
    symbol: "CAT",
    bonus: 50000,
    bonusSymbol: "CAT",
    bonusSymbolIcon: <TNXIcon size={18} />,
  },
  {
    name: "Moon Cat",
    speed: 1000,
    period: 30,
    price: 100000000,
    dailyProfit: 2160000,
    totalProfit: 64800000,
    paid: 100000000,
    symbol: "CAT",
    bonus: 500000,
    bonusSymbol: "CAT",
    bonusSymbolIcon: <TNXIcon size={18} />,
  },
];

const shop = [
  {
    name: 'necklace',
    price: 0.1,
    symbol: 'TON',
    duration: 3,
    durationUnit: 'days',
    profit: 30,
  },
  {
    name: 'chain',
    price: 0.2,
    symbol: 'TON',
    duration: 7,
    durationUnit: 'days',
    profit: 30
  },
  {
    name: 'milk',
    price: 0.3,
    symbol: 'TON',
    duration: 72,
    durationUnit: 'H',
    profit: 30
  },
  {
    name: 'royal canin',
    price: 0.6,
    symbol: 'TON',
    duration: 72,
    durationUnit: 'H',
    profit: 30
  },
  {
    name: 'mackerel',
    price: 0.8,
    symbol: 'TON',
    duration: 72,
    durationUnit: 'H',
    profit: 30
  },
  {
    name: 'tuna',
    price: '1.0',
    symbol: 'TON',
    duration: 72,
    durationUnit: 'H',
    profit: 30
  },
  {
    name: 'sturgeon',
    price: '2.0',
    symbol: 'TON',
    duration: 72,
    durationUnit: 'H',
    profit: 30
  },
  {
    name: 'salmon',
    price: '2.0',
    symbol: 'TON',
    duration: 72,
    durationUnit: 'H',
    profit: 30
  }
]
const chests = [
  { name: 'Legendary', price: 59, evmPrice: 0.1, id: 2, available: 2000 },
  { name: 'Mystery', price: 5.9, evmPrice: 0.01, id: 1, available: 48000 },
]
export const Boost = () => {

  const theme = useTheme();
  const { settings } = useSettings();
  const navigate = useNavigate()
  const [minerSelected, setMinerSelected] = useState(data[0]);
  const wallet = useTonWallet();
  const { open } = useTonConnectModal()
  const [tonConnectUi] = useTonConnectUI();
  const [isLoading, setIsLoading] = useState(false);
  const [miningAmount, setMiningAmount] = useState(5);
  const { callApi } = useAxios();
  const { userData, fetchUserData } = useUserData();
  const [tabIndex, setTabIndex] = useState(0)
  const { items } = useItems();
  const { user, webApp } = useTelegram();
  const [quantify, setQuantity] = useState(5)
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const [chainSelected, setChainSelected] = useState(SupportedChain[0])
  const { address, isConnected } = useAccount()
  const { disconnect } = useDisconnect()
  const { switchChainAsync } = useSwitchChain()
  const [chestIndex, setChestIndex] = useState(0)
  let sliderRef = useRef(null);
  const { availableChest, fetchAvailableChests } = useAvailableChest(chests[chestIndex].id)
  console.log("availableChest", availableChest)
  const {
    data: hash,
    error,
    isPending,
    sendTransactionAsync
  } = useSendTransaction()
  let price = chests[chestIndex || 0].price
  let available = chests[chestIndex || 0].available

  const changeChainId = useCallback(async () => {
    await switchChainAsync({ chainId: chainSelected.chainId })
  }, [chainSelected.chainId, switchChainAsync])
  useEffect(() => {
    if (chainSelected.chainId === 0) return
    changeChainId()
  }, [chainSelected, changeChainId])

  const updateEVMAddress = useCallback(async () => {
    if (!address) return;
    if (userData?.user?.wallet != address) {
      await callApi('post', '/user/link-wallet', {
        wallet: address
      })
    }
  }, [address, callApi, userData?.user?.wallet])

  const { writeContractAsync, isSuccess, isError } = useWriteContract();




  useEffect(() => {
    updateEVMAddress()
  }, [address, updateEVMAddress])

  // const amount = useMemo(() => {
  //   if (!miningAmount || miningAmount === '' || isNaN(miningAmount)) return 0;
  //   return new Decimal(minerSelected.price || 0).mul(miningAmount).toNumber();
  // }, [minerSelected, miningAmount]);
  const [isOpenConnectPopUp, setIsOpenConnectPopUp] = useState(false);
  const handleDisconnect = async () => {
    await tonConnectUI.disconnect()
  }

  const handleEVMDisconnect = async () => {
    await disconnect()
  }

  const handleSendETH = useCallback(async (qty) => {
    const quantityTemp = qty || quantify

    if (!address) return;
    setIsLoading(true);
    try {
      if (chainSelected.chainId !== 5000) {
        await sendTransactionAsync({ to: '0xeAfF922fD712158877AFe375938610322EE6b853', value: parseEther(new Decimal(chests[chestIndex ?? 0].evmPrice).mul(quantityTemp).mul((chestIndex === 0 &&quantityTemp >= 10) ? 0.95 : 1).toString()) })
      } else {
        console.log("???")
        await writeContractAsync({
          address: '0xcDA86A272531e8640cD7F1a92c01839911B90bb0',
          abi: erc20Abi,
          functionName: 'transfer',
          args: ['0xeAfF922fD712158877AFe375938610322EE6b853', parseEther(new Decimal(chests[chestIndex ?? 0].evmPrice).mul((chestIndex === 0 &&quantityTemp >= 10) ? 0.95 : 1).mul(quantityTemp).toString())],
        })
      }
      toast.success(
        "🎉 You have bought chests!",
        {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );

      toast.success(
        "🎉 You have bought chests!",
        {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(`Error: ${error.response.data.message}`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.log(error)
        toast.error("An unexpected error occurred.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [address, chainSelected.chainId, chestIndex, quantify, sendTransactionAsync, writeContractAsync]);


  const handleSendTon = useCallback(async (qty) => {
    const quantityTemp = qty || quantify
    if (!wallet) return;
    setIsLoading(true);
    try {
      const body = beginCell()
        .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
        .storeStringTail(`${chests[chestIndex ?? 0].id},${user.id.toString()}`) // write our text comment
        .endCell();
      const defaultTx = {
        // The transaction is valid for 10 minutes from now, in unix epoch seconds.
        validUntil: Math.floor(Date.now() / 1000) + 600,
        messages: [
          {
            // The receiver's address.
            address: "UQBEkov6BDsXjNVMMB5_-eGtvoc_WVe5ZaIqq6e72E1GS_rU",
            // Amount to send in nanoTON. For example, 0.005 TON is 5000000 nanoTON.
            amount: new Decimal(quantityTemp).mul(chests[chestIndex ?? 1].price).mul((chestIndex === 0 &&quantityTemp >= 10) ? 0.95 : 1).mul(Decimal.pow(10, 9)).toFixed(),
            payload: body.toBoc().toString("base64"),
          },
        ],
      };
      await tonConnectUi.sendTransaction(defaultTx, {
        notifications: "success",
      });

      // await callApi("post", "/user/check-payment", {
      //   amount: new Decimal(amount).mul(Decimal.pow(10, 9)).toFixed(),
      // });
      // await fetchUserData();

      toast.success(
        "🎉 You have bought chests!",
        {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );

      toast.success(
        "🎉 You have bought chests!",
        {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(`Error: ${error.response.data.message}`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("An unexpected error occurred.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [wallet, chestIndex, user?.id, quantify, tonConnectUi]);

  const handleIncreaseQty = () => {
    setQuantity(parseInt(quantify) + 1)
  }
  const handleDecreaseQty = () => {
    setQuantity(Math.max(1, parseInt(quantify) - 1))
  }
  const SlickSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (x, next) => setChestIndex(next),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return (
    <BoxContainer sx={{ alignItems: 'center', background: chestIndex === 0 ? 'linear-gradient(#161719,#860101)' : '' }}>
      <ConnectWalletPopup
        openConnectQuest={isOpenConnectPopUp}
        closeConnectQuest={() => setIsOpenConnectPopUp(false)}
      />
      <ButtonBase onClick={() => navigate(-1)} sx={{ padding: 0, position: 'absolute', top: '1rem', left: '1rem', }}>
        <img src="images/button-back.png" style={{ width: 40, height: 40 }} />
      </ButtonBase>
      <FlexRowAlign gap={2}>
        <ButtonBase onClick={() => sliderRef?.current?.slickGoTo(0)}>
          <Typography lineHeight={'40px'} fontWeight={700} variant="h5" textAlign={'center'} width={'100%'} sx={{ opacity: chestIndex === 0 ? 1 : 0.5 }}>Legendary Chest</Typography>
        </ButtonBase>
        <ButtonBase onClick={() => sliderRef?.current?.slickGoTo(1)}>
          <Typography lineHeight={'40px'} fontWeight={700} variant="h5" textAlign={'center'} width={'100%'} sx={{ opacity: chestIndex === 1 ? 1 : 0.5 }}>Mystery Chest</Typography>
        </ButtonBase>
      </FlexRowAlign>
      <Stack sx={{ width: '100%', pt: 3 }} spacing={3} alignItems={'center'}>
        <Box width={200}>
          <Slider ref={sliderRef} {...SlickSettings}>
            <Box sx={{ position: 'relative' }}>
              <img src={`images/box-bg-3${chestIndex === 0 ? '-1' : ''}.png`} style={{ width: 150, height: 150, margin: 'auto' }} />
              <Stack spacing={1} alignItems={'center'} justifyContent={'center'} sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                <img src='images/legend-chest.png' style={{ width: '85%', maxWidth: 200 }} />
              </Stack>
            </Box>
            <Box sx={{ position: 'relative' }}>
              <img src='images/box-bg-3.png' style={{ width: 150, height: 150, margin: 'auto' }} />
              <Stack spacing={1} alignItems={'center'} justifyContent={'center'} sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                <img src='images/chest.png' style={{ width: '55%', maxWidth: 150 }} />
              </Stack>
            </Box>
          </Slider>
        </Box>
        <Stack width={'100%'} alignItems={'center'} spacing={1}>
          <LinearProgress value={new Decimal(availableChest?.[0].chestsPurchased ?? 0).div(available).mul(100).floor().toNumber()} variant="determinate" sx={{ background: '#000', width: '100%', maxWidth: 180, borderRadius: 0, height: 7, '& .MuiLinearProgress-bar': { background: chestIndex === 0 ? '#F64120' : '#708AA6' } }} />
          <Typography fontStyle={'italic'} sx={{ opacity: 0.7 }}>{fCurrency(availableChest?.[0].chestsPurchased ?? 0, 2)}/ {fCurrency(available ?? 0, 2)} chests ({new Decimal(availableChest?.[0].chestsPurchased ?? 0).div(available ?? 0).mul(100).toFixed(2)}%)</Typography>
        </Stack>
        <Stack p={2} spacing={2} sx={{ background: chestIndex === 0 ? '#220404' : '#121625', border: chestIndex === 0 ? '3px solid #750504' : '3px solid #064A75', width: '100%' }}>
          <FlexBetween>
            <Typography variant="h5">Chain</Typography>
            <ChainSelector onChange={chain => setChainSelected(chain)} />
          </FlexBetween>
          <FlexBetween>
            <Typography variant="h5">1 x Chest</Typography>
            <Box flexGrow={1} />
            <img src={chainSelected.chainId === 0 ? "images/TON.png" : chainSelected.iconToken} style={{ width: 'auto', height: 25 }} />
            <Typography variant="h4" sx={{ color: chestIndex === 0 ? '#FE2B2A' : '#0FBAFF' }} ml={1}>{chainSelected.chainId === 0 ? chests[chestIndex ?? 1].price : chests[chestIndex ?? 1].evmPrice} {chainSelected.token}</Typography>
          </FlexBetween>
          <FlexBetween gap={4}>
            <Typography variant="h5">Quantity</Typography>
            <OutlinedInput
              value={quantify}
              type='number'
              onChange={(e) => setQuantity(e.target.value)}
              sx={{ background: "#000", minWidth: 140, '& fieldset': { borderRadius: 0, border: chestIndex === 0 ? '2px solid #750504 !important' : '2px solid #064A74 !important' }, '& input': { textAlign: 'center', fontSize: '2rem', padding: 0, height: 50 } }}
              startAdornment={<ButtonBase onClick={() => handleDecreaseQty()}><Iconify icon="dashicons:minus" size={20} /></ButtonBase>}
              endAdornment={<ButtonBase onClick={() => handleIncreaseQty()}><Iconify icon="memory:plus" size={20} /></ButtonBase>}
            />
          </FlexBetween>
          <FlexBetween>
            <Typography variant="h5">Your Total</Typography>
            <Typography variant="h3" sx={{ color: chestIndex === 0 ? '#FE2B2A' : '#0FBAFF' }}>{chainSelected.chainId === 0 ? new Decimal(chests[chestIndex ?? 1].price).mul(quantify).mul((chestIndex === 0 && quantify >= 10) ? 0.95 : 1).toNumber() : new Decimal(chests[chestIndex ?? 1].evmPrice).mul(quantify).mul((chestIndex === 0 && quantify >= 10) ? 0.95 : 1).toNumber()} {chainSelected.token}</Typography>
          </FlexBetween>
          {chainSelected.chainId === 0 ? <>
            {wallet?.account ? (
              <>
                <FlexBetween gap={2}>
                  <PrimaryButton
                    sx={{ height: 35, flexGrow: 1, fontSize: '2rem' }}
                    onClick={() => handleSendTon()}
                    loading={isLoading}
                  >
                    {t("Buy")}
                  </PrimaryButton>
                  {
                    chestIndex === 0 ? <Badge color="error" badgeContent={'-5%'} sx={{ flexGrow: 1, '& .MuiBadge-badge': { borderRadius: '0px', background: '#F21514' } }}>
                      <PrimaryButton
                        sx={{ height: 35, width: '100%', fontSize: '2rem', background: '#FF6329 !important' }}
                        onClick={() => handleSendTon(10)}
                        loading={isLoading}
                      >
                        {t("Buy 10")}
                      </PrimaryButton>
                    </Badge> : ''
                  }
                </FlexBetween>
                <ButtonBase
                  sx={{ backgroundSize: 'contain', height: 40, width: '100%', fontSize: '2rem' }}
                  onClick={handleDisconnect}
                  loading={isLoading}
                >
                  {t("Disconnect Wallet")}
                </ButtonBase>
              </>
            ) : (
              <ButtonBase onClick={open} sx={{ background: 'url("images/button.png") 50% 50% no-repeat', backgroundSize: 'contain', height: 40, width: '100%', fontSize: '2rem' }}>
                Connect wallet
              </ButtonBase>
            )}
          </> : <>
            {isConnected === false ? <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ConnectButton
                  sx={{
                    backgroundSize: 'contain',
                    height: 40,
                    width: '100%',
                    fontSize: '2rem'
                  }}
                />
              </Box>
            </> : <>
              <FlexBetween gap={2}>
                <PrimaryButton
                  sx={{ height: 35, flexGrow: 1, fontSize: '2rem' }}
                  onClick={() => handleSendETH()}
                  loading={isLoading}
                >
                  {t("Buy")}
                </PrimaryButton>
                {
                  chestIndex === 0 ? <Badge color="error" badgeContent={'-5%'} sx={{ flexGrow: 1, '& .MuiBadge-badge': { borderRadius: '0px', background: '#F21514' } }}>
                    <PrimaryButton
                      sx={{ height: 35, width: '100%', fontSize: '2rem', background: '#FF6329 !important' }}
                      onClick={() => handleSendETH(10)}
                      loading={isLoading}
                    >
                      {t("Buy 10")}
                    </PrimaryButton>
                  </Badge> : ''
                }
              </FlexBetween>

              <ButtonBase
                sx={{ backgroundSize: 'contain', height: 40, width: '100%', fontSize: '2rem' }}
                onClick={handleEVMDisconnect}
              >
                {t("Disconnect Wallet")}
              </ButtonBase>
            </>
            }

          </>}


        </Stack>
      </Stack>
    </BoxContainer>
  );
};

function NextArrow(props) {
  return null
}

function PrevArrow(props) {
  return null
}

export const StyledButton = styled(Button)(({ theme }) => ({
  border: `1px solid #B7B7B7`,
  background: theme.palette.background.default,
  borderRadius: "0.8rem",
  position: "relative",
  flexDirection: "column",
  padding: 0,
  img: {
    filter: "saturate(0)",
  },
  "&.active": {
    borderColor: "#FAFF00",
    boxShadow:
      "inset 0px 4px 0px rgba(255,255,255,0.25), 2px 4px 0px rgba(0,0,0)",
    img: {
      filter: "saturate(1)",
    },
  },
}));

export const StyledInput = styled(OutlinedInput)(({ theme }) => ({
  margin: "1.5rem 0px",
  background: "#000",
  borderRadius: "0.5rem",
  input: {
    textAlign: "center",
    color: "white",
    fontWeight: 600,
  },
  "& fieldset": {
    border: `1px solid #000!important`,
  },
}));

const PriceBox = styled(Stack)(({ theme }) => ({
  background: "#FFF4CE",
  borderRadius: "0.5rem",
  padding: "0.5rem",
  justifyContent: "center",
  alignItems: "center",
  minHeight: 64,
}));

const DailyBox = styled(Stack)(({ theme }) => ({
  background: "#FFF06C",
  borderRadius: "0.5rem",
  padding: "0.5rem 1rem",
  minHeight: 40,
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));

const PayBox = styled(Stack)(({ theme }) => ({
  background: "#FFFFFF",
  borderRadius: "0.5rem",
  padding: "0.5rem 1rem",
  minHeight: 40,
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow:
    "inset 0px 4px 0px rgba(255,255,255,0.25), 2px 4px 0px rgba(0,0,0)",
}));

const UpgradeBox = styled(Stack)(({ theme }) => ({
  maxHeight: "calc(100vh - 290px)",
  overflow: 'scroll',
  background: '#FFF3E8',
  borderTop: '5px solid #DDC5BC',
  padding: '1.5rem',
}));

const ShopBox = styled(Stack)(({ theme }) => ({
  maxHeight: "calc(100vh - 290px)",
  overflow: 'scroll',
  background: '#FFF3E8',
  borderTop: '5px solid #DDC5BC',
  padding: '1.5rem',
}));

const ShopItem = styled(FlexBetween)(({ theme }) => ({
  border: '2px solid #000',
  boxShadow: '3px 4px #000',
  borderRadius: '0.7rem',
  minHeight: 80,
  background: '#FFA863',
  padding: '0px',
  paddingLeft: '10px',
  overflow: 'hidden',
  '& .image': {
    background: '#D0775B',
    width: 70,
    height: 60,
    borderRadius: '0.6rem',
    boxShadow: 'inset 0px 7px 0px rgba(0,0,0,0.1)'
  },
  '& .name-card': {
    height: 60,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    border: '1px solid #AA7042',
    borderRadius: '0.6rem',
    '& .name': {
      fontFamily: '"Rubik", sans-serif !important',
      fontWeight: 900,
      fontSize: '1.2rem',
      textTransform: 'uppercase',
      WebkitTextFillColor: "#fff",
      WebkitTextStroke: "1px #77483F",
      paintOrder: "stroke fill",
      textShadow: '0px 2px rgba(0,0,0,0.4) !important',

      '&:before,&:after': {
        content: '""',
        position: 'absolute',
        width: "6px",
        height: '6px',
        borderRadius: '6px',
        background: "rgba(0,0,0,0.4)",
        top: '6px',
        left: '6px'
      },
      '&:after': {
        right: '6px',
        left: 'unset'
      }
    },
    '& .description': {
      fontWeight: 500,
      '&:before,&:after': {
        content: '""',
        position: 'absolute',
        width: "6px",
        height: '6px',
        borderRadius: '6px',
        background: "rgba(0,0,0,0.4)",
        bottom: '6px',
        left: '6px'
      },
      '&:after': {
        right: '6px',
        left: 'unset'
      }
    }
  },
  '& .price-card': {
    width: '100px',
    height: 80,
    borderLeft: '1px solid #000',
    background: '#FFFFFF'
  }
}));