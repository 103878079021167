import { Backdrop, Box, Divider, Stack, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { randomNumber, sleep } from '../../utils'
import { animated, useSpring } from '@react-spring/web'
import FlexRowAlign from "../../components/flexbox/FlexRowAlign";
import FlexBetween from "../../components/flexbox/FlexBetween";
import Countdown from 'react-countdown';
import moment from "moment";
import { PrimaryButton } from "../../pages/mobile/styled";
import Iconify from "../../components/Iconify";
import CountUp from 'react-countup';

export const BattleProgress = memo(({ data, onComplete }) => {
    const { map, myTeam, opponents, round1, round2, round3, isWinner, rewards } = data
    const [start, setStart] = useState(false)
    const [end, setEnd] = useState(false)
    const [roundCount, setRoundCount] = useState(1)
    const [isNewRound, setIsNewRound] = useState(true)
    const [winnerInRound, setWinnerInRound] = useState(null)
    const winners = [round1, round2, round3]

    const FightStyles = useSpring({
        from: {
            transform: 'scale(0)'
        },
        to: {
            transform: 'scale(1.3)'
        },
        delay: 5000
    })
    const ZoomInStyles = useSpring({
        from: {
            transform: 'scale(5)'
        },
        to: {
            transform: 'scale(1)'
        },
    })
    const WinLossStyles = useSpring({
        from: {
            transform: 'scale(5)',
            opacity: 0
        },
        to: {
            transform: 'scale(1)',
            opacity: 1
        },
        delay: 1000
    })
    const TeamAStyles = useSpring({
        from: {
            x: -200,
        },
        to: {
            x: 0
        },
        config: { mass: 3, tension: 550, friction: 50 },
        delay: 2000
    })

    const handleComplete = useCallback(() => {
        if (onComplete) {
            onComplete()
        }
    },[onComplete])

    const BattleProcessCallBack= useCallback(async()=>{
        //Start round 1 after 5s
        await sleep(4000)
        setStart(true)
        //End round 1 after 5s
        await sleep(2000)
        setEnd(true)
        //Waiting next round
        await sleep(2000)
        setIsNewRound(false)
        setEnd(false)
        setStart(false)
        await sleep(500)
        setRoundCount(2)
        setIsNewRound(true)

        //Start round 2 after 5s
        await sleep(4000)
        setStart(true)
        //End round 2 after 5s
        await sleep(2000)
        setEnd(true)
        //Waiting next round
        await sleep(2000)
        setIsNewRound(false)
        setEnd(false)
        setStart(false)
        await sleep(500)
        setRoundCount(3)
        setIsNewRound(true)

        //Start round 3 after 5s
        await sleep(4000)
        setStart(true)
        //End round 3 after 5s
        await sleep(2000)
        setEnd(true)
        await sleep(2000)
        setRoundCount(4)
        await sleep(4000)
        onComplete()

    },[])

    useEffect(()=>{
        BattleProcessCallBack()
    }, [BattleProcessCallBack])
    return <Stack mt={2} sx={{ width: '100%', aspectRatio: 1, position: 'relative' }}>
        <img src={map?.thumbnail} style={{ width: '100%', height: '100%', objectFit: 'cover', margin: 'auto' }} />
        <animated.div style={{ ...ZoomInStyles, position: 'absolute', top: 0, zIndex: 1000, width: '100%' }}>
            <FlexRowAlign gap={1} sx={{ background: 'rgba(0,0,0,0.8)', boxShadow: '3px 3px 0px #000' }}>
                <FlexBetween px={1} gap={2} sx={{ borderBottom: '2px solid #fff', width: '100%' }}>
                    <img key={`team-A-${myTeam?.nfts?.[0]?.id}-thumb`} src={`/images/heroes/${myTeam?.nfts?.[0]?.image}.png`} style={{ width: 40, height: 40, objectFit: 'cover' }} />
                    <Typography variant="h3" noWrap maxWidth={80}>{myTeam?.userName}</Typography>
                    <Box flexGrow={1} />
                </FlexBetween>
                <Typography variant="h2" fontStyle={'italic'} sx={{ transform: 'scale(1.6)' }}>vs</Typography>
                <FlexBetween px={1} gap={2} sx={{ borderBottom: '2px solid #fff', width: '100%' }}>
                    <Box flexGrow={1} />
                    <Typography variant="h3" noWrap maxWidth={80}>{opponents?.userName}</Typography>
                    <img key={`team-A-${opponents?.nfts?.[0]?.id}-thumb`} src={`/images/heroes/enemy-${opponents?.nfts?.[0]?.image}.png`} style={{ width: 40, height: 40, objectFit: 'cover' }} />
                </FlexBetween>
            </FlexRowAlign>
            <FlexBetween mt={1} px={1} gap={'50px'}>
                <FlexRowAlign width={'50%'} gap={0.5} sx={{ alignItems: 'flex-start', color: '#000' }}>
                    {
                        myTeam?.nfts?.map((char, index) => <img key={`team-A-${char?.image}-thumb-3-3${index}`} src={`/images/heroes/${char?.image}.png`} style={{ width: 25, height: 25, objectFit: 'cover', border: '1px solid #fff', boxShadow: '2px 2px 0px #000', background: "#000", padding: '2px', filter: roundCount > (index + 1) && winners?.[index] !== char.id ? 'grayscale(1) brightness(0.4)' : '' }} />)
                    }
                    <Box flexGrow={1} />
                    <Iconify icon={'fa-solid:circle'} size={10} sx={{ display: roundCount > 1 && round1 === myTeam?.nfts?.[0].id ? 'inital' : 'none', color: '#ffa54c', borderRadius: '50%', boxShadow: '1px 1px 0px #000' }} />
                    <Iconify icon={'fa-solid:circle'} size={10} sx={{ display: roundCount > 2 && round2 === myTeam?.nfts?.[1].id ? 'inital' : 'none', color: '#ffa54c', borderRadius: '50%', boxShadow: '1px 1px 0px #000' }} />
                    <Iconify icon={'fa-solid:circle'} size={10} sx={{ display: roundCount > 3 && round3 === myTeam?.nfts?.[2].id ? 'inital' : 'none', color: '#ffa54c', borderRadius: '50%', boxShadow: '1px 1px 0px #000' }} />
                </FlexRowAlign>
                <FlexRowAlign width={'50%'} gap={0.5} sx={{ alignItems: 'flex-start', color: '#000' }}>
                    <Iconify icon={'fa-solid:circle'} size={10} sx={{ display: roundCount > 1 && round1 === opponents?.nfts?.[0].id ? 'inital' : 'none', color: '#ffa54c', borderRadius: '50%', boxShadow: '1px 1px 0px #000' }} />
                    <Iconify icon={'fa-solid:circle'} size={10} sx={{ display: roundCount > 2 && round2 === opponents?.nfts?.[1].id ? 'inital' : 'none', color: '#ffa54c', borderRadius: '50%', boxShadow: '1px 1px 0px #000' }} />
                    <Iconify icon={'fa-solid:circle'} size={10} sx={{ display: roundCount > 3 && round3 === opponents?.nfts?.[2].id ? 'inital' : 'none', color: '#ffa54c', borderRadius: '50%', boxShadow: '1px 1px 0px #000' }} />
                    <Box flexGrow={1} />
                    {
                        opponents?.nfts?.map((char, index) => <img key={`team-B-${char?.image}-thumb-3-3${index}`} src={`/images/heroes/enemy-${char?.image}.png`} style={{ width: 25, height: 25, objectFit: 'cover', border: '1px solid #fff', boxShadow: '2px 2px 0px #000', background: "#000", padding: '2px', filter: roundCount > (index + 1) && winners?.[index] !== char.id ? 'grayscale(1) brightness(0.4)' : '' }} />)
                    }
                </FlexRowAlign>
            </FlexBetween>
        </animated.div>
        <Backdrop
            unmountOnExit
            in={end && roundCount > 3}
            sx={{ position: 'absolute', zIndex: 1000, background: 'none', padding: '2rem', top: '35%', left: 0, height: '50%', }}>
            <Stack spacing={2} py={2} alignItems={'center'} justifyContent={'center'} sx={{ height: 'fit-content', width: '100%', background: "rgba(0,0,0,0.8)", border: '1px solid #95A0C8', boxShadow: `0px 0px 10px #fff` }}>
                {
                    isWinner ? <Typography variant="h1" sx={{ marginTop: '-20px', textShadow: '2px 2px 0px #000, -2px -2px 0px #000', color: '#FFC809', fontWeight: 900, textTransform: 'uppercase' }}>Victory</Typography>
                        : <Typography variant="h1" sx={{ marginTop: '-20px', textShadow: '2px 2px 0px #000, -2px -2px 0px #000', color: '#d0dde5', fontWeight: 900, textTransform: 'uppercase' }}>Defeated</Typography>
                }
                <Stack spacing={2}>
                    <Divider sx={{ width: '100%', fontSize: '1.5rem' }}>Reward</Divider>
                    <FlexRowAlign gap={2}>
                        <Stack >
                            <Stack sx={{ position: 'relative' }}>
                                <img src="/images/box-bg-3.png" style={{ width: 50, height: 50 }} />
                                <img src={`/images/${rewards?.[0]?.tokenId === '2' ? 'TRT' : 'TON'}.png`} style={{ position: 'absolute', top: 0, width: '100%', height: '100%', padding: '5px' }} />
                            </Stack>
                            <Typography variant="h5" textAlign={'center'} sx={{ width: '100%', textShadow: '1px 1px 0px #000, -1px -1px 0px #000' }}>{isWinner ? '+' : '-'}{<CountUp end={Math.abs(rewards?.[0].amount)} decimals={2} duration={2} />}</Typography>
                        </Stack>
                        <Stack>
                            <Stack sx={{ position: 'relative' }}>
                                <img src="/images/box-bg-3.png" style={{ width: 50, height: 50 }} />
                                <img src='/images/GOLD.png' style={{ position: 'absolute', top: 0, width: '100%', height: '100%', padding: '8px' }} />
                            </Stack>
                            <Typography variant="h5" textAlign={'center'} sx={{ width: '100%', textShadow: '1px 1px 0px #000, -1px -1px 0px #000' }}>+{<CountUp end={rewards?.[1].amount} decimals={2} duration={2} />}</Typography>
                        </Stack>
                    </FlexRowAlign>
                </Stack>
                <PrimaryButton onClick={() => handleComplete()} sx={{ background: '#FF5638 !important', minHeight: 40, width: 200, fontSize: '1.6rem', color: "#000", fontWeight: 700 }}>Claim</PrimaryButton>
            </Stack>
        </Backdrop>
        {
            end && roundCount <= 3 && <animated.div style={{ ...WinLossStyles, position: 'absolute', left: 0, width: '100%', top: '35%' }}>
                {
                    Boolean(myTeam?.nfts?.find(e => e.id === winners?.[roundCount - 1]))
                        ? <WinLabel text={'You Win'} color={'#FFC809'} />
                        : <WinLabel text={'You Loss'} color={'#d0dde5'} />
                }
            </animated.div>
        }
        {
            isNewRound && roundCount <= 3 && <>
                <Backdrop in={!start} unmountOnExit sx={{ position: 'absolute', zIndex: 100, background: 'rgba(0,0,0,0.4)' }}>
                    <Countdown
                        date={Date.now() + 5000}
                        renderer={props => <RendererCountdown {...props} round={roundCount} />}
                    />
                </Backdrop>
                <Charactor
                    isEnemy={false}
                    start={start}
                    end={end}
                    index={roundCount - 1}
                    data={{
                        ...myTeam?.nfts?.[roundCount - 1],
                        initialPosition: [1, 5, 1],
                        moveTo: 'right',
                        round1,
                        round2,
                        round3
                    }}
                    key={`team-A-${myTeam.nfts?.[roundCount - 1]?.id}`}
                />
                <Charactor
                    isEnemy={true}
                    start={start}
                    end={end}
                    index={roundCount - 1}
                    data={{
                        ...opponents.nfts?.[roundCount - 1],
                        initialPosition: [7, 5, 1],
                        moveTo: 'left',
                        round1,
                        round2,
                        round3
                    }}
                    key={`team-B-${opponents.nfts?.[roundCount - 1]?.id}`}
                />
            </>
        }
        {
            start && !end && <animated.img
                src={`/images/gifs/hero/fight.gif`}
                width={'40%'}
                height={'40%'}
                style={{
                    objectFit: 'contain',
                    position: 'absolute',
                    left: '28%',
                    top: '33%',
                    ...FightStyles
                }}
            />
        }
    </Stack>
})

const Charactor = ({ data, start, end, isEnemy, index }) => {
    const { initialPosition, moveTo, id, image, round1, round2, round3 } = data
    const winId = index === 0 ? round1 : index === 1 ? round2 : round3
    const isWin = (id === winId ? true : false)
    const [moving, setMoving] = useState('')
    const [newPosition, setNewPosition] = useState(null)
    const [inBattle, setInBattle] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setInBattle(true)
        }, 1000);
    }, [data])
    useEffect(() => {
        if (start) {
            setMoving(moveTo)
            setNewPosition([4, 5])
        }
    }, [start])
    const FadingStyles = useSpring({
        from: {
            filter: 'brightness(6) contrast(6)'
        },
        to: {
            filter: 'brightness(1) contrast(1)'
        },
        delay: 1500
    })
    const FadeOutStyles = useSpring({
        from: {
            opacity: 1,
        },
        to: {
            opacity: 0,
        },
        delay: 1600
    })
    return (!end || isWin) && <Stack sx={{
        position: 'absolute',
        top: `${(newPosition?.[1] || initialPosition?.[1]) * 10}%`,
        left: `${(newPosition?.[0] || initialPosition?.[0]) * 10}%`,
        width: '10%',
        height: '10%',
        marginLeft: `${initialPosition[2]}rem`,
        transition: 'all 1s ease-out'
    }}>
        {
            inBattle && <Stack sx={{ width: '100%', height: '100%', position: 'relative' }}>
                {
                    !start && <FlexRowAlign sx={{ position: 'absolute', top: '-50%' }}>
                        <img src='/images/sword.png' style={{ height: 10 }} />
                        <Typography variant="h6" >{data?.stats}</Typography>
                    </FlexRowAlign>
                }
                <animated.img
                    src={`/images/gifs/${data.image}/${moving ? (end ? (isEnemy ? 'left-stand' : 'stand') : moving) : (isEnemy ? 'left-stand' : 'stand')}.gif`}
                    width={'100%'}
                    height={'100%'}
                    style={{ objectFit: 'contain', ...FadingStyles, transition: 'all 0.1s ease-in-out !important' }}
                />
                <animated.img
                    src={`/images/gifs/hero/fade-in-2.gif`}
                    width={'100%'}
                    height={'100%'}
                    style={{
                        objectFit: 'contain',
                        transform: 'scale(1.6)',
                        position: 'absolute',
                        bottom: '-0.5rem',
                        ...FadeOutStyles
                    }}
                />
                <animated.img
                    src={`/images/gifs/hero/fade-in-1.gif`}
                    width={'100%'}
                    height={'100%'}
                    style={{
                        objectFit: 'contain',
                        transform: 'scale(2)',
                        position: 'absolute',
                        top: '-1rem',
                        ...FadeOutStyles
                    }}
                />
            </Stack>
        }
    </Stack>
}

const RendererCountdown = ({ seconds, completed, color, sx, round }) => {
    return completed ? '' : <Stack>
        <Typography textAlign={'center'} variant="h3" sx={{ fontWeight: 700, whiteSpace: 'nowrap', textShadow: '2px 2px 0px #000' }}>{`Round ${round}`}</Typography>
        <Typography textAlign={'center'} variant="h1" sx={{ fontWeight: 700, whiteSpace: 'nowrap', textShadow: '2px 2px 0px #000' }}>{seconds <= 1 ? 'START' : `${seconds - 1}`}</Typography>
    </Stack>;
};

const WinLabel = ({ text, color }) => {
    const [props, api] = useSpring(
        () => ({
            from: { opacity: 0, scale: 5 },
            to: { opacity: 1, scale: 1 },
            config: {
                mass: 1, tension: 550, friction: 30
            }
        }),
        []
    )
    return <animated.h1 style={{ ...props, fontSize: '3.5rem', fontWeight: 900, textShadow: '2px 2px 0px #000, -1px -1px 0px #000', textAlign: 'center', color: color }}>{text}</animated.h1>
}