import { Box, Stack, styled } from "@mui/material"
import { TitleBox, TypographyOutline } from "../pages/mobile/styled"

export const BoxTitle = ({ title, sx, className, onClick }) => {
    return <TitleBox onClick={onClick} className={className} sx={{ position: 'relative', minHeight: '70px !important', ...sx }}>
        <TypographyOutline variant='h3' sx={{ WebkitTextStroke: "4px black !important", textShadow: '0px 5px 0px rgba(0,0,0,0.3)' }} fontWeight={700}>
            {
                title
            }
        </TypographyOutline>
        <Stack justifyContent={'space-between'} sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
            <Dot />
            <Dot />
        </Stack>
    </TitleBox>
}

export const Dot = styled(Box)(({ theme }) => ({
    width: '100%',
    minHeight: '30px',
    position: 'relative',
    '&:before,&:after': {
        content: '""',
        position: 'absolute',
        width: "10px",
        height: '10px',
        borderRadius: '10px',
        background: "rgba(0,0,0,0.4)",
        top: 'calc(50% - 5px)',
        left: '10px'
    },
    '&:after': {
        right: '10px',
        left: 'unset'
    }
}));