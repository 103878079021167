import { LoadingButton } from "@mui/lab";
import { Box, Button, styled } from "@mui/material";
import { PrimaryButton } from "../../pages/mobile/styled";

export const TitleModalBox = styled(Box)(({ theme }) => ({
  backgroundImage: "url(/images/modal-title-bg.svg)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 100%",
  aspectRatio: "4.8",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.25rem",
  padding: "1rem 0.5rem",
}));

export const ButtonWithdraw = styled(Button)(({ theme }) => ({
  position: "relative",
  borderRadius: "1rem",
  padding: "0.5rem 1rem 0.5rem 2.5rem",
  minHeight: "unset",
  overflow: "visible",
  margin: 'auto',
  background: "none !important"
}));

export const ModalContentBox = styled(Box)(({ theme }) => ({
  background: "none",
  position: "relative",
  borderRadius: "0.875rem",
  padding: "0.875rem",
  marginTop: "1rem",
  marginBottom: "1rem",
  "& .MuiInputBase-root": {
    background: "#000000",
    borderRadius: "0.5rem",
  },
  input: { color: "#FFFFFF" },
  fieldset: {
    borderColor: "#FFFFFF",
    borderRadius: "0.5rem",
  },
}));

export const ConvertButton = styled(Button)(({ theme }) => ({
  minWidth: "unset",
  minHeight: "unset",
  padding: "0.35rem 1rem",
  background: "#F6C434",
  color: '#000',
  boxShadow: "inset 0px -2px 0px #E58725, inset 0px 2px 0px #F6E98A",
  "&:hover, &:active": {
    background: "#F6C434",
    boxShadow: "inset 0px -3px 0px #E58725, inset 0px 3px 0px #F6E98A",
  },
}));

export const ClaimButton = styled(LoadingButton)(({ theme }) => ({
  minWidth: "70px",
  minHeight: "unset",
  padding: "0rem 1rem",
  background: "#FFF !important",
  color: '#000',
  borderRadius: 0,
  fontSize: '1.5rem',
  fontWeight: 700,
}));

export const ButtonAction = styled(PrimaryButton)(({ theme }) => ({
  position: "relative",
  borderRadius: "0px",
  background: "#F6A834",
  border: `2px solid #000`,
  height: '40px',
  boxShadow: "inset 0px -2px 0px #E58725, inset 0px 2px 0px #F6E98A",
  "&:hover, &:active": {
    background: "#F6A834",
    boxShadow: "inset 0px -2px 0px #E58725, inset 0px 2px 0px #F6E98A",
  },
}));
