import { useCallback, useEffect, useState } from "react";
import useSettings from './useSettings';

import useAxios from "./useAxios";

const useConvertEvent = () => {
    const { settings } = useSettings();
    const { callApi } = useAxios()
    const [convertEvent, setConvertEvent] = useState()
    const fetchConvertEvent = useCallback(async () => {
        if (!settings.token) return;
        try {
            const userData = await callApi('get', '/user/convert-event')
            setConvertEvent(userData as any)
        } catch (e) {
            console.log('ignoew')
        }
    }, [settings.token])
    useEffect(() => {
        fetchConvertEvent()
    }, [
        fetchConvertEvent, settings.token
    ])
    return { convertEvent, fetchConvertEvent }
};

export default useConvertEvent;
