import { useCallback, useEffect, useState } from "react";
import useSettings from './useSettings';

import { useTelegram } from "contexts/TelegramContext";
import useAxios from "./useAxios";

const useItems = () => {
    const { settings } = useSettings();
    const { callApi } = useAxios()
    const [items, setItems] = useState()
    const { user } = useTelegram()
    const fetchItems = useCallback(async () => {
        if (!settings.token) return;
        try {
            const items = await callApi('get', '/items/')
            setItems(items as any)
        } catch (e) {
            console.log('ignoew')
        }
    }, [settings.token])
    useEffect(() => {
        fetchItems()
    }, [
        fetchItems, settings.token
    ])
    return { items, fetchItems }
};

export default useItems;
