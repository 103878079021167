import { Box, Button, ButtonBase, Modal, Stack, Typography } from "@mui/material";
import { useState } from "react";
import FlexRowAlign from "../components/flexbox/FlexRowAlign";

export const SupportedChain = [
    {
        name: 'TON',
        chainId: 0,
        icon: '/images/TON.png',
        native: 'TON',
        
    },
    {
        name: 'Ethereum',
        chainId: 1,
        icon: '/images/ETH.png',
        iconToken: '/images/ETH.png',
        native: 'ETH',
        price: 0.01,
        token: 'ETH'
    },
    {
        name: 'Arbitrum',
        chainId: 42161,
        icon: '/images/arbitrum.png',
        iconToken: '/images/ETH.png',
        native: 'ARB',
        price:0.01,
        token: 'ETH'
    },
    {
        name: 'Base',
        chainId: 8453,
        icon: '/images/base.png',
        iconToken: '/images/ETH.png',
        native: 'BASE',
        price:0.01,
        token: 'ETH'
    },
    {
        name: 'Manta Network',
        chainId: 169,
        icon: '/images/manta.png',
        iconToken: '/images/ETH.png',
        native: 'MANTA',
        price:0.01,
        token: 'ETH'
    }
]

export default function ChainSelector({ onChange }) {
    const [open, setOpen] = useState(false)
    const [chainSelected, setChainSelected] = useState(SupportedChain[0])
    const handleChangeChain = (chain) => {
        setChainSelected(chain)
        setOpen(false)
        if (onChange) onChange(chain)
    }

    return <>
        <Button variant="outlined" onClick={() => setOpen(true)}>
            {
                chainSelected ? <FlexRowAlign gap={2}>
                    <img src={chainSelected.icon} style={{ width: 20, height: 20 }} />
                    <Typography sx={{ color: "#fff" }} variant="h4">{chainSelected.name}</Typography>
                </FlexRowAlign> : <Typography>Select chain</Typography>
            }
        </Button>
        <Modal open={open} onClose={() => setOpen(false)}>
            <Stack justifyContent={'center'} alignItems={'center'} height={'100%'} >
                <Box p={2} sx={{ border: '2px solid #064A75', minWidth: 300, position: 'relative', background: '#161718' }}>
                    <ButtonBase onClick={() => setOpen(false)} sx={{ position: 'absolute', top: 10, right: 10 }}>
                        <img src="/images/close-icon.png" style={{ width: 15, height: 15 }} />
                    </ButtonBase>
                    <Typography variant="h4" textAlign={'center'}>Select chain</Typography>
                    <Stack mt={2} spacing={1}>
                        {
                            SupportedChain.map((item, index) => <Button key={index} onClick={() => handleChangeChain(item)} variant="outlined">
                                <FlexRowAlign gap={2} justifyContent='flex-start' sx={{ width: '100%' }}>
                                    <img src={item.icon} style={{ width: 30, height: 30 }} />
                                    <Typography sx={{ color: "#fff" }} variant="h4">{item.name}</Typography>
                                </FlexRowAlign>
                            </Button>)
                        }
                    </Stack>
                </Box>
            </Stack>
        </Modal>
    </>
}