import TelegramProvider, { useTelegram } from "contexts/TelegramContext";
import {
  TonConnectButton,
  useTonWallet,
  useTonConnectUI,
  SendTransactionRequest,
} from "@tonconnect/ui-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  ButtonBase,
  Grid,
  OutlinedInput,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { FlexBetween, FlexRowAlign } from "components/flexbox";
import Decimal from "decimal.js";
import { t } from "i18next";
import ProfitIcon from "icons/ProfitIcon";
import TNXIcon from "icons/TNXIcon";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import useSettings from "../../hooks/useSettings";
import useAxios from "../../hooks/useAxios";
import useUserData from "../../hooks/useUserData";
import useUserChest from "../../hooks/useUserChest";
import useUserNFTs from "../../hooks/useUserNFTs";
import { RarityColors } from '../../utils/constants'

import useItems from "../../hooks/useItems";

import { BoxContainer, BoxFooter, BrownBox, PrimaryButton, TitleBox, TypographyOutline } from "./styled";
import { ArrowDropDownRounded, ArrowDropUpRounded } from "@mui/icons-material";
import { Buffer } from 'buffer'
import { beginCell } from "@ton/ton";
import { BoxTitle } from "../../components/BoxTitle";
import { useNavigate } from "react-router-dom";
import { UpgradeForm } from "../../page-sections/homepage/UpgradeForm";
import { ConnectWalletPopup } from "../../components/ConnectWallet";
import { GiftOpened } from "../../page-sections/homepage/GiftOpened";
import InfiniteScroll from "react-infinite-scroller";
// global.Buffer = Buffer;

const data = [
  {
    name: "Worker",
    speed: 10,
    period: 30,
    price: 1000000,
    dailyProfit: 21600,
    totalProfit: 648000,
    paid: 1000000,
    symbol: "CAT",
    bonus: 5000,
    bonusSymbol: "CAT",
    bonusSymbolIcon: <TNXIcon size={18} />,
  },
  {
    name: "Boss",
    speed: 100,
    period: 30,
    price: 10000000,
    dailyProfit: 216000,
    totalProfit: 6480000,
    paid: 10000000,
    symbol: "CAT",
    bonus: 50000,
    bonusSymbol: "CAT",
    bonusSymbolIcon: <TNXIcon size={18} />,
  },
  {
    name: "Moon Cat",
    speed: 1000,
    period: 30,
    price: 100000000,
    dailyProfit: 2160000,
    totalProfit: 64800000,
    paid: 100000000,
    symbol: "CAT",
    bonus: 500000,
    bonusSymbol: "CAT",
    bonusSymbolIcon: <TNXIcon size={18} />,
  },
];

const shop = [
  {
    name: 'necklace',
    price: 0.1,
    symbol: 'TON',
    duration: 3,
    durationUnit: 'days',
    profit: 30,
  },
  {
    name: 'chain',
    price: 0.2,
    symbol: 'TON',
    duration: 7,
    durationUnit: 'days',
    profit: 30
  },
  {
    name: 'milk',
    price: 0.3,
    symbol: 'TON',
    duration: 72,
    durationUnit: 'H',
    profit: 30
  },
  {
    name: 'royal canin',
    price: 0.6,
    symbol: 'TON',
    duration: 72,
    durationUnit: 'H',
    profit: 30
  },
  {
    name: 'mackerel',
    price: 0.8,
    symbol: 'TON',
    duration: 72,
    durationUnit: 'H',
    profit: 30
  },
  {
    name: 'tuna',
    price: '1.0',
    symbol: 'TON',
    duration: 72,
    durationUnit: 'H',
    profit: 30
  },
  {
    name: 'sturgeon',
    price: '2.0',
    symbol: 'TON',
    duration: 72,
    durationUnit: 'H',
    profit: 30
  },
  {
    name: 'salmon',
    price: '2.0',
    symbol: 'TON',
    duration: 72,
    durationUnit: 'H',
    profit: 30
  }
]

export const Upgrade = () => {
  const theme = useTheme();
  const navigate = useNavigate()
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { settings } = useSettings();

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)


  const { callApi } = useAxios();
  const [tabIndex, setTabIndex] = useState(0)
  const [data, setData] = useState({total:0, hasMore: false, data: []})
  
  const handleChangeTabIndex = (index) => {
    setData({ total: 0, data: [] })
    setPage(1); 
    setTabIndex(index)
  }

  useEffect(() => {
    if (data) {
      setHasMore(data.hasMore)
    }
  }, [data])

  const fetchData = useCallback(async () => {
    if (!settings.token) return;
    setIsLoadingMore(true)
    try {
      if(tabIndex===0){
        const results = await callApi('get', `/nft/get-my-nfts?page=${page}&limit=${10}`)
        if(results && results?.data?.length > 0){
          setData((nft)=> {
            const mergedData = [
              ...nft.data.filter(existingItem => !results.data.some(newItem => newItem.id === existingItem.id)),
              ...results.data,
          ];
          
          return {
            hasMore: results.total > page*10,
            total: results.total,
            data: mergedData
          };}
        )  
        }
      } else {
        const results = await callApi('get', `/chest/get-my-chest?page=${page}&limit=${10}`)
        if(results && results?.data?.length > 0){
          setData((nft)=> {
              const mergedData = [
              ...nft.data,
              ...results.data
            ];
            return {
              total: results.total,
              hasMore: results.total > page*10,
              data: mergedData
            };
          })  
        }
      }
    } catch (e) {
        console.log('ignoew')
    } finally {
      setIsLoadingMore(false)
    }
    }, [callApi, page, settings.token, tabIndex])

    useEffect(() => {
      fetchData()
    }, [
      fetchData, settings.token, tabIndex, page
    ])

  const [isOpenConnectPopUp, setIsOpenConnectPopUp] = useState(false);
  const [warriorOpened, setWarriorOpened] = useState(null)
  const handleGetData = useCallback(() => {
    setIsLoadingMore(true)
    setTimeout(() => {
      setPage(page + 1)
    }, 10);
  },[page])


  const handleOpenChest = async (chest) => {
    console.log("chest.id",chest.id)
    setIsLoading(true);
    try {
      const temp = await callApi('post', '/chest/open-chest', {
        chestId: chest.id
      })
      setWarriorOpened(temp?.[0])
      toast.success(
        "🎉 A Warrior was born!",
        {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
      setData(prevChests => {
        const newChests = [...prevChests.data]; // Tạo bản sao mảng trước khi sửa đổi
        const index = newChests.findIndex(c => c.id === chest.id); // Tìm index của phần tử đầu tiên có id trùng
        if (index !== -1) {
          newChests.splice(index, 1); // Xóa phần tử tại index tìm được
        }
        return {...prevChests,data:newChests};
      });
      await fetchData()
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(`Error: ${error.response.data.message}`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("An unexpected error occurred.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <BoxContainer sx={{ alignItems: 'center' }}>
      <ConnectWalletPopup
        openConnectQuest={isOpenConnectPopUp}
        closeConnectQuest={() => setIsOpenConnectPopUp(false)}
      />
      <ButtonBase onClick={() => navigate('/')} sx={{ padding: 0, position: 'absolute', top: '1rem', left: '1rem', }}>
        <img src="images/button-back.png" style={{ width: 40, height: 40 }} />
      </ButtonBase>
      <FlexBetween gap={1} sx={{ background: "#000", height: 40, padding: '5px', width: 'fit-content' }}>
        <PrimaryButton onClick={() => handleChangeTabIndex(0)} className={tabIndex === 0 ? '' : 'non-active'} sx={{ background: theme.palette.primary.main, height: '100%' }}>
          My Warriors
        </PrimaryButton>
        <PrimaryButton onClick={() => handleChangeTabIndex(1)} className={tabIndex === 1 ? '' : 'non-active'} sx={{ background: theme.palette.primary.main, height: '100%' }}>
          My Chests
        </PrimaryButton>
      </FlexBetween>
      <Stack sx={{ width: 'calc(100% + 1rem)' }}>
        {
          tabIndex === 0 ?
            <Stack sx={{ width: '100%', height: 'calc(100vh - 65px)', overflowY: 'scroll' }}>
              <InfiniteScroll
                pageStart={1}
                loadMore={(page) => handleGetData()}
                hasMore={hasMore}
                initialLoad={false}
                loader={(isLoadingMore && hasMore) === true && <Typography textAlign={'center'}>Loading...</Typography>}
                useWindow={false}
              >
                {
                  <Grid container my={2}>
                    {
                      data?.data?.map((char, index) => <Grid key={`nft-${index}`} p={1} item xs={6} >
                        <Stack sx={{ background: "#000", width: '100%', p: 2 }}>
                          <Stack alignItems={'center'} sx={{ position: 'relative', height: '100%', width: '100%', border: `4px solid ${RarityColors[char?.rarity || 'C']}`, padding: '3rem 0px' }}>
                            <Typography variant="h4" sx={{ color: "#fff", position: 'absolute', top: '10px', left: '10px' }}>{`Lv.${char.level}`}</Typography>
                            <Typography color={RarityColors[char?.rarity || 'C']} variant="h3" sx={{ position: 'absolute', top: '35px', left: '10px' }}>{char?.rarity}</Typography>
                            <FlexRowAlign gap={0.5} sx={{ color: "#fff", position: 'absolute', top: '10px', right: '10px' }}>
                              <img src='images/sword.png' style={{ height: 15 }} />
                              <Typography variant="h4" >{char?.stats}</Typography>
                            </FlexRowAlign>
                            <img src={`images/gifs/${char?.image}/stand.gif`} style={{ height: 70, width: 'auto' }} />
                            <Typography variant="h5" sx={{ color: "#fff", opacity: 0.6, position: 'absolute', bottom: '10px', left: '10px' }}>ID: {char.id}</Typography>
                          </Stack>
                          <Typography variant="h4" color={"#fff"} textAlign={'center'} py={1}> {char?.hashRate} $TRT/H</Typography>
                          <FlexBetween gap={1}>
                            {/* <PrimaryButton sx={{ background: '#2B9DFF', width: '100%', height: 30 }}>Battle</PrimaryButton> */}
                            <UpgradeForm char={char} id={char?.id} fetchUserNfts={fetchData} />
                          </FlexBetween>
                        </Stack>
                      </Grid>)
                    }
                  </Grid>
                }
              </InfiniteScroll>
            </Stack>
            :
            <>
              <Stack sx={{ width: '100%', height: 'calc(100vh - 65px)', overflowY: 'scroll' }}>
                <InfiniteScroll
                  pageStart={1}
                  loadMore={(page) => handleGetData()}
                  hasMore={hasMore}
                  initialLoad={false}
                  loader={(isLoadingMore && hasMore) === true && <Typography textAlign={'center'}>Loading...</Typography>}
                  useWindow={false}
                >
                  {
                    <Grid container my={2}>
                      {
                        data?.data?.map((char, index) => <Grid key={`chest-${index}`} p={1} py={2} item xs={6} >
                          <Stack alignItems={'center'} sx={{ position: 'relative', height: '100%', width: '100%', }}>
                            <img src='images/box-bg-3.png' style={{ width: '90%', }} />
                            <Stack spacing={1} alignItems={'center'} justifyContent={'center'} sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                              <img src={char.image} style={{ width: '65%' }} />
                              <PrimaryButton loading={isLoading} onClick={() => handleOpenChest(char)} sx={{ background: '#2B9DFF', width: '55%', height: 30 }}>Open</PrimaryButton>
                            </Stack>
                          </Stack>
                        </Grid>)
                      }
                    </Grid>
                  }
                </InfiniteScroll>
              </Stack>
              <Grid item xs={12} pt={2}>
                <ButtonBase onClick={() => navigate('/boost')} sx={{ background: 'url("images/button.png") 50% 50% no-repeat', backgroundSize: 'contain', height: 40, width: '100%', fontSize: '2rem' }}>
                  Buy more chest
                </ButtonBase>
              </Grid>
            </>
        }
      </Stack>
      <GiftOpened nft={warriorOpened} onClaim={() => {
        setWarriorOpened(null)
        setTabIndex(0)
      }}
        onContinue={() => {
          setWarriorOpened(null)
          setTabIndex(1)
        }} />
    </BoxContainer>
  );
};

export const StyledButton = styled(Button)(({ theme }) => ({
  border: `1px solid #B7B7B7`,
  background: theme.palette.background.default,
  borderRadius: "0.8rem",
  position: "relative",
  flexDirection: "column",
  padding: 0,
  img: {
    filter: "saturate(0)",
  },
  "&.active": {
    borderColor: "#FAFF00",
    boxShadow:
      "inset 0px 4px 0px rgba(255,255,255,0.25), 2px 4px 0px rgba(0,0,0)",
    img: {
      filter: "saturate(1)",
    },
  },
}));

export const StyledInput = styled(OutlinedInput)(({ theme }) => ({
  margin: "1.5rem 0px",
  background: "#000",
  borderRadius: "0.5rem",
  input: {
    textAlign: "center",
    color: "white",
    fontWeight: 600,
  },
  "& fieldset": {
    border: `1px solid #000!important`,
  },
}));

const PriceBox = styled(Stack)(({ theme }) => ({
  background: "#FFF4CE",
  borderRadius: "0.5rem",
  padding: "0.5rem",
  justifyContent: "center",
  alignItems: "center",
  minHeight: 64,
}));

const DailyBox = styled(Stack)(({ theme }) => ({
  background: "#FFF06C",
  borderRadius: "0.5rem",
  padding: "0.5rem 1rem",
  minHeight: 40,
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));

const PayBox = styled(Stack)(({ theme }) => ({
  background: "#FFFFFF",
  borderRadius: "0.5rem",
  padding: "0.5rem 1rem",
  minHeight: 40,
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow:
    "inset 0px 4px 0px rgba(255,255,255,0.25), 2px 4px 0px rgba(0,0,0)",
}));

const UpgradeBox = styled(Stack)(({ theme }) => ({
  maxHeight: "calc(100vh - 290px)",
  overflow: 'scroll',
  background: '#FFF3E8',
  borderTop: '5px solid #DDC5BC',
  padding: '1.5rem',
}));

const ShopBox = styled(Stack)(({ theme }) => ({
  maxHeight: "calc(100vh - 290px)",
  overflow: 'scroll',
  background: '#FFF3E8',
  borderTop: '5px solid #DDC5BC',
  padding: '1.5rem',
}));

const ShopItem = styled(FlexBetween)(({ theme }) => ({
  border: '2px solid #000',
  boxShadow: '3px 4px #000',
  borderRadius: '0.7rem',
  minHeight: 80,
  background: '#FFA863',
  padding: '0px',
  paddingLeft: '10px',
  overflow: 'hidden',
  '& .image': {
    background: '#D0775B',
    width: 70,
    height: 60,
    borderRadius: '0.6rem',
    boxShadow: 'inset 0px 7px 0px rgba(0,0,0,0.1)'
  },
  '& .name-card': {
    height: 60,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    border: '1px solid #AA7042',
    borderRadius: '0.6rem',
    '& .name': {
      fontFamily: '"Rubik", sans-serif !important',
      fontWeight: 900,
      fontSize: '1.2rem',
      textTransform: 'uppercase',
      WebkitTextFillColor: "#fff",
      WebkitTextStroke: "1px #77483F",
      paintOrder: "stroke fill",
      textShadow: '0px 2px rgba(0,0,0,0.4) !important',

      '&:before,&:after': {
        content: '""',
        position: 'absolute',
        width: "6px",
        height: '6px',
        borderRadius: '6px',
        background: "rgba(0,0,0,0.4)",
        top: '6px',
        left: '6px'
      },
      '&:after': {
        right: '6px',
        left: 'unset'
      }
    },
    '& .description': {
      fontWeight: 500,
      '&:before,&:after': {
        content: '""',
        position: 'absolute',
        width: "6px",
        height: '6px',
        borderRadius: '6px',
        background: "rgba(0,0,0,0.4)",
        bottom: '6px',
        left: '6px'
      },
      '&:after': {
        right: '6px',
        left: 'unset'
      }
    }
  },
  '& .price-card': {
    width: '100px',
    height: 80,
    borderLeft: '1px solid #000',
    background: '#FFFFFF'
  }
}));