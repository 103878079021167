import {
  ButtonBase,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FlexRowAlign } from "components/flexbox";
import { useCallback, useEffect, useState } from "react";
import { BoxContainer } from "./styled";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';
import { RarityColors } from "../../utils/constants";
import useWarzoneLogs from "../../hooks/useWarzoneLogs";
import useAxios from "../../hooks/useAxios";
import useSettings from "../../hooks/useSettings";

export default function Warzone() {
  const theme = useTheme();
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [ warzoneLogs, setWarzoneLogs] = useState({hasMore:true,data:[],total:0})
  const { settings } = useSettings();
  const handleGetData = (page) => {
    setPage(page + 1)
  }
  const { callApi } =useAxios()

  const fetchWarzoneLogs = useCallback(async () => {
    if (!settings.token) return;
    try {
        const results = await callApi('get', `/pvp/warzone-logs?page=${page}&limit=${20}`)
        if(results && results?.data?.length > 0)
        setWarzoneLogs((logs)=> {return { hasMore: results.total> page * 20 ,total: results.total, data:[...logs.data,...results.data]}})
    } catch (e) {
        console.log('ignoew')
    }
  }, [callApi, page, settings.token])  
  useEffect(() => {
      fetchWarzoneLogs()
  }, [
      fetchWarzoneLogs, settings.token, page
  ])

  return (
    <BoxContainer sx={{ alignItems: 'center' }}>
      <ButtonBase onClick={() => navigate(-1)} sx={{ padding: 0, position: 'absolute', top: '1rem', left: '1rem', }}>
        <img src="images/button-back.png" style={{ width: 40, height: 40 }} />
      </ButtonBase>
      <Typography lineHeight={2} variant="h3">Warzone</Typography>
      <Stack width={'100%'} height={'100%'}>
        <Stack sx={{ position: 'relative', width: 'fit-content', margin: '0px auto -3px' }}>
          <img src="/images/box-bg-7.png" style={{ height: 32, }} />
        </Stack>
        <Stack px={2} maxHeight={'calc(100vh - 100px)'} spacing={0.5} py={1} sx={{ border: '3px solid #53536C', width: '100%', minHeight: 130, background: theme.palette.background.default, overflowY: 'scroll' }}>

          <InfiniteScroll
            pageStart={0}
            loadMore={(page) => handleGetData(page)}
            hasMore={warzoneLogs.hasMore}
            initialLoad={false}
            loader={<Typography textAlign={'center'}>Loading...</Typography>}
            useWindow={false}
          >
            {
              warzoneLogs?.data?.map((item, index) => <Log key={index} data={item} />)
            }
          </InfiniteScroll>
        </Stack>
      </Stack>
    </BoxContainer>
  );
}

const Log = ({ data, disableIcon }) => {
  return <FlexRowAlign justifyContent="flex-start" gap={1} alignItems='flex-start'>
      {
          !disableIcon && <img src='images/sword.png' style={{ height: 15 }} />
      }
      <Typography>
          <span style={{ color: `${RarityColors[data?.sourceRarity]}` }}>{data?.winerUserName ?? 'hidden'}</span>
          <span> defeat </span>
          <span style={{ color: `${RarityColors[data?.targetRarity || 'C']}` }}> {data?.loserUserName ?? 'hidden'}</span>
          <span> earned </span>
          <span style={data?.token === 'TON' ? { color: '#1E90FF' } : { color: '#d35400' }}> ${data?.amount} {data?.token}</span>
          <span style={{ color: '#FFC809' }}> Winner/loser received {data.winnerGold}/{data.loserGold} GOLD</span>
      </Typography>
  </FlexRowAlign>
}

