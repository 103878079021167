export const THEMES = {
  LIGHT: "light",
  DARK: "dark",
};
export const isDark = (theme) => theme.palette.mode === "dark";

// FOR LAYOUT 2 SECONDARY SIDEBAR
export const secondarySideBarGap = 80;
export const secondarySideBarWidth = 215;

export const RarityColors = {
  C: "#9AAFC5",
  UR: "#DEA54D",
  SR: "#A54DDD",
  UL: "#DC4D55",
  R: "#6860FF",
};
