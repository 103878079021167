import { Outlet, useSearchParams } from "react-router-dom";
// CUSTOM COMPONENTS
import LayoutSideBar from "./components/DashboardSidebar";
import LayoutBodyWrapper from "./components/LayoutBodyWrapper";
// DASHBOARD LAYOUT BASED CONTEXT PROVIDER
import LayoutProvider from "./context/layoutContext";
import DashboardFooter from "./components/DashboardFooter";
import useSettings from "../../hooks/useSettings";
import useAxios from "../../hooks/useAxios";
import axios from 'axios'
import { useCallback, useEffect, useState } from "react";
import { Box, ButtonBase, Stack, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import FlexBetween from "../../components/flexbox/FlexBetween";
import Iconify from "../../components/Iconify";
import { useTelegram } from "contexts/TelegramContext";
import { PrimaryButton, WheatBox } from "../../pages/mobile/styled";
import MainPromo from "../../page-sections/promotion/MainPromo";

export default function MobileLayout({ children }) {
  const theme = useTheme();
  const { settings, saveSettings } = useSettings();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, webApp } = useTelegram();
  const [isJoinChanel, setIsJoinChanel] = useState(true)
  const [verifyStatus, setVerifyStatus] = useState('NONE') //NONE | VERIFYING | VERIFIED
  const { callApi } = useAxios()
  const isLocalhost = () => {
    const hostname = window.location.hostname;
    return hostname === 'localhost' || hostname === '127.0.0.1';
  };
  const isMobile = isLocalhost() || webApp?.platform === "tdesktop" || webApp?.platform === "macos" || webApp?.platform === "windows" || webApp?.platform === "ios" || webApp?.platform === "android";
  // const isMobile = true;

  const app = window.Telegram?.WebApp;
  const openTelegramLink = app?.openTelegramLink;



  useEffect(() => {
    const temp = searchParams.get("token");
    if (settings?.token !== temp && !!temp && temp?.length > 0) {
      saveSettings({ ...settings, token: temp });
    }
  }, [settings]);

  const handleOpenChanel = async () => {
    await openTelegramLink('https://t.me/Ton_Realm_Channel')
    setVerifyStatus('VERIFYING')
  }
  const fetchIsJoinChanel = useCallback(async () => {
    try {
      const data = await callApi('get', '/user/is-join-chanel')
      setIsJoinChanel(true)
    } catch (e) {
      console.log("ignore")
    }
  }, [settings?.token])

  const handleVerify = async () => {
    try {
      const data = await callApi('get', '/user/is-join-chanel')
      if (data === true) {
        setVerifyStatus('VERIFIED')
      }
    } catch (e) {
      console.log("ignore")
    }
  }

  useEffect(() => {
    fetchIsJoinChanel()
  }, [
    fetchIsJoinChanel])


  return (
    <LayoutProvider>
      <LayoutBodyWrapper>
        {isMobile ? (
          <>
            {
              isJoinChanel === false ? <>
                <Stack
                  spacing={2}
                  sx={{ background: 'url("/images/join-chanel-bg.png") no-repeat', backgroundSize: 'cover' }}
                  justifyContent={'center'} alignItems={'center'} height={'100vh'} width={'100%'}>
                  {
                    verifyStatus !== "VERIFIED" && <>
                      <Typography variant="h3">Join Ton Realm Channel</Typography>
                      <Typography color={'red'} maxWidth={230} textAlign={'center'}>Please join the Ton Realm channel before starting the game</Typography>
                    </>
                  }
                  {
                    verifyStatus === "NONE" && <PrimaryButton onClick={handleOpenChanel} sx={{ height: 30, px: 2, fontSize: '1.3rem', background: theme.palette.primary.main }}>Join Now!</PrimaryButton>
                  }
                  {
                    verifyStatus === "VERIFYING" && <PrimaryButton onClick={handleVerify} sx={{ height: 30, px: 2, fontSize: '1.3rem', background: '#37FFB7', color: "#000" }}>Verify</PrimaryButton>
                  }
                  {
                    verifyStatus === "VERIFIED" && <ButtonBase onClick={setIsJoinChanel(true)} sx={{ background: 'url("images/start-btn-bg.png") 50% 50% no-repeat', backgroundSize: 'contain', height: 60, width: 100, fontSize: '1.5rem' }}>
                      START NOW
                    </ButtonBase>
                  }
                </Stack>
              </> : <>
                <Box>{children || <Outlet />}</Box>
                <DashboardFooter />
              </>
            }
          </>
        ) : (
          <>
            <Stack
              sx={{
                backgroundImage: "url(/images/home-bg-1.svg)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "top center",
              }}
              alignItems={"center"}
              justifyContent={"flex-end"}
              height={"100vh"}>
              <WheatBox mb={"20vh"}>
                <Typography fontWeight={600} variant='h6'>
                  {t("Please login on your mobile device")}
                </Typography>
                <FlexBetween mt={"1rem"} gap={2} justifyContent={"center"}>
                  <FlexBetween gap={1}>
                    <Iconify
                      icon={"token:ton"}
                      sx={{
                        background: theme.palette.primary.main,
                        color: "#fff",
                        borderRadius: 50,
                        padding: "3px 3px 1px",
                      }}
                      size={20}
                    />
                    <Typography fontWeight={600} variant='h6'>
                      TON
                    </Typography>
                  </FlexBetween>
                  <FlexBetween gap={1}>
                    <Iconify
                      icon={"token:ton"}
                      sx={{
                        background: "#000",
                        color: "#fff",
                        borderRadius: 50,
                        padding: "3px 3px 1px",
                        transform: "rotate(-180deg)",
                      }}
                      size={20}
                    />
                    <Typography fontWeight={600} variant='h6'>
                      NOT
                    </Typography>
                  </FlexBetween>
                </FlexBetween>
              </WheatBox>
            </Stack>
          </>
        )}
        <MainPromo open={true} />
      </LayoutBodyWrapper>
    </LayoutProvider>
  );
}
