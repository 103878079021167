import {
  Box,
  ButtonBase,
  Card,
  Grid,
  IconButton,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import AppTable from "components/AppTable";
import Iconify from "components/Iconify";
import { FlexBetween, FlexRowAlign } from "components/flexbox";
import { t } from "i18next";
import CheckedIcon from "icons/CheckedIcon";
import CopyIcon from "icons/CopyIcon";
import ShareIcon from "icons/ShareIcon";
import TNXIcon from "icons/TNXIcon";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import useUserData from "../../hooks/useUserData";
import useUserEarned from "../../hooks/useUserEarned";

import { BrownBox, PrimaryButton, TitleBox, TypographyOutline, WheatBox } from "./styled";
import { BoxTitle } from "../../components/BoxTitle";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { fCurrency, shortenNumber } from "../../utils/format";

export default function Referral() {
  const theme = useTheme();
  const navigate = useNavigate()
  const handleCopy = (text) => {
    toast.success(t("Copied"));
  };


  const openTelegramLink = window.Telegram?.WebApp?.openTelegramLink;



  const { userEarned, fetchUserEarned} = useUserEarned()
  const handleShare = async (url) => {
    openTelegramLink(`https://t.me/share/url?url=${url}&text=${`Welcome to TON Realm! Fully on-chain gaming on TON`}
    `)
  }
  const { userData } = useUserData();

  const quests = [
    {
      title: t("Each friend is invited"),
      bonuses: [
        {
          bonus: 500,
          symbol: "CAT",
          color: theme.palette.text.primary,
        },
        // {
        //   bonus: 1000,
        //   symbol: "CAT",
        //   color: theme.palette.text.primary,
        // },
      ],
      status: "PENDING",
    },
    {
      title: t("Each cat is bought by friends."),
      bonuses: [
        {
          bonus: 100,
          symbol: "NOT",
          color: theme.palette.text.primary,
        },
        // {
        //   bonus: 1000,
        //   symbol: "CAT",
        //   color: theme.palette.text.primary,
        // },
      ],
      status: "PENDING",
    },
    // {
    //   title: t("Each mission is completed by a friend"),
    //   bonuses: [
    //     {
    //       bonus: 20,
    //       symbol: "NOT",
    //       color: theme.palette.text.primary,
    //     },
    //     // {
    //     //   bonus: 1000,
    //     //   symbol: "CAT",
    //     //   color: theme.palette.text.primary,
    //     // },
    //   ],
    //   status: "PENDING",
    // },
  ];

  const refCode = !!userData?.user?.refCode ? `${userData?.user?.refCode}` : ""
  return (
    <QuestContainer>
      <ButtonBase onClick={() => navigate(-1)} sx={{ padding: 0, position: 'absolute', top: '1rem', left: '1rem', }}>
        <img src="images/button-back.png" style={{ width: 40, height: 40 }} />
      </ButtonBase>
      <Stack pt={8} spacing={4} alignItems={'center'}>
        <Grid container>
          <Grid item xs={6} pr={0.5}>
            <Stack p={2} spacing={1} sx={{ width: '100%', border: '2px solid #054B76' }}>
              <Typography variant="h5" sx={{ opacity: 0.5 }}>Friends invited</Typography>
              <Typography variant="h3" >{userData?.countRef}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={6} pl={0.5}>
            <Stack p={2} spacing={1} sx={{ width: '100%', border: '2px solid #054B76' }}>
              <Typography variant="h5" sx={{ opacity: 0.5 }}>Points earned</Typography>
              <FlexRowAlign mt={2} sx={{ width: '100%' }} gap={1} justifyContent='flex-start'>
                <img src="images/POINT.png" style={{ width: 20, height: 20 }} />
                <Typography variant="h3">{shortenNumber(userEarned?.point,2)}</Typography>
              </FlexRowAlign>
            </Stack>
          </Grid>
        </Grid>
        <Stack px={2}>
          <ul>
            <li>
              <Typography variant="h6">You earn 10% of all points earned by referrals</Typography>
            </li>
            <li>
              <Typography variant="h6">Invited friends will receive a gift of 100 points.</Typography>
            </li>
          </ul>
        </Stack>
        <FlexRowAlign gap={1.5} pt={5}>
          <img src="images/TON.png" style={{ width: 30, height: 30 }} />
          <Typography variant="h2">{fCurrency(userEarned?.ton,2)}</Typography>
        </FlexRowAlign>
        <Typography variant="h5" maxWidth={'70%'} textAlign={'center'}>Earn 5% TON cashback whenever your friends buy chests.</Typography>
        {/* <Stack width={250} height={250} alignItems={'center'} justifyContent={'center'} sx={{ position: 'relative' }}>
          <img src="images/box-bg-6.png" style={{ width: '100%', aspectRatio: 1 }} />
          <QRCode value={`https://t.me/Ton_Realm_Bot${refCode}`} style={{ position: 'absolute', width: 150, height: 150 }} />
        </Stack> */}
        <FlexBetween gap={1} sx={{ width: '100%', position: 'fixed', bottom: 0, p: 2 }}>
          <PrimaryButton sx={{ height: 40, background: theme.palette.primary.main, width: '100%' }}>
            <FlexRowAlign gap={2}>
              <Typography variant="h4">Invite a friend</Typography>
            </FlexRowAlign>
          </PrimaryButton>
          <CopyToClipboard
            text={`https://t.me/Ton_Realm_Bot/start?startapp=${refCode}`}
            onCopy={() => handleCopy()}>
            <PrimaryButton sx={{ height: 40, minWidth: 40, background: '#FFC32B' }}>
              <img src="images/copy-icon.png" width={20} />
            </PrimaryButton>
          </CopyToClipboard>

          <PrimaryButton onClick={()=>handleShare(`https://t.me/Ton_Realm_Bot/start?startapp=${refCode}`)} sx={{ height: 40, minWidth: 40, background: '#2CFFB2' }}>
            <img src="images/share-icon.png" width={27} />
          </PrimaryButton>
        </FlexBetween>
        {/* <BrownBox>
          <TypographyOutline>Your Referral Link</TypographyOutline>
          <ReferralBox>
            <FlexBetween gap={0.5}>
              <Stack p={1} width={"100%"}>
                <Typography color={"text.secondary"}>
                  {t("Invite link")}
                </Typography>
                <Typography maxWidth={200} noWrap>
                  {`https://t.me/Cattie_Miner_bot${refCode}`}
                </Typography>
              </Stack>
              <CopyToClipboard
                text={`https://t.me/Cattie_Miner_bot${refCode}`}
                onCopy={() => handleCopy()}>
                <Box component={"img"} src='/images/icon-copy.png' sx={{ width: 40, height: 30 }} />
              </CopyToClipboard>
              <IconButton>
                <Box onClick={() => handleShare(`https://t.me/Cattie_Miner_bot${refCode}`)} component={"img"} sx={{ width: 30, height: 30 }} src='/images/icon-share.png' />
              </IconButton>
            </FlexBetween>
          </ReferralBox>

          <Typography color={"white"}>
            {t(
              "The more friends you invite, the more rewards you earn. Earning more rewards when your friends buy cats or complete tasks.",
            )}
          </Typography>
        </BrownBox> */}
        {/* <InvitedList /> */}
      </Stack>
    </QuestContainer>
  );
}

const InvitedList = () => {
  const theme = useTheme();
  const data = [
    {
      id: 1,
      userName: "Namaste1",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 2,
      userName: "Namaste2",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 3,
      userName: "Namaste3",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 4,
      userName: "Namaste4",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 5,
      userName: "Namaste5",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 6,
      userName: "Namaste6",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 7,
      userName: "Namaste7",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 8,
      userName: "Namaste8",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 9,
      userName: "Namaste9",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 10,
      userName: "Namaste10",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 11,
      userName: "Namaste11",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 12,
      userName: "Namaste12",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 13,
      userName: "Namaste13",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 14,
      userName: "Namaste14",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
  ];
  const rowHeight = 40;
  const defaultHeader = [
    {
      field: "userName",
      flex: 1,
      minWidth: 120,
      headerName: t("Name"),
      renderCell: ({ row }) => <Typography noWrap>{row.userName}</Typography>,
    },
    {
      field: "type",
      flex: 1,
      minWidth: 120,
      headerName: t("Type"),
      renderCell: ({ row }) => <Typography noWrap>{row.userName}</Typography>,
    },
    {
      field: "bonuses",
      align: "right",
      headerAlign: "right",
      flex: 1,
      minWidth: 120,
      headerName: t("Bonus"),
      renderCell: ({ row }) => (
        <FlexBetween gap={2}>
          {row.bonuses.map((bonus) => (
            <Typography sx={{ color: bonus.color }}>+{bonus.bonus}</Typography>
          ))}
        </FlexBetween>
      ),
    },
  ];
  return (
    <Stack>
      <Typography mb={2} variant='h5' fontWeight={700}>
        {t("Invited")} {data?.length}
      </Typography>
      <AppTable
        columns={defaultHeader}
        data={data}
        rowHeight={rowHeight}
        rowSpacing={false}
        defaultPageSize={10}
        sx={{
          "& .MuiDataGrid-cell": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            border: "none",
            borderBottom: `1px solid ${theme.palette.divider} !important`,
            padding: "0px 0.7rem !important",
          },
          "& .MuiDataGrid-topContainer": {
            "&::after": {
              display: "none !important",
            },
          },
          "& .MuiDataGrid-columnHeaders": {
            borderRadius: "0.5rem !important",
            overflow: "hidden",
          },
          "& .MuiDataGrid-columnHeader": {
            color: theme.palette.text.secondary,
            background: theme.palette.background.paper,
            height: "45px !important",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
        }}
      />
    </Stack>
  );
};

const QuestContainer = styled(Stack)(({ theme }) => ({
  margin: "auto",
  minHeight: "calc(100vh - 114px)",
  padding: "1rem",
}));
const ReferralBox = styled(Box)(({ theme }) => ({
  borderRadius: "1rem",
  background: "#FFDA92",
  border: `1px solid #000`,
  boxShadow: " 2px 4px 0px rgba(0,0,0)",
  marginBottom: "1rem",
  marginTop: "0.5rem",
}));
