import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import { t } from "i18next";
import { navigation } from "../context/navigation";
import useLayout from "../context/useLayout";
import { useLocation } from "react-router-dom";
export default function DashboardFooter() {
  const { active, handleActiveMainMenu } = useLayout();
  const { pathname } = useLocation()
  return (
    <DashboardFooterRoot sx={{ transform: `translateY(${pathname === '/' ? 0 : 150}px)`, transition: 'all 0.2s ease-in-out' }}>
      <StyledToolBar >
        <Container maxWidth={"xs"} sx={{ zindex:`0` }}>
          <Grid container>
            {navigation.map((nav, index) =>
              <Grid item xs={3} key={index}>
                <StyledButton
                  className={active === nav.name ? "active" : ""}
                  onClick={handleActiveMainMenu(nav)}>
                  <Box
                    component={"img"}
                    src={nav.iconImage}
                    alt={nav.name}
                    sx={{
                      width: 80,
                      height: 80,
                    }}
                  />
                  <Typography variant="h5" sx={{ position: 'absolute', bottom: 10 }} >{t(nav.name)}</Typography>
                </StyledButton>
              </Grid>
            )}
          </Grid>
        </Container>
      </StyledToolBar>
    </DashboardFooterRoot>
  );
}

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  flexDirection: "column",
  background: "none !important",
  height: 80,
  opacity: 0.9,
  position: 'relative',
  "& .MuiTypography-root": {
    fontWeight: 900,
    WebkitTextFillColor: "white",
    WebkitTextStroke: "1px black",
    paintOrder: "stroke fill",
  },
  "&:hover, &.active": {
    opacity: 1,
    "& .MuiTypography-root": {
      WebkitTextFillColor: "white",
    },
  },
}));

const DashboardFooterRoot = styled(AppBar)(({ theme }) => ({
  position: "fixed",
  top: 'unset',
  bottom: 0,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "top center",
  backgroundColor: "transparent",
  boxShadow: "none",
  minHeight: 100,
}));

const StyledToolBar = styled(Toolbar)(({ theme }) => ({
  bottom: 0,
  borderRadius: "0rem",
  padding: "0px 1rem 10px !important",
  height: 100,
}));
