import { useTelegram } from "contexts/TelegramContext";
import {
  useTonWallet,
  useTonConnectUI
} from "@tonconnect/ui-react";
import { LoadingButton } from "@mui/lab";
import CheckedIcon from "icons/CheckedIcon";

import {
  Box,
  Button,
  ButtonBase,
  Grid,
  OutlinedInput,
  Stack, StepConnector, Typography,
  styled,
  useTheme
} from "@mui/material";
import { FlexBetween, FlexRowAlign } from "components/flexbox";
import Decimal from "decimal.js";
import { t } from "i18next";
import TNXIcon from "icons/TNXIcon";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import useSettings from "../../hooks/useSettings";
import useAxios from "../../hooks/useAxios";
import useUserData from "../../hooks/useUserData";
import useUserDailyReward from "../../hooks/useUserDailyReward";

import useItems from "../../hooks/useItems";

import { BoxContainer, PrimaryButton } from "./styled";
import { useNavigate } from "react-router-dom";
import useUserQuest from "../../hooks/useUserQuest";
import ConvertForm from "../../page-sections/homepage/ConvertForm";
import PointForm from "../../page-sections/homepage/PointForm";
// global.Buffer = Buffer;

const data = [
  {
    name: "Worker",
    speed: 10,
    period: 30,
    price: 1000000,
    dailyProfit: 21600,
    totalProfit: 648000,
    paid: 1000000,
    symbol: "CAT",
    bonus: 5000,
    bonusSymbol: "CAT",
    bonusSymbolIcon: <TNXIcon size={18} />,
  },
  {
    name: "Boss",
    speed: 100,
    period: 30,
    price: 10000000,
    dailyProfit: 216000,
    totalProfit: 6480000,
    paid: 10000000,
    symbol: "CAT",
    bonus: 50000,
    bonusSymbol: "CAT",
    bonusSymbolIcon: <TNXIcon size={18} />,
  },
  {
    name: "Moon Cat",
    speed: 1000,
    period: 30,
    price: 100000000,
    dailyProfit: 2160000,
    totalProfit: 64800000,
    paid: 100000000,
    symbol: "CAT",
    bonus: 500000,
    bonusSymbol: "CAT",
    bonusSymbolIcon: <TNXIcon size={18} />,
  },
];

export const Mission = () => {
  const theme = useTheme();
  const navigate = useNavigate()
  const { userData, fetchUserData } = useUserData();
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <BoxContainer sx={{ alignItems: 'center' }}>
      <ButtonBase onClick={() => navigate(-1)} sx={{ padding: 0, position: 'absolute', top: '1rem', left: '1rem', }}>
        <img src="images/button-back.png" style={{ width: 40, height: 40 }} />
      </ButtonBase>
      <FlexBetween mb={2} gap={1} sx={{ background: "#000", height: 40, padding: '5px', width: 'fit-content' }}>
        <PrimaryButton onClick={() => setTabIndex(0)} className={tabIndex === 0 ? '' : 'non-active'} sx={{ background: theme.palette.primary.main, height: '100%', width: 100 }}>
          Missions
        </PrimaryButton>
        <PrimaryButton onClick={() => setTabIndex(1)} className={tabIndex === 1 ? '' : 'non-active'} sx={{ background: theme.palette.primary.main, height: '100%', width: 100 }}>
          Streak Bonus
        </PrimaryButton>
      </FlexBetween>
      <PointForm
        catBalance={0}
        userData={userData}
        tokenInfo={{ symbol: "POINT", min: 1000 }}
        available={
          userData?.userBalances.find(
            (balance) => balance.token === "POINT",
          )?.balance ?? 0
        }
        tonInfo={{
          symbol: "TRT",
          min: 0.02,
          available:
            userData?.userBalances.find(
              (balance) => balance.token === "TRT",
            )?.balance ?? 0,
        }}
        onChange={() => fetchUserData()}
        startTime={'2024-10-30 11:16:00'}
        endTime={'2024-11-30 11:28:00'}
      />
      <Stack mt={2} sx={{ width: '100%', height: 'calc(100vh - 90px)', overflowY: 'scroll' }}>
        {
          tabIndex === 0 ? <Stack spacing={2}>
            <MissionList fetchUserData={fetchUserData} />
          </Stack> : <Stack spacing={2}>
            <StreakList fetchUserData={fetchUserData} />
          </Stack>
        }
      </Stack>
    </BoxContainer>
  );
};

const MissionList = ({fetchUserData}) => {
  const { userQuest,fetchUserQuest } = useUserQuest();
  const { userData } = useUserData();

  const [isDoing, setIsDoing] = useState({});
  const [isVerifying, setIsVerifying] = useState({});
  const quests = userQuest ?? [];
  const connectQuest = quests.find((q) => q.id == 1);

  useEffect(() => {
    if (!userQuest) return;
    const isDoingTemp = {};
    const isVerifyingTemp = {};

    quests.forEach((_, index) => {
      isDoingTemp[index] = false;
      isVerifyingTemp[index] = false;
    });
    setIsDoing(isDoingTemp);
    setIsVerifying(isVerifyingTemp);
  }, [userQuest]);

  const MissionOnX = quests.filter(item => item?.hyperLink?.includes('x.com'))
  const MissionOnTelegram = quests.filter(item => item?.hyperLink?.includes('t.me') && item?.questType !== 'DAILY')
  const DailyMission = quests.filter(item => item?.questType === 'DAILY' && !item?.hyperLink?.includes('x.com') && !item?.hyperLink?.includes('t.me'))
  const BaseMission = quests.filter(item => item.hyperLink && !item?.hyperLink?.includes('t.me') && !item?.hyperLink?.includes('x.com') && !item?.questType !== 'DAILY')
  return <Stack spacing={2}>
    <CustomBox>
      <FlexRowAlign gap={2} justifyContent={'flex-start'}>
        <img src="images/task-icon.png" width={20} />
        <Typography variant="h5">Daily Tasks</Typography>
      </FlexRowAlign>
      <Stack mt={1}>
        {
          DailyMission.map(item => <MissionItem fetchUserData={fetchUserData} fetchUserQuest={fetchUserQuest} key={item.name} data={item} icon={<img src='images/mission-icon-2.png' width={40} height={40} style={{ objectFit: 'contain' }} />} />)
        }
      </Stack>
    </CustomBox>
    <CustomBox>
      <FlexRowAlign gap={2} justifyContent={'flex-start'}>
        <img src="images/task-icon.png" width={20} />
        <Typography variant="h5">Social Tasks</Typography>
      </FlexRowAlign>
      <Stack mt={1} spacing={1}>
        {
          MissionOnX.map(item => <MissionItem fetchUserData={fetchUserData} fetchUserQuest={fetchUserQuest} key={item.name} data={item} icon={<img src='images/twitter-icon.png' width={40} height={25} style={{ objectFit: 'contain' }} />} />)
        }
        {
          MissionOnTelegram.map(item => <MissionItem fetchUserData={fetchUserData} fetchUserQuest={fetchUserQuest} key={item.name} data={item} icon={<img src='images/telegram-icon.jpg' width={40} height={25} style={{ objectFit: 'contain' }} />} />)
        }
      </Stack>
    </CustomBox>
    <CustomBox>
      <FlexRowAlign gap={2} justifyContent={'flex-start'}>
        <img src="images/task-icon.png" width={20} />
        <Typography variant="h5">Other Tasks</Typography>
      </FlexRowAlign>
      <Stack mt={1}>
        {
          BaseMission.map(item => <MissionItem fetchUserData={fetchUserData} fetchUserQuest={fetchUserQuest} key={item.name} data={item} icon={<img src='images/mission-icon-2.png' width={40} height={40} style={{ objectFit: 'contain' }} />} />)
        }
      </Stack>
    </CustomBox>
  </Stack>
}

const MissionItem = ({ data, icon, fetchUserQuest, fetchUserData }) => {
  const [isVerifying, setIsVerifying] = useState({});
  const theme = useTheme();

  const app = window.Telegram?.WebApp;
  const openTelegramLink =app?.openTelegramLink;
  const openLink =app?.openLink;
  const { callApi } = useAxios()
  const handleDoQuest = async (item) => {
    if (
      item.isDone === false &&
      !!item.hyperLink &&
      (!item.isDoing)
    ) {
      try {
        if(item.hyperLink.startsWith('https://t.me')){
          openTelegramLink(item.hyperLink)
        } else {
          // @ts-ignore
          openLink(item.hyperLink,{try_instant_view:true});
        }
        await callApi("post", "/user/do-quest", {
          questId: item.id,
        });
        await fetchUserQuest();
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(`Error: ${error.response.data.message}`, {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          console.log("errr", error)
          toast.error("An unexpected error occurred.", {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  };
  const handleVerifyQuest = async (item, index) => {
    setIsVerifying((prevState) => ({
      ...prevState,
      [index]: true,
    }));
    try {
      await callApi("post", "/user/sucess-quest", {
        questId: item.id,
      });
      await fetchUserQuest();
      await fetchUserData();
      toast.success("🎉 Quest Completed", {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(`Error: ${error.response.data.message}`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("An unexpected error occurred.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setIsVerifying((prevState) => ({
        ...prevState,
        [index]: false,
      }));
    }
  };
  return <FlexBetween onClick={() => { handleDoQuest(data) }} gap={1} sx={{ position: 'relative', zIndex: 10 }} p={1.5}>
    <Stack sx={{ width: 35, height: 35, background: '#000' }} alignItems={'center'} justifyContent={'center'}>
      {icon}
    </Stack>
    <Stack>
      <Typography noWrap color={"#000"} variant="h5">{data?.name?.split('Daily task,')?.[1] || data?.name}</Typography>
      <FlexRowAlign gap={0.5} justifyContent="flex-start">
        <img src="/images/POINT.png" width={17} style={{ marginTop: '-2px' }} />
        <Typography noWrap color={"#000"} variant="h4">{data?.katAmount || 0} POINT</Typography>
      </FlexRowAlign>
    </Stack>
    <Box flexGrow={1} />
    <ButtonBase>
      {data.isDone === true ? (
        <FlexRowAlign gap={1}>
          <Typography noWrap color={"#000"} variant="h5">
            {t("Done")}
          </Typography>
          <CheckedIcon
            size={20}
            sx={{ color: "#000" }}
          />
        </FlexRowAlign>
      ) : (
        <>
          {!!data.hyperLink && data.isDoing == false ? (
            <img src="/images/play-icon.png" width={25} />
          ) : (
            <>
              {!!data.hyperLink && data.isDoing == true ? (
                <FlexRowAlign noWrap color={"#000"} gap={1}>
                  <LoadingButton
                    loading={isVerifying[data.id]}
                    onClick={() => handleVerifyQuest(data, data.id)}
                    fullWidth
                    variant='outlined'>
                    <Typography noWrap color={"#000"} variant="h5">{"Verify"}</Typography>
                  </LoadingButton>
                </FlexRowAlign>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </ButtonBase>
    <img src={'images/box-bg-4.png'} style={{ position: 'absolute', zIndex: -1, top: 0, left: 0, width: '100%', height: '100%' }} />
  </FlexBetween>
}

const StreakList = ({fetchUserData}) => {
  const steps = ['10 invited', '20 invited', '30 invited', '40 invited'];

  const { userDailyReward, fetchUserDailyReward } = useUserDailyReward()
  const { userData } = useUserData()

  const [isLoading, setIsLoading] = useState(false)
  const { callApi } = useAxios()
  const handleClaimReward = async (reward) => {
    if (!reward.isActive || reward.isDone) return
    if (isLoading) return;
    setIsLoading(true);
    try {
      const temp = await callApi('post', '/daily-reward/claim-daily-reward', {
        dailyRewardId: reward.id
      })
      toast.success(
        "🎉 Claim reward successful!",
        {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
      await fetchUserDailyReward()
      await fetchUserData()
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(`Error: ${error.response.data.message}`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("An unexpected error occurred.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };


  const handleClaimStreak = async (streak) => {
    if (!streak) return;
    setIsLoading(true);
    try {
      const temp = await callApi('post', '/user/claim-streak', {
        streak: streak
      })
      toast.success(
        "🎉 Claim reward successful!",
        {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
      await fetchUserData()
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(`Error: ${error.response.data.message}`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("An unexpected error occurred.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  let activeStep = null;

  if (userData?.countRef >= 40 && !userData?.user?.streak4) {
    activeStep = 4;
  } else if (userData?.countRef >= 30 && !userData?.user?.streak3) {
    activeStep = 3;
  } else if (userData?.countRef >= 20 && !userData?.user?.streak2) {
    activeStep = 2;
  } else if (userData?.countRef >= 10 && !userData?.user?.streak1) {
    activeStep = 1;
  }
  return <>
    {/* <CustomBox sx={{ padding: '1rem 0px' }}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<StyledStepConnector />}>
        {steps.map((label) => (
          <Step onClick={() => { handleClaimStreak(activeStep) }} key={label}>
            <StepLabel StepIconComponent={StepIcon} sx={{ '& .MuiStepLabel-label': { fontSize: '1rem' } }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <ButtonBase sx={{ background: 'url("images/button.png") 50% 50% no-repeat', backgroundSize: 'contain', height: 40, width: '100%', fontSize: '1.5rem', marginTop: '2rem' }}>
        Claim Ticket
      </ButtonBase>
    </CustomBox> */}
    <Stack p={2} sx={{ background: '#52B1F1', border: '4px solid #084B7D' }}>
      <FlexRowAlign gap={1} justifyContent={'flex-start'}>
        <img src="images/task-icon.png" width={25} />
        <Typography variant="h4" color={"#000"}>Daily Rewards</Typography>
      </FlexRowAlign>
      <Grid container columnSpacing={1} mt={1.5}>
        {
          userDailyReward?.map((item, index) => <Grid item xs={4} key={index} position={'relative'}>
            <img src="images/box-bg-5.png" style={{ width: '100%', filter: `grayscale(${item.isDone && item.isActive ? 1 : 0}) brightness(${item.isDone || !item.isActive ? 0.4 : 1})` }} />
            <Stack onClick={() => { handleClaimReward(item) }} sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, pt: '0.2rem', pl: '0.7rem' }}>
              <Typography textAlign={'center'} variant="h4">{item.name}</Typography>
              <Stack spacing={1} alignItems={'center'} justifyContent={'center'} height={'100%'}>
                {
                  item?.isDone ? <img src="/images/check-icon.png" width={'30%'} style={{ marginBottom: '1rem' }} /> : <>
                    <img src="/images/POINT.png" width={'40%'} />
                    <Typography variant="h4" color={"#000"}>{item.tokenAmount}</Typography>
                  </>
                }
              </Stack>
            </Stack>
          </Grid>)
        }
      </Grid>
    </Stack>
  </>
}

function StepIcon(props) {
  const { active, completed, icon } = props;
  return <img src={`images/streak-${completed ? 'completed' : icon}.png`} style={{ width: 40, height: 40, filter: `grayscale(${active || completed ? 0 : 1})` }} />;
}

const StyledStepConnector = styled(StepConnector)(({ theme }) => ({
  height: '10px',
  background: '#202020',
  marginTop: '3px',
  '&.Mui-active': {
    background: '#299DFF',
  },
  '& .MuiStepConnector-line': {
    opacity: 0
  }
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  border: `1px solid #B7B7B7`,
  background: theme.palette.background.default,
  borderRadius: "0.8rem",
  position: "relative",
  flexDirection: "column",
  padding: 0,
  img: {
    filter: "saturate(0)",
  },
  "&.active": {
    borderColor: "#FAFF00",
    boxShadow:
      "inset 0px 4px 0px rgba(255,255,255,0.25), 2px 4px 0px rgba(0,0,0)",
    img: {
      filter: "saturate(1)",
    },
  },
}));

export const StyledInput = styled(OutlinedInput)(({ theme }) => ({
  margin: "1.5rem 0px",
  background: "#000",
  borderRadius: "0.5rem",
  input: {
    textAlign: "center",
    color: "white",
    fontWeight: 600,
  },
  "& fieldset": {
    border: `1px solid #000!important`,
  },
}));

export const CustomBox = styled(Stack)(({ theme }) => ({
  border: '3px solid #084B7D',
  background: "#000",
  padding: '1rem'
}));