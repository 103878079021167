import { useCallback, useEffect, useState } from "react";
import useSettings from './useSettings';

import { useTelegram } from "contexts/TelegramContext";
import useAxios from "./useAxios";

const useWarzoneLogs = (page:number=1) => {
    const { settings } = useSettings();
    const { callApi } = useAxios()
    const [ warzoneLogs, setWarzoneLogs] = useState([])
    const { user } = useTelegram()
    const fetchWarzoneLogs = useCallback(async () => {
        if (!settings.token) return;
        try {
            const nfts = await callApi('get', `/pvp/warzone-logs?page=${page}&limit=${5}`)
            setWarzoneLogs(nfts as any)
        } catch (e) {
            console.log('ignoew')
        }
    }, [settings.token])
    useEffect(() => {
        fetchWarzoneLogs()
    }, [
        fetchWarzoneLogs, settings.token
    ])
    return { setWarzoneLogs, warzoneLogs }
};

export default useWarzoneLogs;
